import { ReportTableColumn, ReportTableDropdown } from "../../models/reports.model";
import { WarehouseRentalMenuEnum } from "./report-warehouse-rental.enum";
import { DataType } from "src/app/common/models/enums/common.enum";

export class WarehouseRentalReportConstant {

    static overViewMenu = [
        {
            label: 'reports.overview',
            name: WarehouseRentalMenuEnum.overview,
            count: null
        },
        {
            label: 'reports.rentUnitSizeWise',
            name: WarehouseRentalMenuEnum.retailUnitSize,
            count: null
        },
        {
            label: 'reports.unit',
            name: WarehouseRentalMenuEnum.retailUnit,
            count: null
        }
    ]

    static retailUnitsizeExpandColumns: any[] = [ 
        {field : 'neigh_name'}, 
        {field : 'ambient_range'},
        {field : 'air_conditioned_range'},
        {field : 'frozen_chiller_range'},
        {field : 'open_yard_range'}
    ];
    static retailUnitsizeExpandAverageColumns: any[] = [{field : 'neigh_name', convertNumber: false},{field : 'ambient_avg', convertNumber: true},{field :'air_conditioned_avg', convertNumber: true},{field :'frozen_chiller_avg', convertNumber: true},{field :'open_yard_avg',  convertNumber: true}];



    static retailUnitsizeAverageColumns: ReportTableColumn[] = [
        {
            header: 'unit_size',
            columnName: 'reports.unit_size',
            field: 'unit_size',
            isSortable: false,
            border: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: false,
        },
        {
            header: 'ambient_avg',
            columnName: 'reports.Ambient',
            field: 'ambient_avg',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'ambient_range',
        },
        {
            header: 'air_conditioned_avg',
            columnName: 'reports.air_conditioned',
            field: 'air_conditioned_avg',
            symbol: DataType.currencyWithSquareMeter,
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'air_conditioned_range',
        },
        {
            header: 'frozen_chiller_avg',
            columnName: 'reports.frozen_chiller',
            field: 'frozen_chiller_avg',
            symbol: DataType.currencyWithSquareMeter,
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'frozen_chiller_range',
        },
        {
            header: 'open_yard_avg',
            columnName: 'reports.Open_Yard',
            field: 'open_yard_avg',
            symbol: DataType.currencyWithSquareMeter,
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'open_yard_range',
        }
       
    ]

    static warehouseUnitsizeColumns: ReportTableColumn[] = [
        {
            header: 'unit_size',
            columnName: 'reports.unit_size',
            field: 'unit_size',
            isSortable: false,
            border: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: false,
        },
        {
            header: 'ambient_range',
            columnName: 'reports.Ambient',
            field: 'ambient_range',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            toolTipField:'ambient_avg',
        },
       
        {
            header: 'air_conditioned_range',
            columnName: 'reports.air_conditioned',
            field: 'air_conditioned_range',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            toolTipField:'air_conditioned_avg',
        },
        {
            header: 'frozen_chiller_range',
            columnName: 'reports.frozen_chiller',
            field: 'frozen_chiller_range',
            symbol: DataType.currencyWithSquareMeter,
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: false,
            toolTipField:'frozen_chiller_avg',
        },
        {
            header: 'open_yard_range',
            columnName: 'reports.Open_Yard',
            field: 'open_yard_range',
            symbol: DataType.currencyWithSquareMeter,
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: false,
            toolTipField:'open_yard_avg'
        }
       
    ]

    static warehouseUnitColumns: ReportTableColumn[] = [
        {
            header: 'p_property_name',
            columnName: 'reports.PropertyName',
            field: 'p_property_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'neighborhood',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neighborhood',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
       
        {
            header: 'floor',
            columnName: 'reports.floor',
            field: 'floor',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'unit_size',
            columnName: 'reports.unit_size',
            field: 'unit_size',
            border: false,
            isSortable: true,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'property_type',
            columnName: 'reports.property_type',
            field: 'property_type',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        
        {
            header: 'lease_rate',
            columnName: 'reports.lease_rate',
            field: 'lease_rate',
            border: true,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true
        },
        
       
    ]

    
    static unitSizeDropdown: ReportTableDropdown = {
        label: 'reports.show_price_in',
        data: [{
            value: 'Range',
            id: 'Range',
        },{
            value: 'Average',
            id: 'Average',
        }],
        default: 'Range',
        maxSelect: 1
    }
    
}
