<div class="report-container office-inventory">
    <!-- Menu -->
    <app-report-sub-menu [menuList]="overViewMenu" (menuChangeValue)="menuChangeValue($event)"></app-report-sub-menu>
    <!-- Overview -->
    <ng-container *ngIf="overviewLoading">
        <app-skeleton-loader [reportType]="chartView.overView"></app-skeleton-loader>
    </ng-container>
    <div class="report-tabsection" id="{{ officeInventoryMenuEnum.overview}}"
        [ngClass]="{ 'scroll': currentScroll === officeInventoryMenuEnum.overview}">
        <div class="report-overview" *ngIf="!overviewLoading">
            <!-- Total Properties -->
            <app-overview-card
            [card_title]="'reports.totalProperties'"
            [total_current]="propertiesData?.total_properties" [untillDate]="propertiesData?.untillDate"
            [total_prev]="propertiesData?.prev_total_properties" [total_percentage]="propertiesData?.totalaverage_rate"></app-overview-card>
            <!-- Open Market Share -->
            <app-overview-card
            [card_title]="'reports.open_market_share'"
            [total_current]="openMarketData?.open_market_share" [untillDate]="openMarketData?.untillDate"
            [total_prev]="openMarketData?.prev_open_market_share" [total_percentage]="openMarketData?.totalaverage_rate" [uniType]="UnitTypeEnum.sqm"></app-overview-card>
            <!-- Captive Office Share -->
            <app-overview-card
            [card_title]="'reports.captive_office_share'"
            [total_current]="captiveOfficeData?.captive_office_share" [untillDate]="captiveOfficeData?.untillDate"
            [total_prev]="captiveOfficeData?.prev_captive_office_share" [total_percentage]="captiveOfficeData?.totalaverage_rate" [uniType]="UnitTypeEnum.sqm"></app-overview-card>
        </div>
        <!-- Gross Open Market Office Space - Grade Wise (sqm) Graph -->
        <div class="report-model report-rentrate" *ngIf="overviewChartData">
            <div class="report-model-header">
                <div class="info">
                    <h3>{{'reports.gross_open_market_office_space' | translate}} ({{'reports.sqm_unit' | translate}})
                    </h3>
                </div>
                <button class="btn-select-grade" [matMenuTriggerFor]="menu" *ngIf="(retailRentalChartReport | json) != '{}'">
                    <div class="setext">
                        <ng-container *ngFor="let name of selectedChartValue; let l = last">{{name}}<span *ngIf="!l"
                                style="padding-right: 2px;">,</span></ng-container>
                    </div>
                    <span class="morecount" *ngIf="selectedChartValue.length > 1">{{moreText}}</span>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>

                <mat-menu #menu="matMenu" [xPosition]="langName === 'ar' ? 'after' : 'before'"
                    class="grades-menu directionLtr">
                    <div (click)="$event.stopPropagation()">
                        <h5>{{'reports.choose_maximum_options' | translate}}</h5>
                        <ng-container *ngFor="let category of getReportKeys()">
                            <mat-checkbox (change)="selectChart($event, category)"
                                [checked]="selectedChartValue.includes(category)">{{category}}</mat-checkbox>
                        </ng-container>
                    </div>
                </mat-menu>
            </div>
            <ng-container *ngIf="overviewGraphLoading">
                <app-skeleton-loader [reportType]="chartView.barChart"></app-skeleton-loader>
            </ng-container>
            <ng-container *ngIf="!overviewGraphLoading">
                <div class="multiBarChart d-flex" *ngIf="(retailRentalChartReport | json) != '{}'; else noDataMessage">
                    <!-- {{retailRentalChartReport.length}} -->
                    <ng-container *ngFor="let chart of retailRentalChartReport | keyvalue; let i = index">
                        <app-report-charts class="grchart" [data]="chart.value" [chartType]="chartView.smallBarChart"
                            [isBorder]="false" [name]="chart.key" [index]="i"
                            [ngStyle]="{'min-width': (retailRentalChartReport | keyvalue).length == 1 ? '100%' : '220px'}">
                        </app-report-charts>
                    </ng-container>


                </div>
                <ng-template #noDataMessage>
                    <div class="data_unavail">
                        <span>
                            {{"reports.data_not_available" | translate}} <mat-icon  role="img" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon>
                        </span>
                    </div>
                </ng-template>
            </ng-container>
        </div>
    </div>
</div>