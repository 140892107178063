<div *ngIf="alerts.length" class="alert-container">
    <div *ngFor="let alert of alerts" class="{{cssClasses(alert)}} mr-2">
        <div class="icon_top">
            <span></span>
        </div>
        <div class="message-section">
            <p class="alert-text" [innerHTML]="alert.message"></p>
            <button class="close" [ngClass]="{'close_arb':currentLang == 'ar' }" 
            (click)="removeAlert(alert)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.004" viewBox="0 0 16 16.004"><defs><style></style></defs><path class="a" d="M.227,1.335a.8.8,0,0,1,0-1.11.8.8,0,0,1,1.11,0L8,6.9,14.67.225a.781.781,0,0,1,1.1,1.11L9.108,8l6.661,6.672a.781.781,0,0,1-1.1,1.11L8,9.107,1.337,15.779a.8.8,0,0,1-1.11,0,.8.8,0,0,1,0-1.11L6.888,8Z" transform="translate(-0.002 0)"/></svg>
        </button>
        </div>
    </div>
</div>