<div class="report-breadcum" [ngClass]="{ 'commonView': viewType.mapView !== currentReportView }">

  <div class="breadcums-container">
    <div class="breadcum breadcums-main" *ngIf="isReport">
      <ul>
        <li>
          <p class="title">{{'reports.objective' | translate}}</p>
          <div class="d-flex title-content">
            {{ selectedObjective?.obj_label | langtranslate }}
            <mat-icon *ngIf="currentLang == 'en'">keyboard_arrow_right</mat-icon>
            <mat-icon *ngIf="currentLang == 'ar'">keyboard_arrow_left</mat-icon>
          </div>
        </li>
        <li>
          <p class="title">{{'reports.sector' | translate}}</p>
          <div class="d-flex title-content">
            {{ selectedStudies?.study_label | langtranslate }}
            <mat-icon *ngIf="currentLang == 'en'">keyboard_arrow_right</mat-icon>
            <mat-icon *ngIf="currentLang == 'ar'">keyboard_arrow_left</mat-icon>
          </div>
        </li>
        <li>
          <p class="title">{{'reports.type' | translate}}</p>
          <div class="d-flex title-content">
            {{ selectedRepored?.report_label | langtranslate }}
          </div>
        </li>
      </ul>
    </div>
    <ng-container>
      <button class="breadcums-main breadcum-saved-filter save-filter-option" [class.disable]="countOfFilter == 0 || reportService.getLockReport()"
        *ngIf="isReport && this.reportService.showDefaultSaveButton" (click)="savedFilterName($event)">
        <div class="save-filter d-flex">
          <div class="other-icons d-flex">
            <mat-icon svgIcon="bookmarkWhite" class="icon"></mat-icon>
          </div>
          <div class="search-text">
            <p>{{"reports.save_search_criteria" | translate}}</p>
          </div>
        </div>
      </button>


      <button class="breadcums-main breadcum-saved-filter saved-filter-option"
        *ngIf="isReport && !this.reportService.showDefaultSaveButton" [matMenuTriggerFor]="savedFilter" [disabled]="reportService.getLockReport()">
        <div class="text d-flex">
          <p [matTooltip]='getSaveFilter() ? getSaveFilter() : ("reports.my_saved_searches" | translate)'>
            {{getSaveFilter() ? getSaveFilter() : ("reports.my_saved_searches" | translate)}}</p>
        </div>
        <div class="text-icon d-flex">
          <div class="other-icons arrow-icons d-flex">
            <mat-icon svgIcon="arrowDown" class=" "></mat-icon>
          </div>
          <button class="other-icons restarIcon d-flex" [disabled]="!isResetBtn || reportService.getLockReport()" (click)="resetFilter($event)" [matTooltip] = '"reports.reset_search_criteria" | translate'>
            <mat-icon svgIcon="restartIcon" class="icon"></mat-icon>
          </button>
          <button class="other-icons saveFilterBookmark d-flex" [disabled]="reportService.getLockReport()" (click)="!isResetBtn ? savedFilterName($event) : savedFilterName($event, 'saveAs')" [matTooltip] = '"reports.save_search_criteria" | translate'>
            <mat-icon svgIcon="bookmarkWhite" class="icon"></mat-icon>
          </button>
        </div>
      </button>
      <mat-menu #savedFilter="matMenu" xPosition="after" class="saved-filter">
        <div class="sticky-search">
          <search-bar class="searchFilter" [MinCharSearch]=1 (SearchClick)='searchReport($event)'
            [Placeholder]="'reports.search'" (click)="$event.stopPropagation()"></search-bar>
        </div>
        <div *ngIf="filterSearchTerm && !this.reportService.savedFilters.length" class="save-filter-name">
          <span class="filter-name">{{'reports.no_record_found' | translate}}</span>
        </div>
        <div (click)="applySavedFilter(filter)" *ngFor="let filter of this.reportService.savedFilters"
          class="save-filter-name">
          <span class="filter-name">{{filter.name}}</span>
          <span class="date">{{(filter.updated_date? filter.updated_date : filter.added_date) | date: 'dd/MM/yyyy'}}</span>
        </div>
      </mat-menu>
    </ng-container>
  </div>
  <div class="fiters-function d-flex" [style]="{'max-width': breadcumWidth}">
    <!-- <mat-chip-set #chipGrid aria-label="selection1"> -->
    <!-- <ng-container *ngFor="let filter of filterData let i = index">
                <mat-chip-row class="filter-matChip" *ngIf="(Params['polygon'] && filter.filter != 'neigh_name') || !Params['polygon']">
                    {{ getLabel(filter.filter, filter.value) }}
                    <button matChipRemove (click)=" removeFilter(i)">
                      <mat-icon class="remove-filter">close</mat-icon>
                    </button>
                </mat-chip-row>
              </ng-container> -->
    <!-- </mat-chip-set> -->

    <!-- transformed Filter -->
    <ng-container *ngIf="isReport && countOfFilter != 0">
      <app-report-filter-group style="min-width: 0;" [filterDropdownData]="filterDropdownData" [filterData]="filterData"
        [filterList]="filterList" [queryParams]="queryParams" [user_info]="user_info"></app-report-filter-group>
    </ng-container>


    <div class="map-filter" *ngIf="isReport">
      <!-- Map Layer Icons -->
      <!-- <button class="map-filter_icns" matTooltip="{{'reports.search'| translate}}"><mat-icon svgIcon="searchIcon"></mat-icon></button> -->
      <button class="map-filter_icns" (click)="layerToggle()" matTooltip="{{'reports.map-settings'|translate}}"><mat-icon
          svgIcon="layerIcon"></mat-icon></button>
      <button class="map-filter_icns" (click)="filterToggle()" matTooltip="{{'reports.filter'| translate}}"><mat-icon
          svgIcon="reportFilterIcon"></mat-icon></button>
      <div *ngIf="countOfFilter" class="map-filter_count">{{countOfFilter}}</div>
    </div>
  </div>
</div>
