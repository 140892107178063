import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportTableColumn } from "../../models/reports.model";
import { HouseVillasMenuEnum } from "./report-house-villas.enum";
import { DataType } from "src/app/common/models/enums/common.enum";

export class HouseVillasReportConstant {

    static overViewMenu = [
        {
            label: 'reports.overview',
            name: HouseVillasMenuEnum.overview,
            count: null
        },
        {
            label: 'reports.independent_villas',
            name: HouseVillasMenuEnum.independent_villas,
            count: null
        },
        {
            label: 'reports.govermental_villas',
            name: HouseVillasMenuEnum.governmental_villas,
            count: null
        },
        {
            label: 'reports.projects',
            name: HouseVillasMenuEnum.projects,
            count: null
        }
    ]

    static independentVillasColumns: ReportTableColumn[] = [
        {
            header: 'neigh_name',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neigh_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            showOnMap: true
        },
        {
            header: 'total_land_area',
            columnName: 'reports.total_land_area',
            field: 'total_land_area',
            isSortable: true,
            border: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'avg_land_area',
            columnName: 'reports.avg_land_area',
            field: 'avg_land_area',
            border: false,
            isSortable: true,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'total_no_of_land',
            columnName: 'reports.total_no_of_land',
            field: 'total_no_of_land',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'total_no_of_villas',
            columnName: 'reports.total_no_of_villasD',
            field: 'total_no_of_villas',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true
        },
        // {
        //     header: 'avg_buildup_area',
        //     columnName: 'reports.avg_buildup_area',
        //     field: 'avg_buildup_area',
        //     border: false,
        //     isSortable: true,
        //     symbol: '(sqm)',
        //     isContainsHTML: false,
        //     convertNumber: true
        // }
    ]

    static governmentalVillasColumns: ReportTableColumn[] = [
        {
            header: 'neigh_name',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neigh_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            showOnMap: true
        },
        {
            header: 'total_land_area',
            columnName: 'reports.total_land_area',
            field: 'total_land_area',
            isSortable: true,
            border: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'avg_land_area',
            columnName: 'reports.avg_land_area',
            field: 'avg_land_area',
            border: false,
            isSortable: true,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'total_no_of_land',
            columnName: 'reports.total_no_of_land',
            field: 'total_no_of_land',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'total_no_of_villas',
            columnName: 'reports.total_no_of_villasD',
            field: 'total_no_of_villas',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true
        },
        // {
        //     header: 'avg_buildup_area',
        //     columnName: 'reports.avg_buildup_area',
        //     field: 'avg_buildup_area',
        //     border: false,
        //     isSortable: true,
        //     symbol: '(sqm)',
        //     isContainsHTML: false,
        //     convertNumber: true
        // }
    ]

    static projectsColumns: ReportTableColumn[] = [
        {
            header: 'project_name',
            columnName: 'reports.PROJECTNAME',
            field: 'project_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            showOnMap: true
        },
        {
            header: 'governate',
            columnName: 'reports.governate',
            field: 'governate',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'total_land_area',
            columnName: 'reports.total_land_area',
            field: 'total_land_area',
            isSortable: true,
            border: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'avg_land_area',
            columnName: 'reports.avg_land_area',
            field: 'avg_land_area',
            border: false,
            isSortable: true,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'total_no_of_villas',
            columnName: 'reports.total_no_of_villas',
            field: 'total_no_of_villas',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'total_no_of_plots',
            columnName: 'reports.total_no_of_plots',
            field: 'total_no_of_plots',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true
        }
       
        
    ]

    
}
