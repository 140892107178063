import { Component, Input, SimpleChanges } from '@angular/core';

import { ReportService } from 'src/app/reports/services/report.service';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/common/services/shared.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { TransactionsReportConstant } from '../../reports-lists/components/report-transactions/report-transactions.constants';
import { ReportHouseVillasService } from '../../reports-lists/components/report-house-villas/report-house-villas.service';
import { UnitTypeEnum } from '../../reports-lists/models/unit-type-enum';

@Component({
  selector: 'app-report-housevilla-project-details',
  templateUrl: './report-housevilla-project-details.component.html',
  styleUrls: ['./report-housevilla-project-details.component.scss']
})
export class ReportHousevillaProjectDetailsComponent {
  @Input() propertyInfo: any;
  propertyDetail: any = null;
  landInformation: any;
  areaInfo: any;
  landInfo: any;
  paramData: any;
  userInfo: any = 0;

  propertyTransactionColumns = TransactionsReportConstant.housevillaProjectColumns;
  propertyTransactionCount: number = 0;
  propertyTransactiondata: any[] = [];
  placeCode: string = '';
  sqm_unit: string = '';

  projectDetailsPieData: any[] = [];
  chartView = ReportsChartEnum;
  downloadLoading = false;
  unitsLoading = false;
  overviewLoading = false;
  landLoading = false;
  UnitTypeEnum= UnitTypeEnum;
  report_name: any;
  constructor(
    private reportHouseVillaService: ReportHouseVillasService,
    private reportService: ReportService,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private alertService: AlertService
  ) { 
    this.report_name = this.reportService.getCurrentReport()?.label;
  }

  ngOnInit() {
    this.sqm_unit = 'reports.'+GlobalContants.keySymbolContants.squareMeterUnit;
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.route.queryParams.subscribe((param: any) => {
      this.paramData = param;
      this.reportHouseVillaService.projectUnitPayload.pageNumber = 0;
      // this.getProjectInformation(param);
      if(this.propertyInfo.proj_uid){
        if(typeof this.propertyInfo['proj_name'] == 'string'){
          this.propertyInfo['proj_name'] = JSON.parse(this.propertyInfo['proj_name'])
        }
        this.getUnits();
        this.getOverview();
        this.getLandInfo();
      }
    })
  }

  getUnits() {
    this.unitsLoading = true;
    
      this.reportHouseVillaService.getHouseVillaProjectUnitDetail(this.propertyInfo.proj_uid, this.reportHouseVillaService.projectUnitPayload).subscribe(
        {
          next: (res: any) => {
            this.unitsLoading = false;
            if (res && res.status == 200) {
              this.propertyTransactiondata = res.data;
              this.propertyTransactionCount = res.count;
            } else {
              this.propertyTransactionCount = 0;
              this.propertyTransactiondata =[];
            }
          },
          error: () => {
            this.propertyTransactionCount = 0;
            this.unitsLoading = false;
            this.propertyTransactiondata =[];
          }
        }
      )
  }
  getOverview() {
    this.overviewLoading = true;
      this.reportHouseVillaService.getHouseVillaProjectOverview(this.propertyInfo.proj_uid).subscribe(
        {
          next: (res: any) => {
            this.scrollTop();
            this.overviewLoading = false;
            if (res && res.status == 200) {
              this.areaInfo = res.data;
              if(this.areaInfo) this.areaInfo['total_land_area'] = this.reportService.formatInternationNum(this.areaInfo?.total_land_area);
            } else {
              this.areaInfo = null;
            }
          },
          error: () => {
            this.areaInfo = null;
            this.overviewLoading = false;
          }
        }
      )
  }
  getLandInfo() {
    this.landLoading = true;
      this.reportHouseVillaService.getHouseVillaProjectLand(this.propertyInfo.proj_uid).subscribe(
        {
          next: (res: any) => {
            this.landLoading = false;
            if (res && res.status == 200) {
              this.landInfo = res.data;
              if(this.landInfo?.max_date) this.landInfo.max_date = this.reportService.getDataFormat(this.landInfo?.max_date);
              //Chart Data
              if(this.landInfo?.occupied != null && this.landInfo?.vacant != null){
                let temp = [];
                temp.push({
                  category: 'Occupied',
                  value: this.landInfo?.occupied
                })
                temp.push({
                  category: 'Vacant',
                  value: this.landInfo?.vacant
                })
                this.projectDetailsPieData = temp;
              }else{
                this.projectDetailsPieData=[];
              }
            }else{
              this.landInfo=null;
              this.projectDetailsPieData=[];
            }
          },
          error: () => {
            this.landLoading = false;
            this.projectDetailsPieData=[];
            
          }
        }
      )
  }
  backToTransaction() {
    this.reportService.transactionPropertyDetails.next(false);
  }
  scrollTop(){
    document.querySelector('.transaction-property-detail')?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    })
  }

  directionInfo(value: any) {
    let sum = 0;
    for (const property in value) {
      sum += +value[property]?.toFixed(2);
    }
    return sum?.toFixed(2);
  }
  onPageEvent(e: any, report: string) {
    if (e.previousPageIndex !== e.pageIndex) {
      this.reportHouseVillaService.projectUnitPayload.pageNumber = (e.pageSize * e.pageIndex);
    }
    this.getUnits();
  }
  sortData(event: any, report: string) {
    let sort = {
      column: event.active,
      order: event.direction
    }
    this.reportHouseVillaService.projectUnitPayload.pageNumber = 0;
    this.reportHouseVillaService.projectUnitPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');;
    this.getUnits();
  }


  downloadHouseVillaReport(type: string, module_name: string) {
    this.downloadLoading = true;
    this.reportHouseVillaService.downloadHouseVillaReport(type, this.userInfo?.user_id, this.propertyInfo.proj_uid).subscribe(
      {
        next: (res: any) => {
          this.downloadLoading = false;
          if (res.status === 200) {
            const downloadUrl = `${this.reportHouseVillaService.downloadExcelUrl()}?file_name=${res.data}&city_code=${this.placeCode.toLowerCase()}&module_name=${module_name}`;
            this.sharedService.downloadExcelUrl(downloadUrl);
          } else {
            this.alertService.warn(res.message)
          }
        },
        error: (error: any) => {
          this.downloadLoading = false;
        }
      }
    )
  }
  downloadExcel(event: any, type: string) {
    this.downloadHouseVillaReport(type, 'house_and_villa');
  }
  selectedYear(data: any){
    this.reportHouseVillaService.projectUnitPayload.year = data.year;
    this.getUnits();
  }
}
