import { Component, PLATFORM_ID, Inject, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { SharedService } from '../../../common/services/shared.service';
import { isPlatformBrowser } from '@angular/common';
import { ReportService } from '../../services/report.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { ReportMapService } from '../../services/report-map.service';
import { ReportDataComponent } from '../report-data/report-data.component';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { SEOService } from 'src/app/common/services/SEOService.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit, OnDestroy {

  toggleSide$: boolean = false;
  layerToggle: boolean = false;
  isBrowser: boolean;
  currentLang: string | null = null;
  filterToggle: boolean=false;
  @ViewChild('ReportDataComponent') reportDataComponent:ReportDataComponent | undefined;
  unsubscribe$:Subject<void> = new Subject<void>();
  constructor(
    private sharedService: SharedService,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: any,
    private reportService: ReportService,
    private mapService: ReportMapService,
    private _seoService: SEOService
  ){  
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      this.setLang();
    }
  }

  ngOnInit(): void {
    this._seoService.updateTitle(`${GlobalContants.defaultTitleConstant.defaultTitle} | Generate Reports`)
    this.toggleSide();
  }


  toggleSide() : void{
    this.reportService.reportQueryToggle$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((toggle: boolean) => {
      this.toggleSide$ = toggle;
    });
    this.mapService.mapLayertoggle.pipe(
      takeUntil(this.unsubscribe$),
      distinctUntilChanged()
    ).subscribe((toggle: boolean) => {
      this.layerToggle = toggle;
    });
    this.mapService.mapFiltertoggle.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((toggle: boolean) => {
      this.filterToggle = toggle;
    });
  }

  setLang(): void{
    this.sharedService.language$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(lang => {
      this.currentLang = lang;
      this.translate.use(lang);
    })
  }
 

  resetFilterData(){
    this.reportDataComponent?.resetFilter();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  
}
