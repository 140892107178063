export enum LanguagesEnum {
    english = 1,
    arabic = 2
}

export enum LoaderType {
    skip = '1',
    normal = '2',
    report = '3'
}

export enum DataType {
    percentage = 0,
    unit,
    squareMeter,
    squareMeterWithBracket,
    squareFeet,
    squareFeetWithBracket,
    currencyCode,
    currencyWithSquareMeter,
    monthly,
    currencyWithSquareMonthly
}
