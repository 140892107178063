import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportService } from 'src/app/reports/services/report.service';
import { AlertService } from '../alert/alert.service';
import { SharedService } from '../../services/shared.service';
import { GlobalContants } from '../../constants/global.contants';
@Component({
  selector: 'app-save-filter',
  templateUrl: './save-filter.component.html',
  styleUrls: ['./save-filter.component.scss']
})
export class SaveFilterComponent {

  savedFilter !: FormGroup;
  filters !: any
  user_info: any;
  filterDropdownData: any;
  langNbr: number;
  filterLabel: any[] = [];
  isSaveFilter: boolean = false
  constructor(
    public dialogRef: MatDialogRef<SaveFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public info: any,
    private fb: FormBuilder,
    private reportService: ReportService,
    private alertService: AlertService,
    private sharedService: SharedService
  ) {
    this.filters = this.info.filterData,
    this.filterDropdownData = this.info.filterDropdownData;

    this.langNbr = this.sharedService.getCurrentLangID();
  }
  ngOnInit() {
    this.user_info = this.sharedService.getUserInfo();
    this.savedFilter = this.fb.group({
      inputField: ['', Validators.compose([Validators.required, Validators.maxLength(20)])]
    });
  }
  get inputField() {
    return this.savedFilter.get('inputField')!;
  }

  onCancelClick() {
    this.dialogRef.close()
  }
  /**
   * This function is used new filter
   * @param form : form contains name for the filters
   * @returns
   */
  onSaveClick(form: any) {
    if(this.isSaveFilter) return;
    this.isSaveFilter = true;
    if (form.value.inputField == '' || form.value.inputField == null) {
      return;
    }
    if (form.value.inputField.trim() == "") {
      form.patchValue({
        inputField: ''
      });
      return;
    }

    let datas: { [key: string]: string } = { ...this.filters }
    let polygon: any;
    if (datas['polygon']) {
      polygon = datas['polygon'];
    }
    for (let key in datas) {
      if (['rid', 'srid', 'gid', 'oid', 'polygon'].includes(key)) {
        delete datas[key];
      } else {
        let temp = { ...datas }
        temp['label'] = this.getLabel(key, datas[key])
      }
    }

    /**
     * Send label object in api
     */
    for (const [key, value] of Object.entries(datas)) {
      if (key !== 'zoom' && key !== 'centroid') {
        let data = value?.includes(',') ? value.split(',') : [value]

        const parameterMap: any = {};
        data.forEach((item: any) => {
          if (parameterMap[key]) {
            parameterMap[key].data.push({ value: item, label: this.getLabel(key, item) });
          } else {
            let filter = this.filterDropdownData.find((f: any) => f.parameter_name == key);
            parameterMap[key] = { parameter_name: filter.label, data: [{ value: item, label: this.getLabel(key, item) }] };
            this.filterLabel.push(parameterMap[key]);
          }
        });

      }
    }
    let body = {
      "user_id": this.user_info.user_id,
      "filter_type": "filter",
      "filter_name": form.value.inputField,
      "filter_data": {
        "filter": datas || null,
        "catchment": polygon ? JSON.parse(polygon) : null,
        "filter_label": this.filterLabel
      }
    }

    this.reportService.saveFilter(body).subscribe({
      next: (res: any) => {
        if (res && res?.status == 201) {
          this.savedFilter.reset();
          this.alertService.success(res.message)
          this.reportService.getSavedFilter()
          this.isSaveFilter = false;
          this.dialogRef.close();
        } else {
          this.savedFilter.reset();
          this.alertService.warn(res.message)
          this.isSaveFilter = false;
        }
      },
      error: (err: any) => {
        this.savedFilter.reset();
        this.isSaveFilter = false;
        this.alertService.error(err.message)
      }
    });

  }

  getLabel(key: string, value: string) {
    let filter = this.filterDropdownData.find((f: any) => f.parameter_name == key);
    if(key === 'neigh_name'){
      let neigh_list =  this.sharedService.getStoreValue(GlobalContants.StoreKey.neighList);
      let neighName = neigh_list?.find((e:any)=> e.neigh_name[1].toLowerCase() == value.toLowerCase())
          if(neighName && neighName?.neigh_name){
            return neighName?.neigh_name
          }else{
            return {[this.langNbr]: value};
          }
      } else{
        if (filter && filter?.value_list?.length) {
        let f_value_obj = filter?.value_list?.find((e: any) => e.val == value);
        if (f_value_obj) {
          return f_value_obj?.label;
        }
    }
  }
    return {[this.langNbr]: value} //this.sharedService.removeUnderscores(value);

  }

  // getLabel(key: string, value: string) {
  //   let filter = this.filterDropdownData.find((f: any) => f.parameter_name == key);
  //   if (filter && filter.value_list?.length) {
  //     let f_value_obj = filter.value_list.find((e: any) => e.val == value);
  //     if (f_value_obj) {
  //       return f_value_obj.label[this.langNbr] ? f_value_obj.label[this.langNbr] : f_value_obj.label['1']
  //     }
  //   }
  //   return value//this.sharedService.removeUnderscores(value);
  // }

}
