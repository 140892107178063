<div class="report-container">

    <ng-container *ngIf="!showPropertyDetails">

        <app-report-sub-menu [menuList]="transactionMenu"
            (menuChangeValue)="menuChangeValue($event)"></app-report-sub-menu>
            <ng-container *ngIf="overViewLoading">
                <app-skeleton-loader [reportType]="chartView.overView" [loaderSize]="4" [gridColumn]="2"
                    ></app-skeleton-loader>
            </ng-container>
        <div class="report-tabsection"  id="{{transactionsMenuEnum.overview}}"
            [ngClass]="{ 'scroll': currentScroll === transactionsMenuEnum.overview}">


            <div class="report-overview report-overview-2col hv-transactions-overview" *ngIf="!overViewLoading">
                <app-overview-card
                [card_title]="'reports.total_property_transactions'" 
                [total_current]="transactionOverview?.total_property_transaction"
                [untillDate]="transactionOverview?.untillDateProperty"
                [total_prev]="transactionOverview?.prev_year_total_property_transaction"
                [total_percentage]="transactionOverview?.total_percentage_property_transaction"
                ></app-overview-card>

                <app-overview-card
                [card_title]="'reports.avg_properties_transaction_rate'" 
                [total_current]="transactionOverview?.average_property_transaction_price"
                [untillDate]="transactionOverview?.untillDateProperty"
                [total_prev]="transactionOverview?.prev_year_average_property_transaction_price"
                [total_percentage]="transactionOverview?.total_percentage_avg_property_transaction"
                [uniType]="placeCode == BHRCHECK ? UnitTypeEnum.currencywithsqrt : UnitTypeEnum.currencywithsqm"
                [formatInternation]="false"
                ></app-overview-card>

                <app-overview-card
                [card_title]="'reports.total_units_transactions'" 
                [total_current]="transactionOverview?.total_unit_transaction"
                [untillDate]="transactionOverview?.untillDateUnit"
                [total_prev]="transactionOverview?.prev_year_total_unit_transaction"
                [total_percentage]="transactionOverview?.total_percentage_unit_transaction"
                ></app-overview-card>

                <app-overview-card
                [card_title]="'reports.avg_units_transaction_rate'" 
                [total_current]="transactionOverview?.average_unit_transaction_price"
                [untillDate]="transactionOverview?.untillDateUnit"
                [total_prev]="transactionOverview?.prev_year_average_unit_transaction_price"
                [total_percentage]="transactionOverview?.total_percentage_avg_unit_transaction"
                [uniType]="UnitTypeEnum.currencywithsqm"
                [formatInternation]="false"
                ></app-overview-card>

                <!-- <div class="overview">
                    <div class="overview-data">
                        <h2>{{"reports.total_transaction" | translate}}</h2>
                        <div class="total-val">
                            <h1>
                                <ng-container *ngIf="transactionOverview?.total_transaction; else othertrasdas">
                                    {{transactionOverview?.total_transaction}} <sup class="sup-font"></sup>
                                </ng-container>
                                <ng-template #othertrasdas>-</ng-template>
                            </h1>
                        </div>

                        <p><span>{{"reports.untill" | translate}} </span> <span class="until-date">{{ transactionOverview?.untillDate }}</span></p>

                    </div>
                    <div class="overview-v">
                        <span>{{"reports.was" | translate}} {{ transactionOverview?.total_transaction_prev_year ? ( transactionOverview?.total_transaction_prev_year |
                            number:'1.0-1') : '-'}}</span>
                        <div class="overview-prev">
                          <h5 class="normal-price directionLtr" *ngIf="transactionOverview?.total_percentage == 0"> {{
                            transactionOverview?.total_percentage }} %</h5>
                            <h5 class="high-price directionLtr" *ngIf="transactionOverview?.total_percentage > 0">+ {{
                                transactionOverview?.total_percentage }} %</h5>
                            <h5 class="low-price directionLtr" *ngIf="transactionOverview?.total_percentage < 0">{{
                                transactionOverview?.total_percentage }} %</h5>
                            <span>{{"reports.fromLastYear" | translate}}</span>
                            <mat-icon *ngIf="transactionOverview?.total_percentage > 0" class="high-price">
                                call_made</mat-icon>
                            <mat-icon *ngIf="transactionOverview?.total_percentage < 0" class="low-price">
                                call_received</mat-icon>
                        </div>
                    </div>
                </div>
                <div class="overview">
                    <div class="overview-data">
                        <h2>{{"reports.averageTransactionsValue" | translate}}</h2>
                        <div class="total-val">
                            <h1>
                                <ng-container *ngIf="transactionOverview?.average_trans_price; else othertransactionOverview">
                                    {{transactionOverview?.average_trans_price}} <sup class="sup-font">{{CurrencyCode}}</sup>
                                </ng-container>
                                <ng-template #othertransactionOverview>-</ng-template>
                            </h1>
                        </div>


                        <p><span>{{"reports.untill" | translate}} </span> <span class="until-date">{{ transactionOverview?.untillDate }}</span></p>

                    </div>
                    <div class="overview-v">
                        <span>{{"reports.was" | translate}} {{ transactionOverview?.avg_prev_year ? (transactionOverview?.avg_prev_year |
                            number:'1.0-1') : '-' }}</span>
                        <div class="overview-prev">
                          <h5 class="normal-price directionLtr"
                                *ngIf="transactionOverview?.average_total_percentage == 0">{{
                                transactionOverview?.average_total_percentage }} %</h5>
                            <h5 class="high-price directionLtr"
                                *ngIf="transactionOverview?.average_total_percentage > 0">+ {{
                                transactionOverview?.average_total_percentage }} %</h5>
                            <h5 class="low-price directionLtr"
                                *ngIf="transactionOverview?.average_total_percentage < 0">{{
                                transactionOverview?.average_total_percentage }} %</h5>
                            <span>{{"reports.fromLastYear" | translate}}</span>
                            <mat-icon *ngIf="transactionOverview?.average_total_percentage > 0" class="high-price">
                                call_made</mat-icon>
                            <mat-icon *ngIf="transactionOverview?.average_total_percentage < 0" class="low-price">
                                call_received</mat-icon>
                        </div>
                    </div>

                </div> -->
            </div>
        </div>

        <div class="report-tabsection" id="{{transactionsMenuEnum.property_transaction}}"
            [ngClass]="{ 'scroll': currentScroll === transactionsMenuEnum.property_transaction}">
            <app-report-year-dropdown
            [reportType]="'property'"
            (selectedYear)="selectedYear($event)"
        ></app-report-year-dropdown>
            <div class="report-model">

                <div class="report-model-header">
                    <div class="info">
                        <h3>{{"reports.propertyTransactions" | translate}}</h3>
                        <span class="totalTransaction">({{ propertyTransactionCount }} {{"reports.total_transaction" | translate}})</span>
                    </div>

                    <!-- <search-bar></search-bar> -->
                </div>
                <!-- <ng-container *ngIf="propertyTransactiondataLoading">
                    <app-skeleton-loader [loaderSize]="8"></app-skeleton-loader>
                </ng-container> -->
                <app-report-datatable [showLoader]="!propertyTransactiondataLoading"
                    [tableColumns]="propertyTransactionColumns"
                    [dataLength]="propertyTransactionCount"
                    [tableData]="propertyTransactiondata"
                    [allowExcelDownload]="true"
                    [showEstaterText]="true"
                    (pageEvent)="onPageEvent($event, 'property_transaction')"
                    (sort)="sortData($event, 'property_transaction')"
                    (excelDownload)="downloadExcel($event, 'property', 'a')" [downloadLoading]="downloadLoading['a']">
                </app-report-datatable>

            </div>
        </div>
        <div class="report-tabsection" id="{{transactionsMenuEnum.unit_transaction}}" [ngClass]="{ 'scroll': currentScroll === transactionsMenuEnum.unit_transaction}">
            <app-report-year-dropdown
            [reportType]="'unit'"
            (selectedYear)="selectedYear($event)"
        ></app-report-year-dropdown>
            <div class="report-model">

                <div class="report-model-header">
                    <div class="info">
                        <h3>{{"reports.unitTransactions" | translate}}</h3>
                        <span class="totalTransaction">({{ reportService.formatInternationNum(unitTransactionCount) }} {{"reports.total_transaction" | translate}})</span>
                    </div>
                    <!-- <search-bar></search-bar> -->
                </div>
                <!-- <ng-container *ngIf="unitTransactiondataLoading">
                    <app-skeleton-loader [loaderSize]="8"></app-skeleton-loader>
                </ng-container> -->
                <app-report-datatable  [showLoader]="!unitTransactiondataLoading"
                    [tableColumns]="unitTransactionColumns"
                    [dataLength]="unitTransactionCount"
                    [tableData]="unitTransactiondata"
                    [allowExcelDownload]="true"
                    [showEstaterText]="true"
                    (pageEvent)="onPageEvent($event, 'unit_transaction')"
                    (sort)="sortData($event, 'unit_transaction')"
                    (excelDownload)="downloadExcel($event, 'unit', 'b')" [downloadLoading]="downloadLoading['b']">
                </app-report-datatable>

            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="showPropertyDetails">
        <ng-container *ngIf="multi_story_residential; else elseTemplate">
            <app-report-property-detail [propertyInfo]="propertyInfo"></app-report-property-detail>
        </ng-container>
        <ng-template #elseTemplate>
            <app-report-transactions-property-details [propertyInfo]="propertyInfo"></app-report-transactions-property-details>
        </ng-template>

    </ng-container>

</div>
