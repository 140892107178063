import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ReportPropertyProfilingService } from './report-property-profiling.service';
import { ReportsListsService } from '../../services/reports-lists.service';
import { PropertyProfilingReportConstant } from './report-property-profiling.constants';
import { ReportChartColumn, ReportTableColumn } from '../../models/reports.model';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { SharedService } from 'src/app/common/services/shared.service';

@Component({
  selector: 'app-report-property-profiling',
  templateUrl: './report-property-profiling.component.html',
  styleUrls: ['./report-property-profiling.component.scss']
})
export class ReportPropertyProfilingComponent {

  @Input() params: any;

  chartView = ReportsChartEnum;

  reports = PropertyProfilingReportConstant.reports;

  totalPropertyProfilingReport: any = [];
  totalPropertyProfilinColumn: ReportTableColumn[] = PropertyProfilingReportConstant.totalColumn;
  typeGradePropertyProfilingReportTableColumn: ReportTableColumn[] = PropertyProfilingReportConstant.typeGradePropertyProfilingReportTableColumn;
  typeGradePropertyProfilingReport: any = [];
  amenitiesPropertyProfilingTableColumn: ReportTableColumn[] = PropertyProfilingReportConstant.amenitiesPropertyProfiling;
  amenitiesPropertyProfilingReport: any = [];
  typePropertyProfilingReport: ReportChartColumn[] = [];
  gradeFurnishedPropertyProfilingTableColumn: ReportTableColumn[] = PropertyProfilingReportConstant.gradeFurnishedPropertyProfilingReport;
  gradeFurnishedPropertyProfilingReport: any = [];

  isFirstTime = true;
  amenitiesPropertyProfilingReportLoading = false;
  gradeFurnishedPropertyProfilingReportLoading = false;
  typeGradePropertyProfilingReportLoading = false;
  totalLoading = false;
  typePropertyProfilingReportLoading = false;

  constructor(
    private reportPropertyProfilingService: ReportPropertyProfilingService,
    private reportsListsService: ReportsListsService,
    private sharedService: SharedService
  ) {
    this.sharedService.language$.subscribe(lang => {
      if (lang && !this.isFirstTime) {
        this.getReports();
      }
    })
  }

  ngOnInit() {
    this.getReports();
    this.isFirstTime = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.getReports();
    }
  }

  getReports() {
    this.getTotalPropertyProfilingReport();
    this.getTypePropertyProfilingReport();
    this.getTypeGradePropertyProfilingReport();
    this.getAmenitiesPropertyProfilingReport();
    this.getGradeFurnishedPropertyProfilingReport();
  }

  getTotalPropertyProfilingReport(year = null) {
    this.totalLoading = true;
    this.reportPropertyProfilingService.getTotalPropertyProfilingReport(year).subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            this.totalPropertyProfilinColumn = [...this.reportsListsService.yearColumnAppend(PropertyProfilingReportConstant.totalSummaryColumn, this.reportsListsService.summaryReportYearConversion(res?.data), false, '', '', false)];
            this.totalPropertyProfilingReport = this.reportsListsService.summaryReportConversion(res.data, PropertyProfilingReportConstant.totalColumn)

          } else {
            this.totalPropertyProfilingReport = [];
          }
          this.totalLoading = false;
        },
        error: (error: any) => {
          this.totalPropertyProfilingReport = [];
          this.totalLoading = false;
        }
      }
    )
  }

  getTypePropertyProfilingReport(year = null) {
    this.typePropertyProfilingReportLoading = true;
    this.reportPropertyProfilingService.getTypePropertyProfilingReport('parking', year).subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {

            let reportData = [];
            for (const [key, value] of Object.entries(res?.data[0])) {
              if (key !== 'year') {
                reportData.push({
                  category: this.reportsListsService.removeUnderscores(key),
                  value: value,
                });
              }
            }
            this.typePropertyProfilingReport = reportData;
          } else {
            this.typePropertyProfilingReport = [];
          }
          this.typePropertyProfilingReportLoading = false;
        },
        error: (error: any) => {
          this.typePropertyProfilingReport = [];
          this.typePropertyProfilingReportLoading = false;
        }
      }
    )
  }

  getTypeGradePropertyProfilingReport(year = null) {
    this.typeGradePropertyProfilingReportLoading = true;
    this.reportPropertyProfilingService.getTypeGradePropertyProfilingReport('unit_type', year).subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            let column = ['neigh_name', 'property_grade'];

            this.typeGradePropertyProfilingReport = this.reportsListsService.convertReport(res?.data, column, []);
          } else {
            this.typeGradePropertyProfilingReport = [];
          }
          this.typeGradePropertyProfilingReportLoading = false;
        },
        error: (error: any) => {
          this.typeGradePropertyProfilingReport = [];
          this.typeGradePropertyProfilingReportLoading = false;
        }
      }
    )
  }

  getGradeFurnishedPropertyProfilingReport(year = null) {
    this.gradeFurnishedPropertyProfilingReportLoading = true;
    this.reportPropertyProfilingService.getGradeFurnishedPropertyProfilingReport('furnished', year).subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            let column = ['neigh_name', 'property_grade'];
            this.gradeFurnishedPropertyProfilingReport = this.reportPropertyProfilingService.convertfurnishedProfiling(res.data, column)
          } else {
            this.gradeFurnishedPropertyProfilingReport = [];
          }
          this.gradeFurnishedPropertyProfilingReportLoading = false;
        },
        error: (error: any) => {
          this.gradeFurnishedPropertyProfilingReport = [];
          this.gradeFurnishedPropertyProfilingReportLoading = false;
        }
      }
    )
  }

  getAmenitiesPropertyProfilingReport(year = null) {
    this.amenitiesPropertyProfilingReportLoading = true;
    this.reportPropertyProfilingService.getAmenitiesPropertyProfilingReport(year).subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            let column = ['amenities', 'property_grade'];

            this.amenitiesPropertyProfilingReport = this.reportPropertyProfilingService.convertAmenitiesReport(res?.data, column);
          } else {
            this.amenitiesPropertyProfilingReport = [];
          }
          this.amenitiesPropertyProfilingReportLoading = false;
        },
        error: (error: any) => {
          this.amenitiesPropertyProfilingReport = [];
          this.amenitiesPropertyProfilingReportLoading = false;
        }
      }
    )
  }

  selectedYear(data: any) {
    switch (data.reportType) {
      case this.reports.share_Of_Parking:
        this.getTypePropertyProfilingReport(data.year);
        break;
      case this.reports.neighborhoodAndGradewiseParkingratio:
        this.getTypeGradePropertyProfilingReport(data.year);
        break;
      case this.reports.neighborhoodsAndGradeWiseUnitFurnishingStatus:
        this.getGradeFurnishedPropertyProfilingReport(data.year);
        break;
      case this.reports.shareofamenities:
        this.getAmenitiesPropertyProfilingReport(data.year);
        break;
      case 'totalReport':
        this.getTotalPropertyProfilingReport(data.year);
        break;
    }
  }
}
