import { Component, HostListener, Input, SimpleChanges } from '@angular/core';
import { ReportOfficeSpaceRentalConstants } from './report-office-space-rental.constants';
import { SharedService } from 'src/app/common/services/shared.service';
import { OfficeSpaceRentalMenuEnum } from './report-office-space-renta.enum';
import { ReportOfficeSpaceRentalService } from './report-office-space-rental.service';
import { units } from './report-office-space-rental.interface';
import { Subscription } from 'rxjs';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { ReportService } from 'src/app/reports/services/report.service';
import { ReportChartColumn } from '../../models/reports.model';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from 'src/app/common/services/store.service';
import { UnitTypeEnum } from '../../models/unit-type-enum';
import moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-report-office-space-rental',
  templateUrl: './report-office-space-rental.component.html',
  styleUrls: ['./report-office-space-rental.component.scss']
})
export class ReportOfficeSpaceRentalComponent {
  RYDCHECK=environment.RYDCHECK;
  @Input() params: any;
  UnitTypeEnum = UnitTypeEnum;
  overViewMenu:any = ReportOfficeSpaceRentalConstants.overViewMenu;
  officeSpaceRentalMenuEnum = OfficeSpaceRentalMenuEnum;
  unitsColumns = ReportOfficeSpaceRentalConstants.unitsColumns;
  rentUnitSizeColumns = ReportOfficeSpaceRentalConstants.rentUnitSizeColumns;
  chartView = ReportsChartEnum;
  unitsizeExpandColumns = ReportOfficeSpaceRentalConstants.unitsizeExpandColumns
  unitSizeDropdown = ReportOfficeSpaceRentalConstants.unitSizeDropdown;

  currentScroll: any = '';
  unitsCount: number = 0;
  rentUnitSizeCount: number = 0;
  unitsLoading = false;
  rentUnitSizeLoading = false;
  overviewLoading = false;
  overviewGradeLoading = false;
  overviewGraphLoading = false;
  unitsData: units[] = [];
  rentUnitSizeData: any[] = [];
  averageData: any = {};
  occupancyData: any = {};
  averageGradeData: any[] = [];
  occupancyGradeData: any[] = [];
  overviewChartData: any = {};
  CurrencyCode: string = '';
  placeName = '';
  houserentalChartLoading: boolean = false;
  houserentalChart: any = [];
  retailRentalChartReport: any[] = [];
  userInfo: any = 0;
  placeCode: string = '';
  averageTotalGradeValue: any = 0;
  occupancyTotalGradeValue: any = 0;
  selectedChartValue: any[] = [];
  moreText: any;
  langName: any;
  isFirstTime = true;
  isChart = true;
  officeRentOption: string = 'officeRentOption';
  downloadLoading: any = {
    a: false,
    b: false
  };

  unitsData$ = new Subscription();
  rentUnitSizeData$ = new Subscription();
  overviewData$ = new Subscription();
  overviewDataGrade$ = new Subscription();
  downloadRentalReport$ = new Subscription();
  sqm_unit:string='';
  retailRentalChartReportArray: any = [];


  constructor(
    private sharedService: SharedService,
    private officeService: ReportOfficeSpaceRentalService,
    public reportService: ReportService,
    private alertService: AlertService,
    private translate: TranslateService,
    private storeService: StoreService
  ) {
    this.sqm_unit = 'reports.'+GlobalContants.keySymbolContants.squareMeterUnit;
    this.sharedService.language$.subscribe(lang => {
      this.langName = lang;
      if (lang && !this.isFirstTime) {
        this.getReports();
        this.isChart = false;
      }
    })
  }

  ngOnInit() {
    // this.getOverviewGraph();
    this.getReports();
    this.isFirstTime = false;
    this.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
    this.placeName = this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceName);
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.officeService.unitsPayload.pageNumber = 0;
      this.officeService.rentUnitSizePayload.pageNumber = 0;
      this.getReports();
    }
  }
  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(): void {
    this.storeService.add(this.officeRentOption, this.selectedChartValue);
  }
  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }
  getReports() {
    this.getUnitsList();
    this.getRentUnitSizeList();
    this.getOverview();
    this.getOverviewGrade();
    this.getOverviewGraph();
  }
  /**
   * Overview all type Data
   */
  getOverview() {
    this.overviewLoading = true;
    this.overviewData$ = this.officeService.getOverview().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.averageData = res.data.average_rate;
            this.averageData['untillDate'] = this.averageData.max_date ? moment.utc(this.averageData.max_date).local().format('DD MMM YYYY') : null;
            this.averageData['totalaverage_rate'] = this.reportService.calcPercentage(this.averageData.prev_avg_rent, this.averageData.avg_rent);

            this.occupancyData = res.data.occupacy_rate;
            this.occupancyData['untillDate'] = this.occupancyData.max_date ? moment.utc(this.occupancyData.max_date).local().format('DD MMM YYYY') : null;
            this.occupancyData['totaloccupancy_rate'] = this.reportService.calcPercentage(this.occupancyData.prev_occupacy_rate, this.occupancyData.occupacy_rate);

          }
          this.overviewLoading = false;
        },
        error: (error: any) => {
          this.overviewLoading = false;
        }
      }
    )
  }

  barSortData(data: any){
    let overviewArray: any = Object.entries(data);
    return overviewArray.sort((a: any, b: any) => b[1].category - a[1].category);
  }

  /**
   * Overview Grade Data
   */
  getOverviewGrade() {
    this.overviewGradeLoading = true;
    this.overviewDataGrade$ = this.officeService.getOverviewGrade().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {

            let overviewAverageRateData: any = { ...this.reportService.getPercentageOverview(res.data.average_rate) };
            this.averageGradeData = this.barSortData(overviewAverageRateData);


            //this.averageGradeData = this.barSortData(res.data.average_rate)
            // let averageGradeData: any = res.data.average_rate;
            // this.occupancyGradeData = this.barSortData(res.data.occupancy)
            let overviewOccupancyData: any = { ...this.reportService.getPercentageOverview(res.data.occupancy) };
            this.occupancyGradeData = this.barSortData(overviewOccupancyData);

            //Grade Total
            let averageObj: any = Object.fromEntries(this.averageGradeData);
            this.averageTotalGradeValue = this.reportService.calculateTotalGradeValue(averageObj);
            //Grade Total
            let occupancyObj: any = Object.fromEntries(this.occupancyGradeData);
            this.occupancyTotalGradeValue = this.reportService.calculateTotalGradeValue(occupancyObj);
          }else{
            this.averageGradeData = [];
            this.occupancyGradeData = [];
          }
          this.overviewGradeLoading = false;
        },
        error: (error: any) => {
          this.overviewGradeLoading = false;
        }
      }
    )
  }

   /**
   * Overview Graph Data
   */
  //  getOverviewGraph() {
  //   this.overviewGraphLoading = true;
  //   this.overviewDataGrade$ = this.officeService.getOverviewGraph().subscribe(
  //     {
  //       next: (res: any) => {
  //         if (res.status === 200) {
  //           this.overviewChartData = res.data;

  //             Object.keys(this.overviewChartData).forEach((key, index) => {
  //               let data = this.overviewChartData[key];
  //               this.overviewChartData[key] = data.map((obj: any) => {
  //                 return {
  //                   category: obj.year.toString(),
  //                   value: obj.count,
  //                   name: key
  //                 }
  //               })
  //               let officeRentOption: any = this.storeService.get(this.officeRentOption);
  //               if(this.storeService.get(this.officeRentOption) && officeRentOption?.length > 0){
  //                 this.selectedChartValue = this.storeService.get(this.officeRentOption);
  //                 this.retailRentalChartReport = Object.keys(this.overviewChartData)
  //                   .filter(key => this.selectedChartValue.includes(key))
  //                   .reduce((obj: any, key: any) => {
  //                     obj[key] = this.overviewChartData[key];
  //                     return obj;
  //                   }, {});
  //               }else{
  //                 if (index === 0 && this.isChart) {
  //                   this.selectedChartValue = [key];
  //                   let obj: any = {};
  //                   obj[key] = this.overviewChartData[key]
  //                   this.retailRentalChartReport = obj;
  //                 }
  //               }
  //             })
  //         }else{
  //           this.overviewChartData = null;
  //         }
  //         this.overviewGraphLoading = false;
  //       },
  //       error: (error: any) => {
  //         this.overviewGraphLoading = false;
  //         this.overviewChartData = null
  //       }
  //     }
  //   )
  // }
  getOverviewGraph() {
    this.overviewGraphLoading = true;
    this.overviewDataGrade$ = this.officeService.getOverviewGraph().subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          this.overviewChartData = res.data;
          let officeRentOption: any = this.storeService.get(this.officeRentOption);
          if (officeRentOption && officeRentOption?.length > 0) {
            this.selectedChartValue = officeRentOption;
          } else {
            // Select the first three keys by default
            this.selectedChartValue = Object.keys(this.overviewChartData).slice(0, 3);
          }
              // Transform the data
              Object.keys(this.overviewChartData).forEach((key) => {
                let data = this.overviewChartData[key];
                this.overviewChartData[key] = data.map((obj: any) => ({
                  category: obj.year.toString(),
                  value: obj.count,
                  name: key
                }));
              });
          // Update the retail rental chart report
          this.retailRentalChartReport = Object.keys(this.overviewChartData)
            .filter(key => this.selectedChartValue.includes(key))
            .reduce((obj: any, key: any) => {
              obj[key] = this.overviewChartData[key];
              return obj;
            }, {});
          this.retailRentalChartReportArray = this.sortObjectByGrades(this.retailRentalChartReport)
        } else {
          this.overviewChartData = null;
        }
        this.overviewGraphLoading = false;
      },
      error: (error: any) => {
        this.overviewGraphLoading = false;
        this.overviewChartData = null;
      }
    });
  }
  getReportKeys(): string[] {
    if (this.overviewChartData) {
      return Object.keys(this.overviewChartData).sort((a, b) => this.sortGrades(a, b));
    }
    return [];
  }
  /**
   * Get Unit list data
   */
  getUnitsList() {
    this.unitsLoading = true;
    this.unitsData$ = this.officeService.getUnits(this.officeService.unitsPayload).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.unitsData = res.data;
            if (this.officeService.unitsPayload.pageNumber == 0) {
              this.unitsCount = res.count;
              this.overViewMenu.map((e:any)=>{
                if(e.name == OfficeSpaceRentalMenuEnum.unit){
                  e['count'] = this.reportService.formatInternationNum(this.unitsCount);
                }
              })
            }
          } else {
            this.unitsData = [];
            this.unitsCount = 0;
            if (this.officeService.unitsPayload.pageNumber == 0) {
              this.overViewMenu.map((e:any)=>{
                if(e.name == OfficeSpaceRentalMenuEnum.unit){
                  e['count'] = 0;
                }
              })
            }
          }
          this.unitsLoading = false;
        },
        error: (error: any) => {
          this.unitsLoading = false;
          this.unitsData = [];
          this.unitsCount = 0;
          if (this.officeService.unitsPayload.pageNumber == 0) {
            this.overViewMenu.map((e:any)=>{
              if(e.name == OfficeSpaceRentalMenuEnum.unit){
                e['count'] = 0;
              }
            })
          }
        }

      }
    )
  }
  /**
   * Get rent - unit size wise data
   */
  getRentUnitSizeList() {
    this.rentUnitSizeLoading = true;
    this.rentUnitSizeData$ = this.officeService.getRentUnitSize(this.officeService.rentUnitSizePayload).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            if(res.data){
              let cols = [
                'grade_a+_range',
                'grade_a_range',
                'grade_b_range',
                'grade_c_range',
              ]

              res.data.map((el:any)=>{
                Object.keys(el).map((e:any)=>{
                  if(cols.includes(e)){
                    el[e] = this.reportService.rangeToNumberFor(el[e]);
                  }
                  if(el.details && el.details.length>0){
                    el.details.map((detail:any)=>{
                      Object.keys(detail).map((key:any)=>{
                        if(cols.includes(key)){
                          detail[key] = this.reportService.rangeToNumberFor(detail[key]);
                        }
                      })
                    })
                  }
                })
              })
            }
            this.rentUnitSizeData = res.data;
            if (this.officeService.rentUnitSizePayload.pageNumber == 0) {
              this.rentUnitSizeCount = res.count ;
              // this.overViewMenu.map((e:any)=>{
              //   if(e.name == OfficeSpaceRentalMenuEnum.rentUnitSizeWise){
              //     e['count'] = this.reportService.formatInternationNum(this.rentUnitSizeCount);
              //   }
              // })

            }
          } else {
            this.rentUnitSizeData = [];
            this.rentUnitSizeCount = 0;
            // if (this.officeService.rentUnitSizePayload.pageNumber == 0) {
            //   this.overViewMenu.map((e:any)=>{
            //     if(e.name == OfficeSpaceRentalMenuEnum.rentUnitSizeWise){
            //       e['count'] = 0;
            //     }
            //   })
            // }
          }
          this.rentUnitSizeLoading = false;
        },
        error: (error: any) => {
          this.rentUnitSizeLoading = false;
          // if (this.officeService.rentUnitSizePayload.pageNumber == 0) {
          //   this.overViewMenu.map((e:any)=>{
          //     if(e.name == OfficeSpaceRentalMenuEnum.rentUnitSizeWise){
          //       e['count'] = 0;
          //     }
          //   })
          // }
        }
      }
    )
  }
  /**
   *
   * @param menu
   * if click menu scroll particular div
   */
  menuChangeValue(menu: any) {
    this.currentScroll = menu.name;
    setTimeout(() => {
      this.scrollPoint(menu.name);
    })
  }
  scrollPoint(menu: string) {
    this.sharedService.scrollMenu(menu);
  }
  /**
   *
   * @param data
   * more then 1 filter & click to dropdown payload set max year
   */
  selectedYear(data: any) {
    if (data.reportType === 'units') {
      this.officeService.unitsPayload.pageNumber = 0;
      this.officeService.unitsPayload.year = data.year;
      this.getUnitsList();
    } else {
      this.officeService.rentUnitSizePayload.pageNumber = 0;
      this.officeService.rentUnitSizePayload.year = data.year;
      this.getRentUnitSizeList();
    }
  }
  /**
   *
   * @param e
   * @param report
   * table pagination count change
   */
  onPageEvent(e: any, report: any) {
    if (e.previousPageIndex !== e.pageIndex) {
      if (report === this.officeSpaceRentalMenuEnum.unit) {
        this.officeService.unitsPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getUnitsList();
      }
      if (report === this.officeSpaceRentalMenuEnum.rentUnitSizeWise){
        this.officeService.rentUnitSizePayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getRentUnitSizeList();
      }
    }
  }
  /**
   *
   * @param event
   * @param report
   * table thead sorting
   */
  sortData(event: any, report: any) {
    let sort = {
      column: event.active,
      order: event.direction
    }
    if (report === this.officeSpaceRentalMenuEnum.rentUnitSizeWise) {
      this.officeService.rentUnitSizePayload.pageNumber = 0;
      this.officeService.rentUnitSizePayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getRentUnitSizeList();
    }
    if (report === this.officeSpaceRentalMenuEnum.unit) {
      this.officeService.unitsPayload.pageNumber = 0;
      this.officeService.unitsPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getUnitsList();
    }
  }
  /**
   *
   * @param event
   * @param type
   * Download Excel in table
   */
  downloadExcel(event: any, type: string, key: any) {
    this.downloadRentalReport(type, key);
  }
  downloadRentalReport(type: string, key: any) {
    this.downloadLoading[key] = true;
    this.downloadRentalReport$ = this.officeService.downloadOfficeRentalReport(type, this.userInfo?.user_id).subscribe(
      {
        next: (res: any) => {
          this.downloadLoading[key] = false;
          if (res.status === 200) {
            const downloadUrl = `${this.sharedService.downloadExcelPath()}?file_name=${res.data}&city_code=${this.placeCode.toLowerCase()}&module_name=office_rental`;
            this.sharedService.downloadExcelUrl(downloadUrl);
            this.alertService.success(this.translate.instant('reports.downloadRequestSubmitted'));

          } else {
            this.alertService.warn(res.message)
          }
        },
        error: (error: any) => {
          this.downloadLoading[key] = false;
        }
      }
    )
  }
  /**
   *
   * @param value
   * Data will change when click on the Range & Average radio button
   */
  dropDownChange(value: any){
    if(value === 'Range'){
      this.rentUnitSizeColumns = ReportOfficeSpaceRentalConstants.rentUnitSizeColumns;
      this.unitsizeExpandColumns = ReportOfficeSpaceRentalConstants.unitsizeExpandColumns;
    } else {
      this.rentUnitSizeColumns = ReportOfficeSpaceRentalConstants.retailUnitsizeAverageColumns;
      this.unitsizeExpandColumns = ReportOfficeSpaceRentalConstants.unitsizeExpandAverageColumns;
    }
  }
  // getOpacity(index:number, value: any, type: string): string{
  //   // Adjust the factor and starting value as per your requirement
  //   return this.officeService.changeOpacity(index, value, type);
  // }
  // getNumber(nbr: any){
  //   return +nbr;
  // }
  /**
   *
   * @param e
   * @param value
   * @returns
   * On clicking the dropdown, the chart will be added or removed
   */
  selectChart(e: any, value: any) {
    let index = this.selectedChartValue.indexOf(value)
    if (!e.checked) {
      if(this.selectedChartValue.length === 1){
        this.alertService.warn(this.translate.instant('atLeastOneMust'));
        e.source.checked = true;
      } else{
          this.selectedChartValue.splice(index, 1);
      }
    } else {
      if (this.selectedChartValue.length > 2) {
        this.alertService.warn(this.translate.instant('youCannotSelectMoreThan'));
        e.source.checked = false;
        return;
      } else{
      this.selectedChartValue.push(value)
    }
    }
    this.retailRentalChartReport = Object.keys(this.overviewChartData)
      .filter(key => this.selectedChartValue.includes(key))
      .reduce((obj: any, key: any) => {
        obj[key] = this.overviewChartData[key];
        return obj;
      }, {});
      this.retailRentalChartReportArray = this.sortObjectByGrades(this.retailRentalChartReport)
      this.overviewGraphLoading = false;
      setTimeout(() => {
        let firstEleCount: any = document.querySelector('.setext')?.textContent?.split(',')[0].length;
        if(this.selectedChartValue.length == 2 && firstEleCount > 6){
          this.moreText = `(1) ${this.translate.instant('reports.more')}`;
          } else if(this.selectedChartValue.length == 3 && firstEleCount > 6){
            this.moreText = `(2) ${this.translate.instant('reports.more')}`;
          }else if(this.selectedChartValue.length == 2 && firstEleCount < 6){
            this.moreText = '';
          }else{
            this.moreText = `(1) ${this.translate.instant('reports.more')}`;
          }
      },1)

  }
   /**
   * Custom sort function for property grade
   * @param obj object
   * @returns return sorted data
   */
   sortObjectByGrades(obj: any) {
    const sortedKeys = Object.keys(obj).sort((a, b) => this.sortGrades(a, b));
    const sortedObjArray: any = [];

    sortedKeys.forEach(key => {

      let tempObj = {
        [key] : obj[key]
      }
      sortedObjArray.push(tempObj);
    });
    return sortedObjArray;
  }
   sortGrades(a: string, b: string) {
    const gradeOrder = ['Grade A++', 'Grade A+', 'Grade A','Grade B++', 'Grade B+', 'Grade B', 'Grade C++', 'Grade C+', 'Grade C', 'Grade D++', 'Grade D+', 'Grade D', 'Undefined'];

    // Find the index of each grade in the predefined order
    const indexA = gradeOrder.indexOf(a);
    const indexB = gradeOrder.indexOf(b);

    // If either grade is not found in the array, treat it as coming after defined grades
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  }


  ngOnDestroy() {
    this.unitsData$.unsubscribe();
    this.rentUnitSizeData$.unsubscribe();
    this.overviewData$.unsubscribe();
    this.downloadRentalReport$.unsubscribe();
    this.overviewDataGrade$.unsubscribe();
    this.storeService.clear(this.officeRentOption);
  }
}
