import { Injectable, signal } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ReportsViewEnum } from '../models/enums/reports.enum';
import { environment } from '../../../environments/environment';
import { apiEndPoints } from '../../common/constants/api-endpointsconstant';
import { ApiService } from '../../common/services/api.service';
import { SharedService } from '../../common/services/shared.service';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionPayload } from '../models/users.model';
import { LoaderType } from 'src/app/common/models/enums/common.enum';
import { DecimalPipe } from '@angular/common';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/common/components/alert/alert.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  CurrentYear = new Date().getFullYear();
  reportView$ = new BehaviorSubject<number>(ReportsViewEnum.mapView);
  reportQueryToggle$ = new Subject<boolean>();
  selectedReport$ = new BehaviorSubject<any>({flag: false});
  reportPermission$ = new Subject<boolean>();
  selectedObjective: any | null = null;
  selectedStudies: any | null = null;
  selectedRepored: any | null = null;
  reportFilter: any | null = [];
  queryParams: any = null;
  currentReport: any;
  filterQueryParams: any = null;
  filterFlag: boolean = false;
  userSubscription: any = [];
  user_preference = Object(null);
  transactionPropertyDetails = new Subject<any>();
  savedFilters:any = [];
  showDefaultSaveButtonState!:boolean;
  showDefaultSaveButton!:boolean;
  master_filter_list: any=[];
  projectReportView: boolean = false;
  multi_story_residential$$=signal(false);
  LockReport: boolean=true;
  userSublength: number = -1;
  
  constructor(
    private apiServive: ApiService,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private decimalPipe: DecimalPipe,
    private translate: TranslateService,
    private alertService: AlertService
  ) {     
    this.getSearchMaster();
  }

  setLockReport(lock: boolean) {
    this.LockReport = lock;
  }

  getLockReport() {
    return this.LockReport;
  }


  getSearchMaster(){
    this.getSearchMasterFilters().subscribe({
      next: (res:any)=>{
        if(res.data){
          this.master_filter_list=res.data;
        }else{
          this.master_filter_list=[]
        }
      },
      error:()=>{
        this.master_filter_list=[]
      }
    })
  }
  getObjectStudyReport() {
    let url: string = `${environment.miUrl}${apiEndPoints.objStudyReport}`;
    return this.apiServive.get(url);
  }

  getUserSubscription() {
    const userInfo: any = this.sharedService.getUserInfo();
    let url: string = `${environment.miBaseUrl}${apiEndPoints.userSubscription}?customer_id=${userInfo.user_id}&user_id=${userInfo.user_id}&sort_asc=false&is_count=true&status=1`;
    return this.apiServive.get(url);
  }

  getReportData(req: any) {
    const userInfo: any = this.sharedService.getUserInfo();
    const url: string = `${environment.miBaseUrl}${apiEndPoints.reports}?status=1&sort_asc=true&sort_by=sort_order&is_count=false&group_id=${req?.gid}&id=${req?.rid}&user_id=${userInfo.user_id}`;
    return this.apiServive.get(url, false, LoaderType.normal);
  }

  getReportDownload(queryParams: any, type: any) {
    const changeParams = this.setfilterQueryParams(queryParams);
    const userInfo: any = this.sharedService.getUserInfo();
    const url: string = `${environment.miUrl}${apiEndPoints.reportDownload}${changeParams}&user_id=${userInfo.user_id}&type=${type}`;
    return this.apiServive.get(url);
  }
  getReportDownloadHouseVilla(queryParams: any, type: any, neigh_name:string='') {
    const changeParams = this.setfilterQueryParams(queryParams);
    const userInfo: any = this.sharedService.getUserInfo();
    let url: string = `${environment.miUrl}${apiEndPoints.downloadHouseVillaReport}${changeParams}&user_id=${userInfo.user_id}&type=${type}`;
    if(neigh_name) url+=`&neigh_name=${neigh_name}`
    return this.apiServive.get(url);
  }

  downloadExcelUrl(filePath:string, placeCote:string){
    const downloadUrl = `${this.sharedService.downloadExcelPath()}?file_name=${filePath}&city_code=${placeCote.toLowerCase()}&module_name=house_and_villa`;
    this.sharedService.downloadExcelUrl(downloadUrl);
  }
  subscriptionRequest(payload: SubscriptionPayload) {
    const url: string = `${environment.miBaseUrl}${apiEndPoints.subscriptionRequest}`;
    return this.apiServive.post(url, payload);
  }

  setCurrentReport(report: any) {
    this.currentReport = report;
  }
  getCurrentReport() {
    return this.currentReport;
  }

  getfilterData(
    name: string,
    table_name: string,
    payload: any = null,
    search_text: string = '',
    selected_param=''
  ): Observable<any> {
    let params = '';
    if (payload) {
      let filterParams = { ...payload };

      delete filterParams.rid;
      delete filterParams.oid;
      delete filterParams.gid;
      delete filterParams.zoom;
      delete filterParams.centroid;
      delete filterParams.polygon;
      delete filterParams.srid;

      let filter:any = {};
      for(let key in filterParams){
        if(['property_category','unit_category'].indexOf(key) > -1){
          if(filterParams[key] &&  key != selected_param){
            filter[key] = filterParams[key];
          }
        }      
      }
      filter['year']=this.CurrentYear;
      params = JSON.stringify(filter).replace(/[+]/g, '%2B'); // handle +
    }

    ////Appending reportID in Report APIs
    let reportID = this.activatedRoute.snapshot.queryParams['rid'];

    let url = `${environment.miBaseUrl}${apiEndPoints.casecaddingfilter}?param=${name}&table_name=${table_name}`;

    if (reportID) {
      url += `&report_id=${reportID}`;
    }
    if (search_text) {
      url += `&search_text=${search_text}`;
    }
    if (params) url += `&filter=${encodeURIComponent(params)}`;
    return this.apiServive.get(url, false)
  }

  getCombinefilterData(
    filter_data:any,
    payload: any = null,
  ): Observable<any> {
    let params = '';
    if (payload) {
      let filter:any = {};
      let removeParm = ['property_category'];
      for (let key in payload) {
        if (removeParm.indexOf(key) > -1) {
          filter[key] = payload[key];
        }
      }
      params = Object.keys(filter).length? JSON.stringify(filter).replace(/[+]/g, '%2B'):''; // handle +
    }

    ////Appending reportID in Report APIs
    let reportID = this.activatedRoute.snapshot.queryParams['rid'];

    let url = `${environment.miBaseUrl}${apiEndPoints.combineFilterDataList}`;

    if (reportID) {
      url += `?report_id=${reportID}`;
    }
    if (params && params) url += `&filter=${encodeURIComponent(params)}`;
    return this.apiServive.post(url, filter_data)
  }

  getRecentSavedReport(user_id: number, searchTerm: string = "") {
    let url: string = `${environment.miBaseUrl}${apiEndPoints.recentSavedReport}?user_id=${user_id}&order_by=added_date&group_by=added_date&sort_asc=false`;
    if (searchTerm) {
      const encodedSearchTerm = encodeURIComponent(searchTerm)
        .replace(/[!'()*]/g, (char) => '%' + char.charCodeAt(0).toString(16).toUpperCase());
      url += `&report_name=${encodedSearchTerm}`;
    }
    return this.apiServive.get(url, false);
  }



  setFiltervalue(data: any) {
    let url: any = {};
    let selectedReport: any = this.getCurrentReport();
    // if (this.browser.getLocalValue('selected_report')) {
    //   selectedReport = JSON.parse(this.browser.getLocalValue('selected_report'));
    // }
    let master_f: any;
    if (selectedReport && selectedReport.filter) {
      for (let key in data) {
        master_f = selectedReport.filter.filter((ele: any) => key == ele.parameter_name);
        master_f = master_f[0];
        if (master_f) {
          if (master_f.type == 'range') {
            const value = data[key].split('-');
            url[key] = {
              min: value[0],
              max: value[1],
            };
          } else {
            url[key] = data[key];
          }
        }
      }

    }
    return url;
  }
  /**
   * Applied filter action perform
   * @param data
   * @returns
   */
  getFilterInURL(data: any) {
    let selectedReport: any = this.getCurrentReport();
    let url: any = {};
    let master_f: any;
    for (let key in data) {
      key = key.toLowerCase();
      if (data[key]) {
        master_f = selectedReport?.filter?.find((ele: any) => key == ele.parameter_name);
        if (master_f) {
          if (master_f.type == 'range') {
            let min = 0, max = 0;
            if (data[key].min == null || data[key].min == '' || data[key].min == undefined) {
            } else {
              min = data[key].min;
            }
            if (data[key].max == null || data[key].max == '' || data[key].max == undefined) {
            } else {
              max = data[key].max;
            }
            url[key] = `${min}-${max}`;
          } else if (master_f.type == 'single_select' || master_f.type == 'text') {
            if (data[key]) {
              url[key] = data[key];
            }
          } else if (master_f.type == 'multi_select' && data[key]) {
            if (key == "year" && selectedReport?.query?.component[0] == 'employment-data') {
              url[key] = data[key];
            } else {
              url[key] = data[key].join();
            }
          } else if (master_f.type == 'date_range') {
            if (data[key]) {
              url[key] = data[key];
            }
          }
        }
      } else {
        url[key] = null
      }
    }
    return url;
  }

  setCetegoryFilter(report: any) {
    let defaultFilter: any = {};
    if (report.filter?.length > 0) {
      report.filter.forEach((filter: any) => {
        if (filter.default && (filter.parameter_name == 'property_category' || filter.parameter_name == 'unit_category')) {
          defaultFilter[filter.parameter_name] = this.getDefault(filter.default);
        }
      });
    }
    return defaultFilter;
  }

  setDefaultFilter(report: any) {
    let defaultFilter: any = {};
    if (report.filter?.length > 0) {
      report.filter.forEach((filter: any) => {
        if (filter.default) {
          defaultFilter[filter.parameter_name] = filter.type == 'range'
            ? this.getRangeValue(filter.default)
            : this.getDefault(filter.default);

          // if(report.query.component.every((v: string) => v === 'employment-data' )  && filter.parameter_name === 'year'){
          //   defaultFilter['quarter'] = JSON.stringify({
          //     [filter.default] : [1,2,3,4]
          //   });
          // }
        }
      });
    }
    return defaultFilter;
  }

  setSavedFilterData(defaultFilter: any, saved: any) {
    let filter_data = { ...saved };
    if (filter_data?.quarter) {
      filter_data.quarter = JSON.stringify(filter_data.quarter);
    }
    if (Object.keys(defaultFilter).length > 0) {
      Object.keys(defaultFilter).forEach(v => {
        if (!filter_data[v]) {
          filter_data[v] = defaultFilter[v]
        }
      })
    }
    return filter_data;
  }
  getDefault(value: any) {
    if (Array.isArray(value)) {
      return value.join(',');
    }
    return value;
  }

  getRangeValue(rangeValue: string) {
    if (rangeValue) {
      const val = rangeValue.split('|');
      if (val[0]) {
        return val[0] == "0-0" ? null : val[0];
      }
      return rangeValue;
    }
    return '';
  }

  setfilterQueryParams(paramsData: any) {
    let filter = `?report_id=${paramsData.rid}`;


      if (paramsData?.polygon) { /// If polygon filter applied then disable Neighbourhood Filter
        filter += `&polygon=${paramsData.polygon}`;
        filter += `&neigh_name=null`;
      } else if(paramsData?.neigh_name){
        filter += `&polygon=null`;
        filter += `&neigh_name=${paramsData.neigh_name}`;
      }


    if (paramsData?.year) {
      filter += `&year=${paramsData.year}`;
    }

    if (paramsData?.property_category) {
      filter += `&property_category=${paramsData.property_category}`;
    }

    if (paramsData?.unit_category) {
      filter += `&unit_category=${paramsData.unit_category}`;
    }


    if (Object.keys(this.filterData(paramsData)).length > 0) {
      let filterValue = JSON.stringify(this.filterData(paramsData)).replace(/[+]/g, '%2B');
      filter += `&filter=${encodeURIComponent(filterValue)}`;
    }
    return filter;
  }

  filterData(paramsData: any) {
    let filterParams = { ...paramsData };

    delete filterParams.rid;
    delete filterParams.oid;
    delete filterParams.gid;
    delete filterParams.zoom
    delete filterParams.centroid
    delete filterParams.polygon
    delete filterParams.srid
    delete filterParams.property_category
    delete filterParams.unit_categorys

    if (filterParams?.year) {
      delete filterParams.year;
    }
    if (filterParams?.neigh_name) {
      delete filterParams.neigh_name;
    }
    if (filterParams?.property_category) {
      delete filterParams.property_categoryp;
    }
    if (filterParams?.unit_category) {
      delete filterParams.unit_category;
    }
    return filterParams;
  }

  yearMaxValue(year: any): any {
    return year ? Math.max(...year.split(',')) : null;
  }

  // setUserPreference(body: UserPreference) {
  //   const url: string = `${environment.miUrl}${apiEndPoints.userPreference}`;
  //   return this.apiServive.post(url, body);
  // }

  subscriptionPayload(report: any): SubscriptionPayload {
    const userInfo: any = this.sharedService.getUserInfo();
    const payload = {
      user_id: userInfo.user_id,
      user_name: userInfo.uname || '',
      user_email: userInfo.email || '',
      user_company: userInfo.companies || '',
      entity_id: report.report_id,
      entity_type: 'report',
      status: 1,
      added_by: userInfo.user_id
    }
    return payload;
  }

  formatInternationNum(num: any, digits:number=0) {
    if (!num) {
      return '';
    }
    let ans:any = '';
    if (isNaN(num))
      return "Invalid Input"
    if (num >= 1000 && num <= 999999) {
      ans = this.decimalPipe.transform((num / 1000), '1.0-1') + this.translate.instant('thousand');
    } else if (num >= 1000000 && num <= 999999999) {
      ans = this.decimalPipe.transform((num / 1000000), '1.0-1') + this.translate.instant('million');
    } else if (num >= 1000000000 && num <= 999999999999) {
      ans = this.decimalPipe.transform((num / 1000000000), '1.0-1') + this.translate.instant('billion');
    } else {
      ans = this.decimalPipe.transform(num, '1.0-1') + ""
      if(ans.includes('.00')) ans = this.decimalPipe.transform((ans.split('.00')[0]), '1.0-1');
    }
    return ans;
  }

  calcPercentage(y: number, x: number) {
    let lastYear = (Number(y));
    let currentYear = (Number(x));
    if(lastYear && currentYear){
      //  return (((currentYear/lastYear)*100)-100)?.toFixed(2);
      return (((currentYear-lastYear)/lastYear)*100)?.toFixed(2);
    } else {
      return '-';
    }
 }

 saveFilter(body:any) {
    const url: string = `${environment.miUrl}${apiEndPoints.saveFilter}`;
    return this.apiServive.post(url, body);
  }
  /**
   * Function is used to get the saved filter
   * @param searchTerm : used to search the filter on the basis of name;
   */
  getSavedFilter(searchTerm:string = "", sort_asc:boolean = false) {
    const UserId: any = this.sharedService.UserId;
    if(!this.showDefaultSaveButtonState) this.showDefaultSaveButton = true;
    let url: string = `${environment.miUrl}${apiEndPoints.getSavedFilter}?user_id=${UserId}`;
    if (searchTerm) {
      const encodedSearchTerm = encodeURIComponent(searchTerm);
      url += `&search_text=${encodedSearchTerm}`;
    }
    if(!sort_asc) {
      let sort_order = JSON.stringify({"order":"desc", "column":"added_date"})
      url += `&sort_order=${sort_order}`;
    } else if(sort_asc) {
      let sort_order = JSON.stringify({"order":"asc", "column":"added_date"})
      url += `&sort_order=${sort_order}`;
    }
    this.apiServive.get(url).subscribe({
      next: (res: any) => {
        if(res.status == 200) {
          this.savedFilters = res.data;
          this.showDefaultSaveButton = false;
          this.showDefaultSaveButtonState = true;
        }else{
          this.savedFilters = [];
          if(!searchTerm) {
            this.showDefaultSaveButton = true;
            this.showDefaultSaveButtonState = false;
          }
        }
      },
      error: (err: any) => {
        this.savedFilters = [];
        this.showDefaultSaveButton = true;
        this.showDefaultSaveButtonState = false;
      }
    });
  }
  /**
   * This function is used to delete the saved Filter
   * @param filter_id : filter id
   * @returns
   */
  deleteFilter(filter_id:string) {
    const userInfo: any = this.sharedService.getUserInfo();
    const url: string = `${environment.miUrl}${apiEndPoints.getSavedFilter}?filter_id=${filter_id}&user_id=${userInfo.user_id}`;
    return this.apiServive.delete(url);
  }


  getSearchMasterFilters(){
    let url = `${environment.miBaseUrl}${apiEndPoints.searchMaster}?&status=1`;
    return this.apiServive.get(url);
  }

/**
 * This function use for the getting filter label of given filter_key
 * @param key
 * @param value_list
 * @returns
 */
  getParameterLabel(key: string, value_list: any=[]) {
    let filter = value_list.find((f: any) => f.parameter_name == key);
    if (filter) {
     return filter?.label[this.sharedService.getCurrentLangID()] || filter?.label['1']
    }
    return key;
  }
  /**
   * Api integration to get the report count
   * @returns  total count of saved report.
   */
  getUserReportFilterCount() {
    const userInfo: any = this.sharedService.getUserInfo();
    const url: string = `${environment.miUrl}${apiEndPoints.userReportCount}?user_id=${userInfo.user_id}`;
    return this.apiServive.get(url);
  }
/**
 * This Function delete the selected Saved Filter in Bulk.
 * @returns
 */
  bulkDeleteSavedFilter() {
    const userInfo: any = this.sharedService.getUserInfo();
    let filterId = this.sharedService.savedFilter.join(',')
    let url = `${environment.miUrl}${apiEndPoints.getSavedFilter}?user_id=${userInfo.user_id}&filter_id=${filterId}`;
    return this.apiServive.delete(url);
  }
  /**
   * This Function is used to update the saved filter
   * @param body updated filter
   * @returns
   */
  updateSavedFilter(body:any, savedFilter:any) {
    const userInfo: any = this.sharedService.getUserInfo();
    const url: string = `${environment.miUrl}${apiEndPoints.getSavedFilter}?user_id=${userInfo.user_id}&filter_id=${savedFilter.id}`;
    return this.apiServive.patch(url, body);
  }
  /**
   * 
   * @param grades 
   * @returns 
   * The count of all grades has been totaled
   */
  calculateTotalGradeValue(grades: any) {
    return Object.values(grades)
      .filter(value => value !== null)
      .reduce((sum: any, current: any) => sum + current, 0);
  }
  /**
   * 
   * @param gradeValue 
   * @param totalGradeValue 
   * @returns 
   * The grade-wise percentage has been calculated
   */
  calculateGradePercentage(gradeValue: any, totalGradeValue: any): any {
    if (gradeValue === null) return 0;
    const percentage = (gradeValue / totalGradeValue) * 100;
    return this.decimalPipe.transform(percentage, '1.2-2');
  }

  downloadReport(type:string, user_id:any){
    const url: string = `${environment.miUrl}${apiEndPoints.reportDownload}${this.filterQueryParams}&type=${type}&user_id=${user_id}`;
    return this.apiServive.get(url);
  }

  /**
   * 
   * @param data 
   * @returns number
   * The count of all grades has been totaled
   */
  getMaxValue(data: any) {
    let maxValue = 0;
    for (let val in data) {
      maxValue += data[val]
    }
    return maxValue;
  }

  getPercentageOverview(data: any) {
    let dataChange: any = {...data};
    let newobj:any={}
    let max = this.getMaxValue(data);
    for (let category in data) {
      if(dataChange[category]){
        newobj[category] = { category : dataChange[category], calculation : dataChange[category] ? Math.round(this.percentageCal(dataChange[category], max)) : 0 }
      }
    }
    return newobj;
  }

  percentageCal(category: any, max: number){
    let cal = (category * 100) / max
    if(cal > 0 && cal < 1){
      cal = 1;
    }
    return cal
  }

  rangeToNumberFor(value:string){
    if(value && value?.includes('-')){
      let minmax = value.split('-')
      let numrangeval = this.numFormat(minmax[0])+'-'+this.numFormat(minmax[1])
      return numrangeval;
    }
    return value; 
  }

  numFormat(num:any){
    if (!num) {
      return '';
    }
    if (isNaN(num)){
        return num;
    }
    return this.decimalPipe.transform(Number(num), '1.0-1')
  }

  getDataFormat(date:any){
    if(date) return date ? moment.utc(new Date(date)).local().format('DD MMM YYYY') : null;
    return '';
  }
  /**
   * 
   * @param selectedChartValue 
   * @param overviewChartData 
   * @param e 
   * @param value 
   * @param maxSelections 
   * @returns 
   * Common function if select dropdown
   */
  selectChart(
    selectedChartValue: string[],
    overviewChartData: any,
    e: any, 
    value: string,
    maxSelections: number = 2
  ): any {
    let index = selectedChartValue.indexOf(value);

    if (!e.checked) {
      if (selectedChartValue.length === 1) {
        this.alertService.warn(this.translate.instant('atLeastOneMust'));
        e.source.checked = true;
      } else {
        selectedChartValue.splice(index, 1);
      }
    } else {
      if (selectedChartValue.length > maxSelections) {
        this.alertService.warn(this.translate.instant('youCannotSelectMoreThan'));
        e.source.checked = false;
        return false;
      } else {
        selectedChartValue.push(value)
      }
    }
    // Return the filtered chart report data
    return Object.keys(overviewChartData)
      .filter(key => selectedChartValue.includes(key))
      .reduce((obj: any, key: any) => {
        obj[key] = overviewChartData[key];
        return obj;
      }, {});
  }
  // Define the showMoreText logic here
  showMoreText(selectedChartValue: any[], textContent: string | null): string {
    let firstEleCount: number = textContent?.split(',')[0].length || 0;

    if (selectedChartValue.length == 2 && firstEleCount > 4) {
      return `(1) ${this.translate.instant('reports.more')}`;
    } else if (selectedChartValue.length == 3 && firstEleCount > 4) {
      return `(2) ${this.translate.instant('reports.more')}`;
    } else if (selectedChartValue.length == 2 && firstEleCount < 4) {
      return '';
    } else {
      return `(1) ${this.translate.instant('reports.more')}`;
    }
  }
  //Filter Subscription
  filterSubscription(){
    //Add Lock
    let reportSubscription = this.userSubscription.find((x: any) => (x.group_id == this.queryParams?.gid) && (x.report_id == this.queryParams?.rid));
    this.reportFilter.map((reportFilter: any) => {
      // Find the matching filter from userSubscription based on parameter_name or col_name
      const matchingUserFilter = reportSubscription?.filter?.find((userFilter: any) => userFilter.parameter_name === reportFilter.parameter_name);
      // If a match is found
      if (matchingUserFilter) {
        //Set Defult Value
        if (matchingUserFilter?.default) {
          reportFilter['default'] = matchingUserFilter?.default;
        }
        reportFilter['dynamic_value'] = matchingUserFilter?.dynamic_value;
        reportFilter['required'] = matchingUserFilter?.required;
        reportFilter['map_filter'] = matchingUserFilter?.map_filter;
        
        // if (matchingUserFilter?.all_list) {
        //   reportFilter['all_list'] = matchingUserFilter?.all_list;
        // }
        // Iterate over the value_list of allFilter and match with the values in userSubscription
        if(reportFilter?.value_list?.length){
          reportFilter?.value_list.forEach((valueListItem: any) => {
            if(Array.isArray(matchingUserFilter?.value)){
              const isMatch = matchingUserFilter?.value.some(
                (userFilterValue: any) => userFilterValue.val.toLowerCase() === valueListItem.val.toLowerCase()
              );
              // If a match is found, lock the corresponding object
              if (isMatch) {
                valueListItem['isSubscription'] = true;
              } else {
                valueListItem['isSubscription'] = false; // or leave it undefined if not required
              }
            }
          })
        }
        if(reportFilter?.value_list?.length){
          reportFilter['isSubscription'] = reportFilter?.value_list.every((x: any) => x.isSubscription);
        }
        if (matchingUserFilter?.type == 'range') reportFilter['isSubscription'] = true;
        if (matchingUserFilter?.type === 'date_range') reportFilter['isSubscription'] = true;
      }
    })
    //update the filter by subscripetion
    this.currentReport['filter']=this.reportFilter;
    return this.reportFilter;
  }
}
