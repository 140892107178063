<div class="transaction-property-detail">
    <app-child-breadcum [property_uid]="propertyInfo?.property_uid" (back)="backToTransaction()"></app-child-breadcum>
    <div class="property-details">

        <div class="property-info">
            <h4>{{ propertyDetail?.property_name}}</h4>
            <p><strong>{{'reports.address' | translate}}:</strong> {{ propertyDetail?.address }}</p>
            <p><strong>{{'reports.developer' | translate}}:</strong> {{ propertyDetail?.developer_name }}</p>

            <p><strong>{{'reports.year_of_build' | translate}}:</strong> {{ propertyDetail?.year_of_build }}</p>


            <div class="property-zone" *ngIf="propertyDetail?.zoning">
                <h4>{{'reports.property_type_zoning' | translate}}</h4>
                <div class="zoning-info">
                    <span>{{ "reports.zoning" | translate }} : <strong>{{ propertyDetail?.zoning }}</strong></span>
                    <span *ngIf="propertyDetail?.property_type"><strong>{{ propertyDetail?.property_type }}</strong></span>
                </div>

            </div>

            <div class="property-information">
                <h4>{{'reports.property_information' | translate}}</h4>

                <div class="property-details-d">
                    <div class="property-area">
                        <h6>{{'reports.land_area' | translate}}</h6>
                        <span [matTooltip]="propertyDetail?.land_area">{{ propertyDetail?.land_area ? propertyDetail?.land_area : 'N/A'
                            }}</span>
                        <h6 *ngIf="propertyDetail?.land_area">{{'sqm_unit' | translate}}</h6>
                    </div>
                    <div class="property-area frontage">
                        <h6>{{ "reports.setback" | translate }}</h6>
                        <span [matTooltip]="propertyDetail?.setback">
                          {{ propertyDetail?.setback ? (propertyDetail?.setback | number: '1.0-1') : 'N/A' }}</span>
                        <h6 *ngIf="propertyDetail?.setback">m</h6>
                    </div>
                    <div class="property-area setback">
                      <h6>{{ "reports.corners" | translate }}</h6>
                      <span [ngClass]="{'nocorner-txt': !propertyDetail?.corner}">{{ propertyDetail?.corner ? propertyDetail?.corner : ('reports.no_corner' | translate) }}</span>

                        
                    </div>
                    <div class="property-area corner">
                        <h6>{{'reports.buildUp' | translate}}</h6>
                        <span>{{ propertyDetail?.builtup ? (propertyDetail?.builtup) : 'N/A' }}</span>
                        <h6 *ngIf="propertyDetail?.builtup">{{'sqm_unit' | translate}}</h6>
                    </div>
                </div>
            </div>


            <!-- <div class="property-zone property-builtup">
                <h4>{{'reports.builtup_information' | translate}}</h4>
                <div class="zoning-info builtup-info">
                    <span>{{'reports.builtup_area' | translate}}: <strong>{{propertyDetail?.total_buildup_area ?
                          propertyDetail?.total_buildup_area + (' reports.sqm_unit' | translate): 'N/A'}}</strong></span>
                    <span>{{'reports.year_of_build' | translate}}:<strong>{{propertyDetail?.build_year || 'N/A'}}</strong></span>
                    <span *ngIf="propertyDetail?.floors"><strong>{{propertyDetail?.floors }} {{'reports.floor' | translate}}</strong></span>
                    <span>{{'reports.finish' | translate}}: <strong>{{propertyDetail?.furnishing_status || 'N/A'}}</strong></span>
                    <span *ngIf="propertyDetail?.bed_rooms"><strong>{{propertyDetail?.bed_rooms}}</strong></span>
                    <span *ngIf="propertyDetail?.balconies"><strong>{{propertyDetail?.balconies}}</strong></span>
                    <span *ngIf="propertyDetail?.parking"><strong>{{propertyDetail?.parking}} {{'reports.parking' | translate}}</strong></span>
                </div>
            </div> -->
            <!-- <button class="btn-primary btn-housevilla-plan" mat-button><img src="assets/images/pdf.svg" alt=""> Floor
                Plans</button> -->
        </div>

       
    </div>

    <div class="report-tabsection">
            <div class="report-overview" *ngIf="!overViewPropertyLoading && overViewPropertySummary">
                
                <app-overview-card
                    [card_title]="'reports.totalResidentialUnits'"
                    [untillDate]="overViewPropertySummary.untillDate"
                    [total_prev]="overViewPropertySummary.prev_residential_units"
                    [total_current]="overViewPropertySummary.residential_units"
                    [total_percentage]="overViewPropertySummary.totalresidential"
                ></app-overview-card>

                <app-overview-card
                    [card_title]="'reports.totalCommercialUnits'"
                    [untillDate]="overViewPropertySummary.untillDate"
                    [total_prev]="overViewPropertySummary.prev_commercial_units"
                    [total_current]="overViewPropertySummary.commercial_units"
                    [total_percentage]="overViewPropertySummary.totalcommercial"
                ></app-overview-card>

                <app-overview-card
                    [card_title]="'reports.totalParkingLots'"
                    [total_current]="overViewPropertySummary.parking_lots"
                ></app-overview-card>

            </div>
        </div>


    <div class="report-model">

        <div class="report-model-header">
            <div class="info">
                <h3>{{'reports.units' | translate}}</h3>
                <span class="totalTransaction">{{ unitCount }}</span>
            </div>

            <!-- <search-bar></search-bar> -->
        </div>
        <app-report-datatable 
            [showLoader]="!unitLoading" 
            [tableColumns]="unitPropertyColumns" 
            [dataLength]="unitCount"
            [tableData]="unitData" 
            [allowExcelDownload]="true"
            (pageEvent)="onPageEvent($event, inventoryMenuEnum.unit)" 
            (sort)="sortData($event, inventoryMenuEnum.unit)"
            (excelDownload)="downloadExcel($event, 'ir_inventory_unit')">
        </app-report-datatable>
    </div>

    <div class="report-model">

        <div class="report-model-header">
            <div class="info">
                <h3>{{'reports.unitTransactionsPrices' | translate}}</h3>
                <span class="totalTransaction">{{ unitTransactionCount }}</span>
            </div>

            <!-- <search-bar></search-bar> -->
        </div>
        <app-report-datatable 
            [showLoader]="!unitTransactionLoading" 
            [tableColumns]="unitTransactionPropertyColumns" 
            [dataLength]="unitTransactionCount"
            [tableData]="unitTransactionData" 
            [allowExcelDownload]="true"
            (pageEvent)="onPageEvent($event, inventoryMenuEnum.unitTransaction)" 
            (sort)="sortData($event, inventoryMenuEnum.unitTransaction)"
            (excelDownload)="downloadExcel($event, 'ir_inventory_unit_transaction')">
        </app-report-datatable>
    </div>

</div>
    

 <!-- <app-report-property-detail></app-report-property-detail> -->