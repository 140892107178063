import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { SharedService } from '../../services/shared.service';
import { ReportService } from '../../../reports/services/report.service';
import { ReportsViewEnum } from '../../../reports/models/enums/reports.enum';
import { TranslateService } from '@ngx-translate/core';
import { GlobalContants } from '../../constants/global.contants';
import { StoreService } from '../../services/store.service';
import { ReportMapService } from 'src/app/reports/services/report-map.service';

@Component({
  selector: 'app-city-model',
  templateUrl: './city-model.component.html',
  styleUrls: ['./city-model.component.css']
})
export class CityModelComponent implements OnInit, OnDestroy {

  cityList: any | null = null;
  citySub$ = new Subscription();

  constructor(
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<CityModelComponent>,
    private reportService: ReportService,
    public translate: TranslateService,
    private storeService: StoreService,
    private mapService: ReportMapService
  ) { }

  ngOnInit(): void {
    this.getCity();
    this.getCitylist();
  }

  getCity() {
    this.sharedService.getCity()
  }

  getCitylist() {
    this.citySub$ = this.sharedService.cityList$.subscribe((res: any | null) => {
      if (res && res.data) {
        this.cityList = res.data;
      }
    })
  }

  citySelect(city: any) {
    this.storeService.clear(GlobalContants.StoreKey.latestReportRoute)
    this.sharedService.setSelectedCity(city);
    this.mapService.dashboardMapCoordinates.next([city.latitude, city.longitude]);
    this.reportService.reportView$.next(ReportsViewEnum.mapView);
    this.dialogRef.close({ selectedCity: city })
    // this.sharedService.getNeighDetails(false, false).subscribe({
    //   next: (res: any) => {
    //     if (res && res?.status === 200) {
    //       this.sharedService.setStoreValue(GlobalContants.StoreKey.neighList, res?.data);
    //     } else {
    //       this.sharedService.setStoreValue(GlobalContants.StoreKey.neighList, null);
    //     }
    //   }
    // })
  }

  ngOnDestroy(): void {
    this.citySub$?.unsubscribe();
  }

}
