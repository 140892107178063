<div class="report-container warehouse-inventory">


    <app-report-sub-menu [menuList]="overViewMenu" (menuChangeValue)="menuChangeValue($event)"></app-report-sub-menu>
    <ng-container *ngIf="overViewLoading">
        <app-skeleton-loader [reportType]="chartView.overView"></app-skeleton-loader>
    </ng-container>
    <div class="report-tabsection" id="{{ warehouseInventoryMenuEnum.overview}}"
        [ngClass]="{ 'scroll': currentScroll === warehouseInventoryMenuEnum.overview}">
        <div class="report-overview" *ngIf="!overViewLoading">
            <app-overview-card
                [card_title]="'reports.totalBuiltUparea'"
                [total_current]="overViewSummary?.total_builtup_area.total_builtup_area" 
                [untillDate]="overViewSummary?.untillDate"
                [total_prev]="overViewSummary?.total_builtup_area.prev_total_builtup_area"
                [total_percentage]="overViewSummary?.totalBuiltupArea" 
                [uniType]="UnitTypeEnum.sqm"
                [graphData]="{data: overviewAverageRate, type: 'averageRentRate', loader: graphLoader}">
            </app-overview-card>

            <app-overview-card 
                [card_title]="'reports.totalWarehouses'"
                [total_current]="overViewSummary?.occupacy_rate.total_warehouses"
                [untillDate]="overViewSummary?.untillDate"
                [total_prev]="overViewSummary?.occupacy_rate.prev_total_warehouses"
                [total_percentage]="overViewSummary?.totalOccupacy_rate"
                [graphData]="{data: overviewOccupancy, type: 'occupancyRate', loader: graphLoader}">
            </app-overview-card>
            
            <app-overview-card 
                [card_title]="'reports.land_area_covered'"
                [total_current]="overViewSummary?.occupacy_rate.total_land_covered"
                [untillDate]="overViewSummary?.untillDate"
                [total_prev]="overViewSummary?.occupacy_rate.prev_total_land_covered"
                [total_percentage]="overViewSummary?.totalLandCovered" 
                [uniType]="UnitTypeEnum.sqm"
                [graphData]="{data: overviewOccupancy, type: 'occupancyRate', loader: graphLoader}">
        </app-overview-card>    



        </div>
        <div class="report-model report-rentrate" *ngIf="retailRentalRentrateReport">
            <div class="report-model-header">
                <div class="info">
                    <h3>{{'reports.grossBuiltupWarehouseSpace' | translate}}  ({{'reports.sqm_unit' | translate}})
                    </h3>
                </div>

                <!-- <button class="btn-select-grade" [matMenuTriggerFor]="menu"
                    *ngIf="!retailRentalChartLoading && (retailRentalChartReport | json) != '{}'">
                    <div class="setext">
                        <ng-container *ngFor="let name of selectedChartValue; let l = last">{{name}}<span *ngIf="!l"
                                style="padding-right: 2px;">,</span></ng-container>
                    </div>
                    <span class="morecount" *ngIf="selectedChartValue.length > 1">{{moreText}}</span>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>

                <mat-menu #menu="matMenu" [xPosition]="langName === 'ar' ? 'after' : 'before'"
                    class="grades-menu directionLtr">
                    <div (click)="$event.stopPropagation()">
                        <h5>{{'reports.choose_maximum_options' | translate}}</h5>
                        <ng-container *ngFor="let category of getReportKeys()">
                            <mat-checkbox (change)="selectChart($event, category)"
                                [checked]="selectedChartValue.includes(category)">{{category}}</mat-checkbox>
                        </ng-container>
                    </div>
                </mat-menu> -->
            </div>
            <!-- <ng-container *ngIf="retailRentalChartLoading">
                <app-skeleton-loader [reportType]="chartView.barChart"></app-skeleton-loader>
            </ng-container>
            <ng-container *ngIf="!retailRentalChartLoading">
                <div class="multiBarChart d-flex" *ngIf="(retailRentalChartReport | json) != '{}'; else noDataMessage">
                    <ng-container *ngFor="let chart of retailRentalChartReport | keyvalue; let i = index">
                        <app-report-charts class="grchart" [data]="chart.value" [chartType]="chartView.smallBarChart"
                            [isBorder]="false" [name]="chart.key" [index]="i"
                            [ngStyle]="{'min-width': (retailRentalChartReport | keyvalue).length == 1 ? '100%' : '220px'}">
                        </app-report-charts>
                    </ng-container>


                </div>
                <ng-template #noDataMessage>
                    <div class="data_unavail">
                        <span>
                            {{"reports.data_not_available" | translate}} <mat-icon role="img"
                                class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color"
                                aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon>
                        </span>
                    </div>
                </ng-template>
            </ng-container> -->
        </div>

        <div class="report-model report-rentrate" *ngIf="retailRentalRentrateReport">
            <div class="report-model-header">
                <div class="info">
                    <h3>{{'reports.grossLandCoverageArea' | translate}}  ({{'reports.sqm_unit' | translate}})
                    </h3>
                </div>

                <!-- <button class="btn-select-grade" [matMenuTriggerFor]="menu"
                    *ngIf="!retailRentalChartLoading && (retailRentalChartReport | json) != '{}'">
                    <div class="setext">
                        <ng-container *ngFor="let name of selectedChartValue; let l = last">{{name}}<span *ngIf="!l"
                                style="padding-right: 2px;">,</span></ng-container>
                    </div>
                    <span class="morecount" *ngIf="selectedChartValue.length > 1">{{moreText}}</span>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>

                <mat-menu #menu="matMenu" [xPosition]="langName === 'ar' ? 'after' : 'before'"
                    class="grades-menu directionLtr">
                    <div (click)="$event.stopPropagation()">
                        <h5>{{'reports.choose_maximum_options' | translate}}</h5>
                        <ng-container *ngFor="let category of getReportKeys()">
                            <mat-checkbox (change)="selectChart($event, category)"
                                [checked]="selectedChartValue.includes(category)">{{category}}</mat-checkbox>
                        </ng-container>
                    </div>
                </mat-menu> -->
            </div>
            <!-- <ng-container *ngIf="retailRentalChartLoading">
                <app-skeleton-loader [reportType]="chartView.barChart"></app-skeleton-loader>
            </ng-container>
            <ng-container *ngIf="!retailRentalChartLoading">
                <div class="multiBarChart d-flex" *ngIf="(retailRentalChartReport | json) != '{}'; else noDataMessage">
                    <ng-container *ngFor="let chart of retailRentalChartReport | keyvalue; let i = index">
                        <app-report-charts class="grchart" [data]="chart.value" [chartType]="chartView.smallBarChart"
                            [isBorder]="false" [name]="chart.key" [index]="i"
                            [ngStyle]="{'min-width': (retailRentalChartReport | keyvalue).length == 1 ? '100%' : '220px'}">
                        </app-report-charts>
                    </ng-container>


                </div>
                <ng-template #noDataMessage>
                    <div class="data_unavail">
                        <span>
                            {{"reports.data_not_available" | translate}} <mat-icon role="img"
                                class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color"
                                aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon>
                        </span>
                    </div>
                </ng-template>
            </ng-container> -->
        </div>
    </div>

    <!-- neighborhood -->
    <div class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === warehouseInventoryMenuEnum.neighborhood}"
        id="{{ warehouseInventoryMenuEnum.neighborhood }}">

        <app-report-year-dropdown [reportType]="'neighborhood'"
            (selectedYear)="selectedYear($event)"></app-report-year-dropdown>


        <div class="report-model">

            <div class="report-model-header">
                <div class="info">
                    <h3>{{"reports.neighborhoods" | translate}}</h3>
                    <span class="totalTransaction"> ({{neighbourhoodCount ? reportService.formatInternationNum(neighbourhoodCount) : neighbourhoodCount}})</span>
                </div>

            </div>
          
            <app-report-datatable class="rrr-rentunitsize-table" 
                [showLoader]="!neighbourhoodLoading"
                [tableColumns]="neighborhoodColumns" 
                [dataLength]="neighbourhoodCount"
                [tableData]="neighbourhooddata" 
                (pageEvent)="onPageEvent($event, warehouseInventoryMenuEnum.neighborhood)"
                (sort)="sortData($event, warehouseInventoryMenuEnum.neighborhood)" 
                [allowExcelDownload]="true"
                (excelDownload)="downloadExcel($event, 'retail_rental_unit_size', 'a')"
                [downloadLoading]="downloadLoading['a']" 
                [expandColumn]="true"
                [tableExpandColumns]="neighbourhoodExpandColumns" 
                [showTooltip]="true"
                >
            </app-report-datatable>



        </div>
    </div>

    <!-- Properties -->
    <div class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === warehouseInventoryMenuEnum.properties}"
        id="{{ warehouseInventoryMenuEnum.properties }}">

        <app-report-year-dropdown [reportType]="'properties'"
            (selectedYear)="selectedYear($event)"></app-report-year-dropdown>


        <div class="report-model">

            <div class="report-model-header">
                <div class="info">
                    <h3>{{'reports.projectsProperties' | translate}}</h3>
                    <span class="totalTransaction">({{propertiesCount ?
                        reportService.formatInternationNum(propertiesCount) : propertiesCount}})</span>
                </div>

            </div>
           
            <app-report-datatable 
                [showLoader]="!propertiesLoading" 
                [tableColumns]="propertiesColumns"
                [dataLength]="propertiesCount" 
                [tableData]="propertiesData"
                (pageEvent)="onPageEvent($event, warehouseInventoryMenuEnum.properties)"
                (sort)="sortData($event, warehouseInventoryMenuEnum.properties)" 
                [allowExcelDownload]="true"
                (excelDownload)="downloadExcel($event, 'retail_rental_unit', 'b')"
                [downloadLoading]="downloadLoading['b']" 
                >
            </app-report-datatable>


        </div>
    </div>



</div>