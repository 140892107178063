import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { IconPath } from '../constants/userDefineIcon.constant'
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class MatRegisterIconService {

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: any,
    ) {
      if(isPlatformBrowser(this.platformId)){
        this.iconRegistry.addSvgIcon(
          'male',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.male
          )
        );
        this.iconRegistry.addSvgIcon(
          'female',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.female
          )
        );

        this.iconRegistry.addSvgIcon(
          'fileIcon',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.fileIcon
          )
        );

        this.iconRegistry.addSvgIcon(
          'deleteIcon',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.deleteIcon
          )
        );

        this.iconRegistry.addSvgIcon(
          'downloadIcon',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.downloadIcon
          )
        );

        this.iconRegistry.addSvgIcon(
          'filterIcon',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.filterIcon
          )
        );

        this.iconRegistry.addSvgIcon(
          'layoutIcon',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.layoutIcon
          )
        );

        this.iconRegistry.addSvgIcon(
          'removeFolderIcon',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.removeFolderIcon
          )
        );

        this.iconRegistry.addSvgIcon(
          'shareIcon',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.shareIcon
          )
        );

        this.iconRegistry.addSvgIcon(
          'sortIcon',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.sortIcon
          )
        );
        this.iconRegistry.addSvgIcon(
          'saveIcon',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.saveIcon
          )
        );
        this.iconRegistry.addSvgIcon(
          'searchIcon',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.searchIcon
          )
        );

        this.iconRegistry.addSvgIcon(
          'reportFilterIcon',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.reportFilterIcon
          )
        );
        this.iconRegistry.addSvgIcon(
          'layerIcon',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.layerIcon
          )
        );
        this.iconRegistry.addSvgIcon(
          'vector',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.vector
          )
        );

        this.iconRegistry.addSvgIcon(
          'security',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.security
          )
        );

        this.iconRegistry.addSvgIcon(
          'group',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.group
          )
        );

        this.iconRegistry.addSvgIcon(
          'creditCard',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.creditCard
          )
        );

        this.iconRegistry.addSvgIcon(
          'rename',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.rename
          )
        );

        this.iconRegistry.addSvgIcon(
          'verticalEllipsis',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.verticalEllipsis
          )
        );

        this.iconRegistry.addSvgIcon(
          'duplicateIcon',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.duplicateIcon
          )
        );

        this.iconRegistry.addSvgIcon(
          'arrowDown',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.arrowDown
          )
        );
        this.iconRegistry.addSvgIcon(
          'bookmark',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.bookmark
          )
        );
        this.iconRegistry.addSvgIcon(
          'bookmarkWhite',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.bookmarkWhite
          )
        );
        this.iconRegistry.addSvgIcon(
          'restartIcon',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.restartIcon
          )
        );
        this.iconRegistry.addSvgIcon(
          'markets',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.markets
          )
        );
        this.iconRegistry.addSvgIcon(
          'minmenu',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.minmenu
          )
        );
        // this.iconRegistry.addSvgIcon(
        //   'dashboard',
        //   this.sanitizer.bypassSecurityTrustResourceUrl(
        //     IconPath.dashboard
        //   )
        // );
        this.iconRegistry.addSvgIcon(
          'description',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.description
          )
        );
        this.iconRegistry.addSvgIcon(
          'micMenu',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            IconPath.micMenu
          )
        );

      }
     }




}
