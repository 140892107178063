import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { InventoryReportConstant } from './report-investment-inventory.constants';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportInvestmentInventoryService } from './report-investment-inventory.service';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from 'src/app/reports/services/report.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { InventoryMenuEnum } from './report-investment-inventory.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-report-investment-inventory',
  templateUrl: './report-investment-inventory.component.html',
  styleUrls: ['./report-investment-inventory.component.scss']
})
export class ReportInvestmentInventoryComponent implements OnInit, OnChanges, OnDestroy {

  @Input() params: any;
  @Input() multi_story_residential: boolean = false;

  chartView = ReportsChartEnum;

  overViewMenu = InventoryReportConstant.overViewMenu;

  inventoryMenuEnum = InventoryMenuEnum;
  currentScroll: any = '';
  transactionPropertyDetails$ = new Subscription();
  isFirstTime = true;
  placeName='';
  userInfo: any = 0;
  placeCode: string = '';
  sqm_unit: string = '';
  CurrencyCode: string = '';

  overViewLoading: boolean = false;
  overViewSummary: any = null;

  propertiesByNeighborhoodData: any = [];
  propertiesByNeighborhoodCount: number = 0;
  propertiesByNeighborhoodLoading: boolean = false;

  projectsAndPropertiesReportdata: any = [];
  projectsAndPropertiesReportCount: number = 0;
  projectsAndPropertiesReportLoading: boolean = false;

  projectsReportdata: any = [];
  projectsReportCount: number = 0;
  projectsReportLoading: boolean = false;

  inventoryChartLoading: boolean = false;
  inventoryChart: any = [];

  propertiesByNeighborhoodColumns = InventoryReportConstant.propertiesByNeighborhoodColumns;
  propertiesByNeighborhoodExpandColumns = InventoryReportConstant.propertiesByNeighborhoodExpandColumns;
  projectsAndPropertiesColumns = InventoryReportConstant.projectsAndPropertiesColumns;
  projectColumns = InventoryReportConstant.projectColumns;

  downloadLoading: any = {
    a: false,
    b: false,
    c: false
  };
  showPropertyDetails: boolean = false;
  propertyInfo = null;

  constructor(
    private sharedService: SharedService,
    private reportInvestmentInventoryService: ReportInvestmentInventoryService,
    private alertService: AlertService,
    public translate: TranslateService,
    public reportService: ReportService
  ) {
    this.sharedService.language$.subscribe(lang => {
      if (lang && !this.isFirstTime) {
        this.getReports();
      }
    })
  }

  ngOnInit(): void {
    this.getReports();
    this.transactionPropertyDetails();
    this.isFirstTime = false;
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    this.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
    this.placeName =  this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceName);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['params'] && changes['params'].currentValue && !changes['params'].firstChange){
      this.reportInvestmentInventoryService.projectsAndPropertiesPayload.pageNumber = 0;
      this.reportInvestmentInventoryService.propertiesByNeighborhoodPayload.pageNumber = 0;
      this.reportInvestmentInventoryService.projectsPayload.pageNumber = 0;
      this.reportInvestmentInventoryService.projectsAndPropertiesPayload.year =null
      this.reportInvestmentInventoryService.propertiesByNeighborhoodPayload.year =null
      this.reportInvestmentInventoryService.projectsPayload.year =null

      this.propertyInfo = null;
      this.showPropertyDetails = false;
      this.getReports();
    }
  }


  getReports(){
    this.getOverviewSummary();
    this.getInventoryChart();
    this.getpropertiesByNeighborhood();
    this.getProjectsAndPropertiesReport();
    this.getProjectsReport();
  }

  transactionPropertyDetails(){
    this.transactionPropertyDetails$ = this.reportService.transactionPropertyDetails.subscribe((data) => {
      if(data){
        this.showPropertyDetails = true;
        this.propertyInfo = data;
      } else {
        this.showPropertyDetails = false;
        this.propertyInfo = null;
      }

    });
  }

  menuChangeValue(menu: any) {
    this.currentScroll = menu.name;
    setTimeout(() => {
      this.scrollPoint(menu.name);
    })
  }

  scrollPoint(menu: string) {
    this.sharedService.scrollMenu(menu);
  }

  getOverviewSummary() {
    this.overViewLoading = true;
    this.reportInvestmentInventoryService.getOverviewSummary().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.overViewSummary = this.reportInvestmentInventoryService.formatOverViewSummary(res.data[0]);
          } else {
            this.overViewSummary = null;
          }
          this.overViewLoading = false;
        },
        error: (error: any) => {
          this.overViewSummary = null;
          this.overViewLoading = false;
        }
      }
    )
  }

  getInventoryChart() {
    this.inventoryChartLoading = true;
    this.reportInvestmentInventoryService.getInventoryPropertyGraphReport().subscribe(
      {
        next: (res: any) => {
          this.inventoryChart = res;
          this.inventoryChartLoading = false;
        },
        error: (error: any) => {
          this.inventoryChart = [];
          this.inventoryChartLoading = false;
        }
      }
    )
  }

  getpropertiesByNeighborhood(){

    this.propertiesByNeighborhoodLoading = true;
    this.reportInvestmentInventoryService.getpropertiesByNeighborhood(this.reportInvestmentInventoryService.propertiesByNeighborhoodPayload).subscribe(
      {
        next: (res: any) => {
          this.propertiesByNeighborhoodData = res.data;
          if (this.reportInvestmentInventoryService.propertiesByNeighborhoodPayload.pageNumber == 0) {
            this.propertiesByNeighborhoodCount = res.count;
            this.overViewMenu.map((e:any)=>{
              if(e.name == InventoryMenuEnum.propertiesByNeighborhood){
                e['count'] = this.reportService.formatInternationNum(this.propertiesByNeighborhoodCount);
              }
            })
          }
          this.propertiesByNeighborhoodLoading = false;
        },
        error: (error: any) => {
          this.propertiesByNeighborhoodData = [];
          this.propertiesByNeighborhoodCount = 0;
          this.propertiesByNeighborhoodLoading = false;
        }

      }
    )

  }

  getProjectsAndPropertiesReport() {
    this.projectsAndPropertiesReportLoading = true;
    this.reportInvestmentInventoryService.getProjectsAndPropertiesReport(this.reportInvestmentInventoryService.projectsAndPropertiesPayload).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
           this.projectsAndPropertiesReportdata = res.data;
            if(this.reportInvestmentInventoryService.projectsAndPropertiesPayload.pageNumber == 0){
            this.projectsAndPropertiesReportCount = res.count;
            this.overViewMenu.map((e:any)=>{
              if(e.name == InventoryMenuEnum.projectsAndProperties){
                e['count'] = this.reportService.formatInternationNum(this.projectsAndPropertiesReportCount);
              }
            })
           }

          } else {
            this.projectsAndPropertiesReportdata = [];
            this.projectsAndPropertiesReportCount = 0;
            this.overViewMenu.map((e:any)=>{
              if(e.name == InventoryMenuEnum.projectsAndProperties){
                e['count'] = 0;
              }
            })
          }
          this.projectsAndPropertiesReportLoading = false;
        },
        error:()=>{
          this.projectsAndPropertiesReportLoading = false;
          this.projectsAndPropertiesReportdata = [];
          this.projectsAndPropertiesReportCount = 0;
          this.overViewMenu.map((e:any)=>{
            if(e.name == InventoryMenuEnum.projectsAndProperties){
              e['count'] = 0;
            }
          })
        }

      }
    )
  }

  getProjectsReport() {
    this.projectsReportLoading = true;
    this.reportInvestmentInventoryService.getProjectsReport(this.reportInvestmentInventoryService.projectsPayload).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
           this.projectsReportdata = res.data;
            if(this.reportInvestmentInventoryService.projectsPayload.pageNumber == 0){
            this.projectsReportCount = res.count;
            this.overViewMenu.map((e:any)=>{
              if(e.name == InventoryMenuEnum.project){
                e['count'] = this.reportService.formatInternationNum(this.projectsReportCount);
              }
            })
           }

          } else {
            this.projectsReportdata = [];
            this.projectsReportCount = 0;
            this.overViewMenu.map((e:any)=>{
              if(e.name == InventoryMenuEnum.project){
                e['count'] = 0;
              }
            })
          }
          this.projectsReportLoading = false;
        },
        error:()=>{
          this.projectsReportLoading = false;
          this.projectsReportdata = [];
          this.projectsReportCount = 0;
          this.overViewMenu.map((e:any)=>{
            if(e.name == InventoryMenuEnum.project){
              e['count'] = 0;
            }
          })
        }

      }
    )
  }

  downloadExcel(event: any, type: string, key: any) {
    this.downloadRentalReport(type, key);
  }

  downloadRentalReport(type: string, key: any) {
    this.downloadLoading[key] = true;
    this.reportInvestmentInventoryService.downloadInvestmentReport(type, this.userInfo?.user_id).subscribe(
      {
        next: (res: any) => {
          this.downloadLoading[key] = false;
          if (res.status === 200) {
            const downloadUrl = `${this.sharedService.downloadExcelPath()}?file_name=${res.data}&city_code=${this.placeCode.toLowerCase()}&module_name=investment_residential`;
            this.sharedService.downloadExcelUrl(downloadUrl);
            this.alertService.success(this.translate.instant('reports.downloadRequestSubmitted'));

          } else {
            this.alertService.warn(res.message)
          }
        },
        error: (error: any) => {
          this.downloadLoading[key] = false;
        }
      }
    )
  }

  onPageEvent(e: any, report: any) {
    if (e.previousPageIndex !== e.pageIndex) {
      if (report === this.inventoryMenuEnum.propertiesByNeighborhood) {
        this.reportInvestmentInventoryService.propertiesByNeighborhoodPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getpropertiesByNeighborhood();
      } else if (report === this.inventoryMenuEnum.projectsAndProperties)  {
        this.reportInvestmentInventoryService.projectsAndPropertiesPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getProjectsAndPropertiesReport();
      } else {
        this.reportInvestmentInventoryService.projectsPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getProjectsReport();
      }
    }

  }

  sortData(event: any, report: any) {
    let sort = {
      column: event.active,
      order: event.direction
    }
    if (report === this.inventoryMenuEnum.propertiesByNeighborhood) {
      this.reportInvestmentInventoryService.propertiesByNeighborhoodPayload.pageNumber = 0;
      this.reportInvestmentInventoryService.propertiesByNeighborhoodPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getpropertiesByNeighborhood();
    }   else if (report === this.inventoryMenuEnum.projectsAndProperties)  {
      this.reportInvestmentInventoryService.projectsAndPropertiesPayload.pageNumber = 0;
        this.reportInvestmentInventoryService.projectsAndPropertiesPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
        this.getProjectsAndPropertiesReport();
  } else {
        this.reportInvestmentInventoryService.projectsPayload.pageNumber = 0;
        this.reportInvestmentInventoryService.projectsPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
        this.getProjectsReport();
    }
  }

  selectedYear(data: any){
    if (data.reportType === 'project') {
      this.reportInvestmentInventoryService.projectsPayload.pageNumber = 0;
      this.reportInvestmentInventoryService.projectsPayload.year = data.year;
      this.getProjectsReport();
    }else if(data.reportType === 'propertiesByNeighborhood'){
      this.reportInvestmentInventoryService.propertiesByNeighborhoodPayload.pageNumber = 0;
      this.reportInvestmentInventoryService.propertiesByNeighborhoodPayload.year = data.year;
      this.getpropertiesByNeighborhood();
    }else{
      this.reportInvestmentInventoryService.projectsAndPropertiesPayload.pageNumber = 0;
      this.reportInvestmentInventoryService.projectsAndPropertiesPayload.year = data.year;
      this.getProjectsAndPropertiesReport();
    }
  }

  ngOnDestroy(): void {
    this.transactionPropertyDetails$.unsubscribe()
  }

}
