<div class="transaction-property-detail">
    <app-child-breadcum [property_uid]="propertyInfo?.property_uid" (back)="backToTransaction()"></app-child-breadcum>
    <ng-container *ngIf="allDataLoading">
        <app-skeleton-loader [reportType]="chartView.overView" [loaderSize]="1"></app-skeleton-loader>
    </ng-container>
    <div class="property-details" *ngIf="propertyDetail">

        <div class="property-info">
            <h4>{{ propertyDetail?.property_name}}</h4>
            <p><strong>Address:</strong> {{ propertyDetail?.address }}</p>

            <div class="property-zone" *ngIf="propertyDetail?.zoning">
                <h4>Property Type & Zoning</h4>
                <div class="zoning-info">
                    <span>{{ "reports.zoning" | translate }} : <strong>{{ propertyDetail?.zoning }}</strong></span>
                    <span *ngIf="propertyDetail?.property_type"><strong>{{ propertyDetail?.property_type
                            }}</strong></span>
                </div>

            </div>

            <div class="property-information">
                <h4>Property Information</h4>

                <div class="property-details-d">
                    <div class="property-area">
                        <h6>Area</h6>
                        <span [matTooltip]="propertyDetail?.area">{{ propertyDetail?.area ? (propertyDetail?.area | number: '1.0-1') : 'N/A'
                            }}</span>
                        <h6 *ngIf="propertyDetail?.area">{{sqm_unit | translate}}</h6>
                    </div>
                    <div class="property-area frontage">
                        <h6> Frontage</h6>
                        <span [matTooltip]="propertyDetail?.frontAge">{{ propertyDetail?.frontAge ?
                            (propertyDetail?.frontAge | number: '1.0-1') : 'N/A' }}</span>
                        <h6 *ngIf="propertyDetail?.frontAge">m</h6>
                    </div>
                    <div class="property-area setback">
                      <h6>{{ "reports.setback" | translate }}</h6>
                        <span [matTooltip]="propertyDetail?.setback">{{ propertyDetail?.setback ?
                            (propertyDetail?.setback | number: '1.0-1') : 'N/A' }}</span>
                        <h6 *ngIf="propertyDetail?.setback">{{sqm_unit | translate}}</h6>
                    </div>
                    <div class="property-area corner">
                        <h6>Corners</h6>
                        <span [ngClass]="{'nocorner-txt': !propertyDetail?.corner}">{{ propertyDetail?.corner ? propertyDetail?.corner : ('reports.no_corner' | translate) }}</span>

                    </div>
                </div>
            </div>


            <div class="property-zone property-builtup">
                <h4>{{'reports.builtup_information' | translate}}</h4>
                <div class="zoning-info builtup-info">
                    <span>Built-up Area: <strong>{{propertyDetail?.total_buildup_area ?
                            propertyDetail?.total_buildup_area + (sqm_unit | translate): 'N/A'}}</strong></span>
                    <!-- <span>Year of build:<strong>{{propertyDetail?.build_year || 'N/A'}}</strong></span> -->
                    <span *ngIf="propertyDetail?.floors"><strong>{{propertyDetail?.floors }} floor</strong></span>
                    <span>Finish: <strong>{{propertyDetail?.furnishing_status || 'N/A'}}</strong></span>
                    <span *ngIf="propertyDetail?.bed_rooms"><strong>{{propertyDetail?.bed_rooms}}</strong></span>
                    <span *ngIf="propertyDetail?.balconies"><strong>{{propertyDetail?.balconies}}</strong></span>
                    <span *ngIf="propertyDetail?.parking"><strong>{{propertyDetail?.parking}} parking</strong></span>
                </div>
            </div>
            <!-- <button class="btn-primary btn-housevilla-plan" mat-button><img src="assets/images/pdf.svg" alt=""> Floor
                Plans</button> -->
        </div>

        <div class="property-info-img">
            <div class="property-imgD">
                <img src="assets/images/housevilla.png">
            </div>
        </div>
    </div>
</div>
