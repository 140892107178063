<div class="card-container">
  <div class="card">
    <div class="card-header d-flex">
      <div class="checkbox d-flex">
        <input *ngIf="isCheckbox" class="checkboxInput" type="checkbox"
          [checked]="sharedService.savedFilter.indexOf(saveFilter.id) != -1" (click)='selectFilter(saveFilter.id)' />
        <span class="title" [matTooltip]="saveFilter.name">{{saveFilter.name}}</span>
      </div>
      <div class="date">{{(saveFilter.updated_date? saveFilter.updated_date : saveFilter.added_date) | date: 'dd/MM/yyyy'}}</div>
    </div>
    <div class="card-body">
      <ul class="filters">
        <!-- <li class="filter" *ngIf="saveFilter?.data?.catchment">
          <span class="span-font title">Catchment area:</span>
          <span class=" span-font content"> Sabah Al-Salem, Salwa, Bayan, Mishref </span>
        </li>-->
        <ng-container *ngFor="let entrie of saveFilter?.data?.filter_label; let i = index">
          <li class="filter" *ngIf="i < 8">
            <span class="span-font title">{{getLabel(entrie?.parameter_name)}} : </span>
            <ng-container *ngFor="let value of entrie?.data; let l = last">
              <span class="span-font content">{{getLabel(value?.label)}}<ng-container *ngIf="!l">,
                </ng-container></span>
            </ng-container>
          </li>
        </ng-container>
        <!-- <ng-container *ngFor="let entrie of entries" >
          <li class="filter" *ngIf="entrie[0] !== 'zoom' && entrie[0] !== 'centroid'">
            <span class="span-font title">{{getFilterLabel(entrie[0])}} : </span>
            <span class="span-font content">{{ getValues(entrie[1]) }} </span>
          </li>
        </ng-container> -->
      </ul>
    </div>
    <div class="card-footer">
      <ul class="buttons d-flex">
        <li *ngIf="isViewAll"><button class="btn-link all-filters"
            (click)="viewAllFilter(saveFilter)">{{"reports.ViewAllFilters" | translate}} ({{entries.length -
            2}})</button></li>
        <li *ngIf="isGenReport"><button class="btn-link all-filters"
            (click)="generateReport(saveFilter)">{{"reports.StartaReport" | translate}}</button></li>
        <li *ngIf="isDelete"><button class="btn-link delete"
            (click)="deleteSavedFilter(saveFilter)">{{"reports.deleteTitle" | translate}}</button></li>
      </ul>
    </div>
  </div>
</div>
