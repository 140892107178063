<div>
    <div class="login-right-section">
        <div class="login-right-inner">
            <div class="title">{{'User.contact_us' | translate}}</div>
            <div class="form">
                <form  [formGroup]="contactUsForm" autocomplete="off" (ngSubmit)="saveChanges(contactUsForm.value)" >
                    <div class="form-group mt-3">
                        <div class="input-field">
                            <div class="">
                                <label for="">{{"User.city" | translate}} <span> *</span></label>
                                <mat-select [class.error] = "City.invalid && (City.dirty || City.touched)" class="form-control" formControlName="city">
                                    <mat-option *ngFor="let city of city_list" [value]="city.place_id">
                                        {{city.place_name}}
                                    </mat-option>
                                </mat-select>
                                <!-- <input type="text" class="form-control" formControlName="company_name" autocomplete="address-level1"> -->
                                <mat-error class="" *ngIf="City.invalid && (City.dirty || City.touched)">
                                    <span class="text-center" *ngIf="City.errors?.['required'] && City?.errors != null">
                                        {{'User.city_required' | translate}}</span>
                                </mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <div class="input-field">
                            <div class="">
                                <label for="">{{"User.full_name" | translate}} <span> *</span></label>
                                <input [class.error] ="FullName.invalid && (FullName.dirty || FullName.touched)" type="text" class="form-control" formControlName="full_name" autocomplete="nickname">
                                <mat-error class="" *ngIf="FullName.invalid && (FullName.dirty || FullName.touched)">
                                    <span *ngIf="!FullName.errors?.['required'] && FullName.errors?.['pattern'] && FullName?.errors != null">
                                        {{'User.valid_name' | translate}}</span>
                                    <span class="text-center" *ngIf="FullName.errors?.['required'] && !FullName.errors?.['pattern'] && FullName?.errors != null">
                                        {{'User.full_name_required' | translate}}</span>
                                    <span class="text-center" *ngIf="FullName.errors?.['maxlength'] && !FullName.errors?.['pattern'] && FullName?.errors != null">
                                        {{'User.full_name_maxlen' | translate}}</span>
                                    <span class="text-center" *ngIf="FullName.errors?.['minlength'] && !FullName.errors?.['pattern'] && FullName?.errors != null">
                                        {{'User.full_name_minlen' | translate}}</span>
                                </mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <label for="">{{"User.Email" | translate}} <span> *</span></label>
                        <input [class.error] = "Email.invalid && (Email.dirty || Email.touched)" type="email" class="form-control" formControlName="email" autocomplete="address-level1">
                        <mat-error class="" *ngIf="Email.invalid && (Email.dirty || Email.touched)">
                            <!-- <span *ngIf="!Email.errors?.['required'] && !Email.errors?.['pattern'] && Email?.errors != null">{{'User.valid_email' | translate}}</span> -->
                            <span *ngIf="(!Email.errors?.['required'] && Email.errors?.['pattern'] && Email?.errors != null && !Email.errors?.['minlength'])">{{'User.valid_email_address' | translate}}</span>
                            <span class="text-center" *ngIf="Email.errors?.['required'] && !Email.errors?.['pattern'] && Email?.errors != null">
                                {{'User.Email_required' | translate}}</span>
                            <span class="text-center" *ngIf="Email.errors?.['maxlength'] && !Email.errors?.['pattern'] && Email?.errors != null">
                                 {{'User.valid_email_maxlen' | translate}}</span>
                                 <!-- Min Length -->
                                 <span class="text-center" *ngIf="Email.errors?.['minlength']">
                                 {{ "User.valid_email_sign_up" | translate }}
                                </span>
                        </mat-error>
                    </div>
                    <div class="form-group mt-3">
                        <div class="input-field">
                            <div class="">
                                <label for="">{{"User.Mobile_number" | translate}}</label>
                                <div class="mobile-country-code">
                                    <ngx-mat-intl-tel-input [class.error] = "contactUsForm.controls['phoneNumber'].invalid && (contactUsForm.controls['phoneNumber'].dirty || contactUsForm.controls['phoneNumber'].touched)" class="form-control-mobile" [class.ar-country]="currentLang == 'ar'"
                                    [enablePlaceholder]="true"
                                    [enableSearch]="true"
                                    #phone
                                    describedBy="phoneInput"
                                    formControlName="phoneNumber"
                                    (countryChanged)="getCountryCode($event)"
                                    (input) = "phoneInputChange($event)"
                                  ></ngx-mat-intl-tel-input>
                                </div>
                                <ng-container>
                                    <div><mat-hint>{{'User.eg' | translate}} {{phone.selectedCountry?.placeHolder?.replace('+'+dialCode,"")}}</mat-hint></div>
                                    <div  *ngIf="contactUsForm.controls['phoneNumber'].invalid && (contactUsForm.controls['phoneNumber'].dirty || contactUsForm.controls['phoneNumber'].touched)">
                                        <!-- <mat-error *ngIf="contactUsForm.controls['phoneNumber']?.errors?.['required']"
                                        >{{"User.Phone_number_required" | translate}}</mat-error> -->
                                        <mat-error *ngIf="contactUsForm.controls['phoneNumber']?.errors?.['validatePhoneNumber']"
                                            >{{"User.Invalid_number" | translate}}</mat-error
                                        >
                                    </div>

                                  </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <label for="">{{"User.message" | translate}} <span> *</span></label>
                        <textarea type="text" class="form-control" formControlName="message" autocomplete="address-level1"></textarea>
                        <mat-error class="" *ngIf="Message.invalid && (Message.dirty || Message.touched)">
                            <span *ngIf="Message.errors?.['required']">{{'User.enter_message' | translate}}</span>
                            <span class="text-center" *ngIf="Message.errors?.['maxlength'] && !Message.errors?.['required'] && Message?.errors != null">
                                 {{'User.valid_message_maxlen' | translate}}</span>
                            <span class="text-center" *ngIf="Message.errors?.['minlength'] && !Message.errors?.['required'] && Message?.errors != null">
                                 {{'User.valid_message_minlen' | translate}}</span>
                        </mat-error>
                    </div>
                    <div class="form-group mt-3 text-right mt-2">
                        <button class="btn-default m-1" type="button"  (click)="close()">{{"User.Cancel" | translate}}</button>
                        <button class="btn-primary m-1" type="submit" [disabled]="contactUsForm.invalid" >{{"User.submit" | translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
