<header *ngIf="isLoggedIn">
  <div class="logo">
    <img routerLink="/" src="assets/images/landing_page/logoArb.svg" *ngIf="currentLangID == 2; else defaultLogo"
      alt="Estater Markets">
    <ng-template #defaultLogo>
      <img routerLink="/" src="assets/images/landing_page/logo.svg" alt="Estater Markets">
    </ng-template>
  </div>
  <div class="header-right-contain">
    <a routerLink="/{{globalContants.RequestDemo}}" class="request_demo headerborder">
      <img src="../../../../assets/images/Request_Demo.png" alt="">
      <span>{{ "User.request_for_demo" | translate }}</span>
    </a>
    <!-- <div class="notification header-setting headerborder">
      <img
        alt="notification"
        src="../../../../assets/images/notification.svg"
      />
      <span class="notification-count">20</span>
    </div> -->

    <div *ngIf="selectedCity"
      class="city-selection header-setting headerborder"
      (click)="openCityModel()"
    >
      <img
        src="../../../../assets/images/city_icon/{{ placeCode }}.svg"
        onerror="this.src='../../../../assets/images/city_icon/kwt.svg';"
        alt="city-image"
      />
      <span>{{ (selectedCity | lowercase) | translate }}</span>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
    <div class="user-info header-setting" [matMenuTriggerFor]="profile">
      <img
        *ngIf="userInfo.profile_image"
        src="{{ userInfo.profile_image }}"
        alt="profile-image"
      />
      <img
        *ngIf="!userInfo.profile_image"
        src="../../../../assets/images/default-user.svg"
        alt="profile-image"
      />
      <!-- <div class="initial-name" *ngIf="userInfo.profile_image == ''">{{ userInfo.userString }}</div> -->
      <div class="user-name">{{ userInfo.uname }}</div>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </div>

    <mat-menu #profile="matMenu" xPosition="after" class="profile_menu">
      <div class="profile-header">
        <div class="profile-image">
          <img
            *ngIf="userInfo.profile_image"
            src="{{ userInfo.profile_image }}"
            alt="profile-image"
          />
          <img
            *ngIf="!userInfo.profile_image"
            src="../../../../assets/images/default-user.svg"
            alt="profile-image"
          />
        </div>
        <div class="profile-header-content">
          <div class="profile-user-name" [title]="userInfo.uname">{{ userInfo.uname }}</div>
          <ul class="profile-header-list">
            <li>
              <a
                mat-menu-item
                [routerLink]="cityLang + '/' + globalContants.Profile"
                >{{"User.visit_profile_page" | translate}}</a
              >
            </li>
            <li><button mat-menu-item (click)="logout()">{{'User.logout'| translate}}</button></li>
          </ul>
        </div>
      </div>
      <section class="profile-section">
        <ul>
          <li>
            <a [routerLink]="cityLang + '/' + globalContants.Profile + '/' + globalContants.PersonalInfo">
              <mat-icon svgIcon="vector" class="icon"></mat-icon>
              <span>{{ "User.Personal_info" | translate }}</span>
            </a>
          </li>
          <li>
            <a [routerLink]="cityLang + '/' + globalContants.Profile + '/' + globalContants.ResetPassword">
              <mat-icon svgIcon="security" class="icon"></mat-icon
                ><span>{{ "User.Reset_password" | translate }}</span>
            </a>
          </li>
          <!-- <li>
            <a>
              <mat-icon svgIcon="group" class="icon"></mat-icon
                ><span>{{ "User.People_and_sharing" | translate }}</span>
            </a>
          </li>
          <li>
            <a>
              <mat-icon svgIcon="creditCard" class="icon"></mat-icon
                ><span>{{ "User.Subscriptions_and_Payment" | translate }}</span>
            </a>
          </li> -->
        </ul>
      </section>
    </mat-menu>
  </div>
</header>
