<div class="container">
  <div class="list">
    <h2>{{'reports.folders' | translate}}</h2>
    <ul>
      <li [class.active]="data.id === selectedFolder" *ngFor="let data of message" (click) = "selectFolder(data.id)">{{(data.label | foldertranslate:data) }}</li>
    </ul>
  </div>
  <div class="list-btn d-flex">
    <button class="btn-primary" (click)="confirmDialogBox()">{{"User.submit" | translate}}</button>
  </div>
</div>
