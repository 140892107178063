import { OfficeInventoryMenuEnum } from "./report-office-inventory.enum";

export class ReportOfficeInventoryConstants{
    static overViewMenu = [
        {
            label: 'reports.overview',
            name: OfficeInventoryMenuEnum.overview,
            count: null
        },
        {
            label: 'reports.neighborhood',
            name: OfficeInventoryMenuEnum.neighborhood,
            count: null
        },
        {
            label: 'reports.projectsProperties',
            name: OfficeInventoryMenuEnum.properties,
            count: null
        }
    ]
}