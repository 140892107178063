import { Component, Input, SimpleChanges } from '@angular/core';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { SharedService } from 'src/app/common/services/shared.service';
import { UnitTypeEnum } from '../../../models/unit-type-enum';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from 'src/app/reports/services/report.service';
import { DecimalPipe } from '@angular/common';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';

@Component({
  selector: 'app-overview-card',
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.scss']
})
export class OverviewCardComponent {

  @Input() card_title: any;
  @Input() total_current: any;
  @Input() untillDate: any;
  @Input() total_prev: any;
  @Input() total_percentage: any;
  @Input() uniType:any=null;
  @Input() graphData:any;
  @Input() neighborhood:string='';
  @Input() formatInternation:boolean=false;
  @Input() isWas: boolean = true;

  current:any;
  symble:string='';
  totalSumValue: any;

  averageRentRate = new Map();
  occupancyRate = new Map();
  chartView = ReportsChartEnum;
  constructor(private sharedService: SharedService, private translate: TranslateService, 
    public reportService: ReportService,
    private decimalPipe: DecimalPipe){

    
  }

  ngOnChanges(changes: SimpleChanges) {
    
    if(this.total_current && this.formatInternation && changes['total_current']) {
      this.current = this.reportService.formatInternationNum(this.total_current);
    }else{
      this.current = this.decimalPipe.transform((this.total_current), '1.0-1');
    }
    if(this.uniType == UnitTypeEnum.sqm){
      this.symble = this.translate.instant(GlobalContants.keySymbolContants.squareMeterUnit)
    }
    if(this.uniType == UnitTypeEnum.percentage){
      this.symble = GlobalContants.keySymbolContants.percentage
    }
    if(this.uniType == UnitTypeEnum.currency){
      this.symble = this.translate.instant(this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode));
    }
    if(this.uniType == UnitTypeEnum.currencywithsqm){
      this.symble = ` ${this.translate.instant(this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode))}/${this.translate.instant(GlobalContants.keySymbolContants.squareMeterUnit)}`;
    }
    if(this.uniType == UnitTypeEnum.currencywithsqrt){
      this.symble = ` ${this.translate.instant(this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode))}/${this.translate.instant(GlobalContants.keySymbolContants.squarefeetUnit)}`;
    }
    
 
  }

  getOpacity(index:number, value: any): string{
    // Adjust the factor and starting value as per your requirement
    return this.changeOpacity(index, value);
  }

  getNumber(nbr: any){
    return +nbr;
  }

  
  changeOpacity(index: number, value: any){

    if(this.graphData?.type === 'averageRentRate'){

      if(this.averageRentRate.has(value)){
        return this.averageRentRate.get(value);
      } else {
        this.averageRentRate.set(value, this.getOpacityVal(index));
        return this.getOpacityVal(index);
      }

    } else {
      if(this.occupancyRate.has(value)){
        return this.occupancyRate.get(value);
      } else {
        this.occupancyRate.set(value, this.getOpacityVal(index));
        return this.getOpacityVal(index);
      }
    }

  }

  getOpacityVal(index:number): string{
    // Adjust the factor and starting value as per your requirement
    return (1 - (index * 0.1)).toFixed(2);
  }
  
}
