import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ReportOccupancyLeaseService } from './report-occupancy-lease.service';
import { ReportsListsService } from '../../services/reports-lists.service';
import { OccupancyLeaseReportConstant } from './report-occupancy-lease.constants';
import { ReportChartColumn, ReportTableColumn } from '../../models/reports.model';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { SharedService } from 'src/app/common/services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-report-occupancy-lease',
  templateUrl: './report-occupancy-lease.component.html',
  styleUrls: ['./report-occupancy-lease.component.scss']
})
export class ReportOccupancyLeaseComponent implements OnChanges, OnInit {
  RYDCHECK=environment.RYDCHECK;
  @Input() params: any;

  chartView = ReportsChartEnum;

  totalOccupancyLeaseReport: any = [];
  totalOccupancyLeaseReportColumn: ReportTableColumn[] = OccupancyLeaseReportConstant.totalSummaryColumn;
  occupancyLeaseTableColumn: ReportTableColumn[] = OccupancyLeaseReportConstant.OccupanyLeaseColumns;
  occupancyLeaseData: any = [];
  occupancyTableColumn: ReportTableColumn[] = OccupancyLeaseReportConstant.OccupanyLeaseColumns;
  occupancyData: any = [];
  investmentPropertyReport: ReportChartColumn[] = [];
  yearwiseOccupancyLeaseReport: any = [];
  totalLoading = false;
  investmentPropertyLoading = false;
  occupancyLeaseLoading = false;
  isFirstTime = true;
  occupancyLoading = false;
  placeCode: any;

  constructor(
    private reportOccupancyLeaseService: ReportOccupancyLeaseService,
    private reportsListsService: ReportsListsService,
    private sharedService: SharedService,
    private translateService: TranslateService
  ) {
    this.sharedService.language$.subscribe(lang => {
      if (lang && !this.isFirstTime) {
        this.getReports();
      }
    })
  }

  ngOnInit() {
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    this.getReports();   
    this.isFirstTime = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.getReports();
    }
  }

  getReports() {
    this.getTotalInventoryReport();
    this.getInvestmentPropertyReport();
    this.getYearwiseOccupancyLeaseReport();
    this.getOccupancyLeaseReport();
    this.getOccupancyReport();
  }

  getTotalInventoryReport(year = null) {
    this.totalLoading = true;
    this.reportOccupancyLeaseService.getTotalInventoryReport(year).subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200 && res.data.length > 0) {
            this.totalOccupancyLeaseReportColumn = [...this.reportsListsService.yearColumnAppend(OccupancyLeaseReportConstant.totalSummaryColumn, this.reportsListsService.summaryReportYearConversion(res?.data), false, '', '', false)];
            this.totalOccupancyLeaseReport = this.reportsListsService.summaryReportConversion(res.data, OccupancyLeaseReportConstant.totalOccupanyLeaseColumn)
          } else {
            this.totalOccupancyLeaseReport = [];
          }
          this.totalLoading = false;
        },
        error: (error: any) => {
          this.totalLoading = false;
          this.totalOccupancyLeaseReport = [];
        }
      }
    )
  }

  getInvestmentPropertyReport() {
    this.investmentPropertyLoading = true;
    this.reportOccupancyLeaseService.getInvestmentPropertyReport().subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            this.investmentPropertyReport = res.data.map((data: any) => {
              const obj = {
                category: data.year.toString(),
                value: data.total_property
              }
              return obj;
            })
          } else {
            this.investmentPropertyReport = [];
          }
          this.investmentPropertyLoading = false;
        },
        error: (error: any) => {
          this.investmentPropertyReport = [];
          this.investmentPropertyLoading = false;

        }
      }
    )
  }

  getYearwiseOccupancyLeaseReport() {
    this.reportOccupancyLeaseService.getYearwiseOccupancyLeaseReport().subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            this.yearwiseOccupancyLeaseReport = res.data.map((val: any) => {
              let obj = {
                category: val.year.toString(),
                lineValue: val.occupancy_ratio || 0,
                barValue: val.average_lease_price || 0,
                symbol: ` ${this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode)}`,
              };
              return obj;
            })
          } else {
            this.yearwiseOccupancyLeaseReport = [];
          }
        },
        error: (error: any) => {
          this.yearwiseOccupancyLeaseReport = [];
        }
      }
    )
  }

  getOccupancyLeaseReport() {
    this.occupancyLeaseLoading = true;
    this.reportOccupancyLeaseService.getOccupancyLeaseReport('lease_price').subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            let column = ['neigh_name', 'category'];
            if (res?.data?.year.length > 0) {
              this.occupancyLeaseTableColumn = [...this.reportsListsService.yearColumnAppend(OccupancyLeaseReportConstant.OccupanyLeaseColumns, res?.data.year, true)];
            }
            this.occupancyLeaseData = this.reportsListsService.convertReport(res?.data?.data, column, res.data?.year);
          } else {
            this.occupancyLeaseData = [];
          }
          this.occupancyLeaseLoading = false;
        },
        error: (error: any) => {
          this.occupancyLeaseData = [];
          this.occupancyLeaseLoading = false;
        }
      }
    )
  }

  getOccupancyReport() {
    this.occupancyLoading = true;
    this.reportOccupancyLeaseService.getOccupancyLeaseReport('occupancy').subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            let column = ['neigh_name', 'category'];
            if (res?.data?.year.length > 0) {
              this.occupancyTableColumn = [...this.reportsListsService.yearColumnAppend(OccupancyLeaseReportConstant.OccupanyLeaseColumns, res?.data.year, false, GlobalContants.keySymbolContants.percentage)];
            }
            this.occupancyData = this.reportsListsService.convertReport(res?.data?.data, column, res.data?.year);
          } else {
            this.occupancyData = [];
          }
          this.occupancyLoading = false;
        },
        error: (error: any) => {
          this.occupancyData = [];
          this.occupancyLoading = false;
        }
      }
    )
  }

  selectedYear(data: any) {
    this.getTotalInventoryReport(data.year);
  }

}
