import { SharedService } from 'src/app/common/services/shared.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ReportUnitService } from './report-unit.service';
import { ReportsListsService } from '../../services/reports-lists.service';
import { UnitReportConstant } from './report-unit.constants';
import { ReportChartColumn, ReportTableColumn } from '../../models/reports.model';
import { ReportsChartEnum } from '../../../../../reports/models/enums/reports.enum';
import { TranslateService } from '@ngx-translate/core';
import { GlobalContants } from 'src/app/common/constants/global.contants';

@Component({
  selector: 'app-report-unit',
  templateUrl: './report-unit.component.html',
  styleUrls: ['./report-unit.component.scss']
})
export class ReportUnitComponent implements OnInit, OnChanges {

  @Input() params: any;

  chartView = ReportsChartEnum;

  reports = UnitReportConstant.reports;

  unitTypeReportTableColumn: ReportTableColumn[] = UnitReportConstant.tableColumns;
  unitTypeData: any = [];
  totalUnitReport: any = [];
  totalUnitReportColumn: ReportTableColumn[] = UnitReportConstant.totalUnitColumn;
  investmentUnitReport: ReportChartColumn[] = [];
  unitOccupancyLeaseReport: ReportChartColumn[] = [];
  unitOccupancyTypeReport: ReportChartColumn[] = [];
  unitFurnishedTypeReport: ReportChartColumn[] = [];

  occupancyAverageFilter: any = [];
  occupancyAverageVal: string = '';
  occupancyAverageOriginalData: any = [];
  occupancyAverageData: any = [];
  isFirstTime = true;
  totalLoading = false;
  investmentUnitReportLoading = false;
  unitTypeDataLoading = false;
  unitOccupancyLeaseReportLoading = false;
  unitOccupancyTypeReportLoading = false;
  unitFurnishedTypeReportLoading = false;
  unitGradeYear: any;
  unitTypeYear: any;
  furnishedYear: any;

  constructor(
    private reportUnitService: ReportUnitService,
    private reportsListsService: ReportsListsService,
    private translate: TranslateService,
    private sharedService: SharedService
  ) {
    this.sharedService.language$.subscribe(lang => {
      if (lang && !this.isFirstTime) {
        this.getReports();
      }
    })
  }


  ngOnInit() {
    this.getReports();
    this.setLang();
    this.isFirstTime = false;
  }

  setLang(): void {
    this.sharedService.language$.subscribe(lang => {
      this.translate.use(lang);
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.getReports();
    }
  }

  getReports() {
    this.getTotalUnit();
    this.getInvestmentUnitReport();
    this.getUnitOccupancyLeaseReport();
    this.getUnitOccupancyTypeReport();
    this.getUnitFurnishedTypeReport();
    this.getUnitOccupancyAverageReport();
    this.getUnitTypeReport();
  }

  getTotalUnit(year = null) {
    this.totalLoading = true;
    this.reportUnitService.getTotalUnit(year).subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            this.totalUnitReportColumn = [...this.reportsListsService.yearColumnAppend(UnitReportConstant.totalSummaryColumn, this.reportsListsService.summaryReportYearConversion(res?.data), false, '', '', false)];
            this.totalUnitReport = this.reportsListsService.summaryReportConversion(res.data, UnitReportConstant.totalUnitColumn)
          } else {
            this.totalUnitReport = [];
          }
          this.totalLoading = false;
        },
        error: (error: any) => {
          this.totalUnitReport = [];
          this.totalLoading = false;
        }
      }
    )
  }

  getInvestmentUnitReport() {
    this.investmentUnitReportLoading = true;
    this.reportUnitService.getInvestmentUnitReport().subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            this.investmentUnitReport = res.data.map((data: any) => {
              const obj = {
                category: data.year.toString(),
                value: data.total_units
              }
              return obj;
            })
          } else {
            this.investmentUnitReport = [];
          }
          this.investmentUnitReportLoading = false;
        },
        error: (error: any) => {
          this.investmentUnitReport = [];
          this.investmentUnitReportLoading = false;
        }
      }
    )
  }

  getUnitOccupancyLeaseReport(year = null) {
    this.unitOccupancyLeaseReportLoading = true;
    if(year) this.unitGradeYear = year;
    this.reportUnitService.getUnitOccupancyTypeReport('unit_grade', this.unitGradeYear).subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            this.unitOccupancyLeaseReport = res.data.map((data: any) => {
              const obj = {
                category: data.category,
                value: data.occupancy_ratio
              }
              return obj;
            })
          } else {
            this.unitOccupancyLeaseReport = [];
          }
          this.unitOccupancyLeaseReportLoading = false;
        },
        error: (error: any) => {
          this.unitOccupancyLeaseReport = [];
          this.unitOccupancyLeaseReportLoading = false;
        }
      }
    )
  }

  getUnitOccupancyTypeReport(year = null) {
    this.unitOccupancyTypeReportLoading = true;
    if(year) this.unitTypeYear = year;
    this.reportUnitService.getUnitOccupancyTypeReport('unit_type', this.unitTypeYear).subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            this.unitOccupancyTypeReport = res.data.map((data: any) => {
              const obj = {
                category: data.category,
                value: data.occupancy_ratio
              }
              return obj;
            })
          } else {
            this.unitOccupancyTypeReport = [];
          }
          this.unitOccupancyTypeReportLoading = false;
        },
        error: (error: any) => {
          this.unitOccupancyTypeReport = [];
          this.unitOccupancyTypeReportLoading = false;
        }
      }
    )
  }

  getUnitFurnishedTypeReport(year = null) {
    this.unitFurnishedTypeReportLoading = true;
    if(year) this.furnishedYear = year;
    this.reportUnitService.getUnitOccupancyTypeReport('furnished', this.furnishedYear).subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            this.unitFurnishedTypeReport = res.data.map((data: any) => {
              const obj = {
                category: data.category,
                value: data.occupancy_ratio
              }
              return obj;
            })
          } else {
            this.unitFurnishedTypeReport = [];
          }
          this.unitFurnishedTypeReportLoading = false;
        },
        error: (error: any) => {
          this.unitFurnishedTypeReport = [];
          this.unitFurnishedTypeReportLoading = false;
        }
      }
    )
  }

  getUnitOccupancyAverageReport() {
    this.reportUnitService.getUnitOccupancyAverageReport().subscribe(
      {
        next: (res: any) => {
          if (res?.data && res?.data.length > 0) {
            this.occupancyAverageOriginalData = res.data;
            this.occupancyAverageFilter = this.reportsListsService.getUniqueListBy(res?.data, 'category').map((v: any) => v.category);
            this.occupancyAverageVal = this.occupancyAverageFilter[0];

            this.occupancyAverageData = res?.data.filter((v: any) => v.category == this.occupancyAverageVal).map((val: any) => {
              return {
                category: val.year.toString(),
                lineValue: val.occupancy_ratio || 0,
                barValue: val.average_lease_price || 0,
                symbol: ` ${this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode)}`,

              };
            });
          }


        },
        error: (error: any) => {
          this.unitTypeData = [];
        }
      }
    )
  }

  getUnitTypeReport() {
    this.unitTypeDataLoading = true;
    this.reportUnitService.getUnitTypeReport().subscribe(
      {
        next: (res: any) => {
          if (res && res.status == 200) {
            let unit_numberOfUnits = ['neigh_name', 'category'];
            if (res?.data?.year.length > 0) {
              this.unitTypeReportTableColumn = [...this.reportsListsService.yearColumnAppend(UnitReportConstant.tableColumns, res?.data.year)];
            }
            this.unitTypeData = this.reportsListsService.convertReport(res?.data?.data, unit_numberOfUnits, res.data?.year);
          } else {
            this.unitTypeData = [];
          }
          this.unitTypeDataLoading = false;
        },
        error: (error: any) => {
          this.unitTypeData = [];
          this.unitTypeDataLoading = false;
        }
      }
    )
  }

  selectedYear(data: any) {
    
    switch (data.reportType) {
      case this.reports.unit_grade:
        this.getUnitOccupancyLeaseReport(data.year);
        break;
      case this.reports.unit_type:
        this.getUnitOccupancyTypeReport(data.year);
        break;
      case this.reports.furnished:
        this.getUnitFurnishedTypeReport(data.year);
        break;
      case 'totalReport':
        this.getTotalUnit(data.year);
        break;

    }
  }

  occupancyAverageFilterChange(value: string) {

    this.occupancyAverageVal = value;

    this.occupancyAverageData = this.occupancyAverageOriginalData.filter((v: any) => v.category == this.occupancyAverageVal).map((val: any) => {
      return {
        category: val.year.toString(),
        lineValue: val.occupancy_ratio || 0,
        barValue: val.average_lease_price || 0,
        symbol: ` ${this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode)}`,

      };
    });

  }


}
