<div class="transaction-property-detail">
    <app-child-breadcum [property_uid]="propertyInfo?.property_uid" (back)="backToTransaction()"></app-child-breadcum>

    <ng-container *ngIf="overviewLoading">
        <app-skeleton-loader [reportType]="chartView.overView" [loaderSize]="1"></app-skeleton-loader>
    </ng-container>
    <div class="property-details" *ngIf="areaInfo">

        <div class="property-info">
            <h4>{{ propertyInfo?.proj_name | langtranslate}}</h4>
            <p><strong>{{'reports.developer' | translate}} : </strong><span>{{ areaInfo?.developer_name }}</span></p>
            <p><strong>{{'build_year' | translate}} : </strong><span>{{areaInfo?.year}}</span></p>
            <div class="property-zone" *ngIf="areaInfo?.zoning">
                <h4>{{'reports.property_type_zoning' | translate}}</h4>
                <div class="zoning-info">
                    <span>{{ "reports.zoning" | translate }} : <strong>{{ areaInfo?.zoning }}</strong></span>
                </div>

            </div>

            <div class="property-information">
                <h4>{{'reports.project_information' | translate}}</h4>

                <div class="property-details-d">
                    <div class="property-area">
                        <h6>{{'reports.total_area' | translate}}</h6>
                        <span [matTooltip]="areaInfo?.total_land_area">{{ areaInfo?.total_land_area
                            ?
                            areaInfo?.total_land_area : ('na' | translate)
                            }}</span>
                        <h6 *ngIf="areaInfo?.total_land_area">{{'sqm_unit' | translate}}</h6>
                    </div>
                    <div class="property-area frontage">
                        <h6>{{'reports.others' | translate}}</h6>
                        <span [matTooltip]="areaInfo?.road">{{ areaInfo?.road ?
                            areaInfo?.road+'%' : ('na' | translate) }}</span>
                    </div>
                    <div class="property-area setback">
                        <h6>{{'reports.parks' | translate}}</h6>
                        <span [matTooltip]="areaInfo?.park">{{ areaInfo?.park ?
                            areaInfo?.park+'%' : ('na' | translate) }}</span>
                        <h6 *ngIf="areaInfo?.park"></h6>
                    </div>
                    <div class="property-area corner">
                        <h6>{{'reports.plots' | translate}}</h6>
                        <span [matTooltip]="areaInfo?.plot">{{ areaInfo?.plot ?
                            areaInfo?.plot+'%' :
                            ('na' | translate) }}</span>

                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="property-info-img">
            <div class="property-imgD">
                <img src="assets/images/housevilla.png">
            </div>

        </div> -->

    </div>
    <ng-container *ngIf="landLoading">
        <app-skeleton-loader [reportType]="chartView.overView" [loaderSize]="3"></app-skeleton-loader>
    </ng-container>
    <div class="report-overview reportov-housevilla" *ngIf="landInfo">
        <app-overview-card
                [card_title]="'reports.totalLandVillas'" 
                [total_current]="landInfo?.total_land"
                [untillDate]="landInfo?.max_date"
                [total_prev]="landInfo?.prev_total_land"
                [total_percentage]="landInfo?.tot_land_diff"></app-overview-card>

        <app-overview-card
                [card_title]="'reports.averageLandArea'" 
                [total_current]="landInfo?.avg_land"
                [untillDate]="landInfo?.max_date"
                [total_prev]="landInfo?.prev_avg_land"
                [total_percentage]="landInfo?.avg_land_diff"
                [uniType]="UnitTypeEnum.sqm"></app-overview-card>

        <app-overview-card
                [card_title]="'reports.landDevelopmentRate'" 
                [total_current]="landInfo?.land_dev_rate"
                [untillDate]="landInfo?.max_date"
                [total_prev]="landInfo?.prev_land_dev_rate"
                [total_percentage]="landInfo?.land_dev_rate_diff"
                [uniType]="UnitTypeEnum.percentage"></app-overview-card>

                
        <!-- <div class="overview">
            <div class="overview-data">
                <h2>{{"reports.totalLandVillas" | translate}}</h2>
                <h1>{{ landInfo?.total_land | number:'1.0-1' }}</h1>

                <span>{{"reports.forYear" | translate}} {{landInfo?.max_date}}</span>

            </div>
            <div class="overview-v">
                <span>{{"reports.was" | translate}} {{ landInfo?.prev_total_land | number:'1.0-1'
                    }}</span>
                <div class="overview-prev">
                  <h5 class="normal-price" *ngIf="landInfo?.tot_land_diff == 0">{{
                    landInfo?.tot_land_diff }} %</h5>
                    <h5 class="high-price" *ngIf="landInfo?.tot_land_diff > 0">+ {{
                        landInfo?.tot_land_diff }} %</h5>
                    <h5 class="low-price" *ngIf="landInfo?.tot_land_diff < 0">{{
                        landInfo?.tot_land_diff }} %</h5>
                    <span>{{"reports.fromLastYear" | translate}}</span>
                    <mat-icon *ngIf="landInfo?.tot_land_diff > 0" class="high-price">
                        call_made</mat-icon>
                    <mat-icon *ngIf="landInfo?.tot_land_diff < 0" class="low-price">
                        call_received</mat-icon>
                </div>
            </div>
        </div>
        <div class="overview">
            <div class="overview-data">
                <h2>{{"reports.averageLandArea" | translate}} </h2>
                <h1>
                    <ng-container *ngIf="landInfo?.avg_land; else otheravgLand">
                        {{landInfo?.avg_land | number:'1.0-1'}} <sup class="sup-font">{{sqm_unit | translate}}</sup>
                    </ng-container>
                    <ng-template #otheravgLand>-</ng-template>
                </h1>

                <span>{{"reports.forYear" | translate}} {{landInfo?.max_date}}</span>

            </div>
            <div class="overview-v">
                <span>{{"reports.was" | translate}} {{ landInfo?.prev_avg_land ? (landInfo?.prev_avg_land | number:'1.0-1') + (' reports.sqm_unit' | translate) :''
                    }}</span>
                <div class="overview-prev">

                  <h5 class="normal-price" *ngIf="landInfo?.avg_land_diff == 0"> {{
                    landInfo?.avg_land_diff }} %</h5>
                    <h5 class="high-price" *ngIf="landInfo?.avg_land_diff > 0">+ {{
                        landInfo?.avg_land_diff }} %</h5>
                    <h5 class="low-price" *ngIf="landInfo?.avg_land_diff < 0">{{
                        landInfo?.avg_land_diff }} %</h5>
                    <span>{{"reports.fromLastYear" | translate}}</span>
                    <mat-icon *ngIf="landInfo?.avg_land_diff > 0" class="high-price">
                        call_made</mat-icon>
                    <mat-icon *ngIf="landInfo?.avg_land_diff < 0" class="low-price">
                        call_received</mat-icon>
                </div>
            </div>
        </div>
        <div class="overview">
            <div class="overview-data">
                <h2>{{"reports.landDevelopmentRate" | translate}}</h2>
                <h1>
                    <ng-container *ngIf="landInfo?.land_dev_rate; else otherLandDevRate">
                        {{landInfo?.land_dev_rate | number:'1.0-1'}} <sup class="sup-font">%</sup>
                    </ng-container>
                    <ng-template #otherLandDevRate>-</ng-template>
                </h1>

                <span>{{"reports.forYear" | translate}} {{landInfo?.max_date}}</span>

            </div>
            <div class="overview-v">
                <span>{{"reports.was" | translate}} {{ landInfo?.prev_land_dev_rate ? (landInfo?.prev_land_dev_rate | number:'1.0-1') + '%': ''
                    }}</span>
                <div class="overview-prev">
                  <h5 class="normal-price" *ngIf="landInfo?.land_dev_rate_diff == 0"> {{
                    landInfo?.land_dev_rate_diff }} %</h5>
                    <h5 class="high-price" *ngIf="landInfo?.land_dev_rate_diff > 0">+ {{
                        landInfo?.land_dev_rate_diff }} %</h5>
                    <h5 class="low-price" *ngIf="landInfo?.land_dev_rate_diff < 0">{{
                        landInfo?.land_dev_rate_diff }} %</h5>
                    <span>{{"reports.fromLastYear" | translate}}</span>
                    <mat-icon *ngIf="landInfo?.land_dev_rate_diff > 0" class="high-price">
                        call_made</mat-icon>
                    <mat-icon *ngIf="landInfo?.land_dev_rate_diff < 0" class="low-price">
                        call_received</mat-icon>
                </div>
            </div>
        </div> -->
    </div>

    <!-- Charts -->

    <div class="report-chart-overview reportov-housevilla">
        <!-- <div class="overview overview-chart">
            <div class="overview-data">
                <h2>{{"Total Villas Unit Type Wise" | translate}}</h2>
            </div>
        </div> -->
        <div class="overview overview-chart">
            <div class="overview-data">
                <!-- <h2>{{"Developed vs Vacant Villas" | translate}}</h2> -->
                <h2>{{"reports.independent_villas" | translate}}</h2>
            </div>
            <ng-container *ngIf="landLoading">
                <app-skeleton-loader [reportType]="chartView.pieChart"></app-skeleton-loader>
            </ng-container>
            <ng-container *ngIf="projectDetailsPieData.length && !landLoading; else noDataMessage">
                <app-report-chart-view
                [height]="'200px'"
                [data]="projectDetailsPieData"
                [chartType]="chartView.pieChart"
                [isBorder]="false"
                ></app-report-chart-view>
            </ng-container>
            <ng-template #noDataMessage>
                <div class="data_unavail">
                    <span>
                        {{"reports.data_not_available" | translate}} <mat-icon  role="img" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon>
                    </span>
                </div>
            </ng-template>
            <!-- <ng-container *ngIf="projectDetailsPieData.length == 0">
                <p>{{"reports.no_record_found" | translate}}</p>
            </ng-container> -->
        </div>
    </div>

    <!-- DataTable -->
    <app-report-year-dropdown
            [reportType]="'projectDetails'"
            (selectedYear)="selectedYear($event)"
        ></app-report-year-dropdown>
    <div class="report-model">

        <div class="report-model-header">
            <div class="info">
                <h3>{{'reports.units' | translate}}</h3>
                <span class="totalTransaction">{{ propertyTransactionCount }} {{'total_units' | translate}}</span>
            </div>

            <!-- <search-bar></search-bar> -->
        </div>
        <!-- <ng-container *ngIf="allDataLoading">
            <app-skeleton-loader [loaderSize]="8"></app-skeleton-loader>
        </ng-container> -->
        <app-report-datatable [showLoader]="!unitsLoading" [tableColumns]="propertyTransactionColumns" [dataLength]="propertyTransactionCount"
            [tableData]="propertyTransactiondata" [allowExcelDownload]="true" [showEstaterText]="true"
            (pageEvent)="onPageEvent($event, '')" (sort)="sortData($event, '')"
            (excelDownload)="downloadExcel($event, 'project_details')" [downloadLoading]="downloadLoading"></app-report-datatable>
    </div>

</div>
