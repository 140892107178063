<div class="excel-download" [ngClass]="{ 'excel-ar-download': current_lang === 'ar' }">

  <!--- Down load icon -->
  <ng-container *ngIf="
      tableDataSource.data?.length !== 0 &&
      tableDataSource?.data &&
      allowExcelDownload
    ">
    <button class="download_excel" [disabled]="LockReport" *ngIf="!downloadLoading" (click)="downloadExcel()" matTooltip="{{ 'reports.download_excel' | translate }}"
      matTooltipPosition="above"><img *ngIf="!LockReport" src="../../../../../../assets/images/excel-icon.svg" />
      <img *ngIf="LockReport" src="../../../../../../assets/images/excel-icon-disable.svg" /></button>
    <span *ngIf="downloadLoading"><mat-spinner class="download-spinner"></mat-spinner></span>
  </ng-container>

  <!--- Down load icon -->

  <!--- Dropdown option -->
  <div class="table-dropdown" *ngIf="dropDownOption && (tableDataSource.data?.length !== 0 && tableDataSource?.data)">
    <button class="btn-select-dropdown" [matMenuTriggerFor]="menu">
      {{ "reports.show_value_in" | translate }} :
      <span>{{
        selectedRadio
        ? ("reports." + selectedRadio | translate)
        : ("reports." + dropDownOption.default | translate)
        }}</span>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before" class="table-dropdown-menu">
      <div (click)="$event.stopPropagation()">
        <h5 *ngIf="dropDownOption.label">
          {{ dropDownOption.label | translate }}
        </h5>
        <mat-radio-group aria-label="Select an option">
          <ng-container *ngFor="let option of dropDownOption.data; let i = index">
            <mat-radio-button (change)="selectRadioValue(option.id, option.value)" value="{{ option.id }}"
              [checked]="option.id === dropDownOption.default">{{ "reports." + option.value | translate
              }}</mat-radio-button>
          </ng-container>
        </mat-radio-group>
      </div>
    </mat-menu>
  </div>


  <!--- Dropdown option -->
</div>


<!--- grid table -->
<div class="grid-wrapper">

  <div class="grid-default" id="tablediv">
    <div class="combined_both">
      <table mat-table matSort multiTemplateDataRows [dataSource]="tableDataSource"
        (matSortChange)="onSortTable($event)" class="mat-elevation-z8">
        <ng-container *ngFor="let tableColumn of tableColumns; index as i">
          <ng-container *ngIf="!tableColumn.hideColumn">
            <ng-container [matColumnDef]="tableColumn.header">

              <!---  Column -->
              <ng-container *ngIf="tableColumn.isSortable; else notSortable">
                <th mat-header-cell *matHeaderCellDef [class.custom-bg-th]="tableColumn.border">
                  <span [mat-sort-header]="tableColumn.header">


                    <ng-container *ngIf="dropDownOption; else noDropDownOption">
                      <span class="header_label">
                        {{ tableColumn?.columnName | translate }}

                        <span class="text-lowercase text-right mlr-10px" >{{
                          tableColumn?.symbol
                          ? valueDataType(tableColumn?.symbol)
                          : ""
                          }}</span>
                      </span>
                    </ng-container>
                    <ng-template #noDropDownOption>
                      <span class="header_label">
                      {{ tableColumn?.columnName | translate }}

                      <span class="text-transform-normal mlr-10px">{{
                        tableColumn?.symbol
                        ? valueDataType(tableColumn?.symbol)
                        : ""
                        }}</span>
                        </span>

                    </ng-template>
                  </span>
                </th>
              </ng-container>

              <ng-template #notSortable>
                <th mat-header-cell *matHeaderCellDef [class.custom-bg-th]="tableColumn.border">
                  <ng-container *ngIf="dropDownOption; else noDropDownOption">
                    <span class="header_label">
                      {{ tableColumn?.columnName | translate }}

                      <span class="text-transform-normal mlr-10px">{{
                        tableColumn?.symbol
                        ?  valueDataType(tableColumn?.symbol)
                        : ""
                        }}</span>
                    </span>
                  </ng-container>
                  <ng-template #noDropDownOption>
                    <span>{{ tableColumn?.columnName | translate }}</span>



                    <span class="text-transform-normal mlr-10px" [class.break-text]="tableColumn?.isBreak">{{
                      tableColumn?.symbol
                      ?  valueDataType(tableColumn?.symbol)
                      : ""
                      }}</span>
                  </ng-template>
                </th>
              </ng-template>

              <!---  Column -->

              <!---  column data -->
              <ng-container>
                <td mat-cell *matCellDef="let element" [ngClass]="{'blur-content': element['NODATA']}" [class.custom-bg-td]="tableColumn.border"
                  style="position: relative">
                  <ng-container *ngIf="!tableColumn.isContainsHTML">
                    <div class="column-data">
                      <div class="expand-icon" *ngIf="expandColumn && i === 0">
                        <mat-icon (click)="
                            expandedElement =
                              expandedElement === element ? null : element
                          ">{{
                          expandedElement === element
                          ? "arrow_drop_down"
                          : current_lang === "ar"
                          ? "arrow_left"
                          : "arrow_right"
                          }}</mat-icon>
                      </div>

                      <div class="tooltip" [ngClass]="{'showonmap-column' : element?.lat && tableColumn?.showOnMap}">
                        <span [class.direction] = "tableColumn?.columnName === 'reports.transactionDate' || tableColumn?.columnName === 'reports.date_of_transaction'" class="column_entry" matTooltip="{{
                            isCharNumber(element[tableColumn.toolTipField])
                          }}" [matTooltipDisabled]="
                            showTooltip && element[tableColumn.toolTipField]
                              ? false
                              : true
                          ">
                          {{
                          element[tableColumn.field]
                          ? tableColumn?.convertNumber
                          ? (element[tableColumn.field]
                          | number : "1.0-1") +
                          (tableColumn?.header === "occupancy"
                          ? "%"
                          : "")
                          : element[tableColumn.field] +
                          (tableColumn?.header === "occupancy"
                          ? "%"
                          : "")
                          : (tableColumn?.header === "occupancy") ? '0%' : "-"
                          }}
                          {{
                          element[tableColumn.field] && tableColumn?.valSymbol
                          ? tableColumn?.valSymbol
                          : ""
                          }}</span>


                        <span class="tooltiptext" (click)="panToMap(element, tableColumn.field)"
                          *ngIf="(element?.lat || element?.tenants_lat_lon) && tableColumn?.showOnMap">{{ "reports.show_on_map" | translate }}</span>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="tableColumn.isContainsHTML">
                    <span class="htmlView" *ngIf="element[tableColumn.field]"
                      [innerHtml]="element[tableColumn.field] | safeHtml"></span>
                    <span class="htmlView" *ngIf="!element[tableColumn.field]" [innerHtml]="'-'"></span>
                  </ng-container>
                </td>
              </ng-container>
              <!---  column data -->


            </ng-container>
          </ng-container>
        </ng-container>


        <!--- Expand td Column -->
        <ng-container matColumnDef="expandedDetail" *ngIf="expandColumn">
          <td class="expand-td" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="expand-element-detail" [@detailExpand]="
                element == expandedElement ? 'expanded' : 'collapsed'
              ">
              <table [style.background-color]="expandbgColor">
                <!-- <ng-container *ngFor="let tableColumn of tableColumns; index as i">


                    </ng-container> -->
                <ng-container *ngIf="element.details">
                  <tr *ngFor="let child of element.details">
                    <td *ngFor="let expandCol of tableExpandColumns" [ngClass]="{
                        'expand-td-item':
                          !currentToggle && currentReportView == 2
                      }">
                      <!-- <span *ngIf="expandCol.convertNumber">{{ child[expandCol.field] ? (child[expandCol.field] | number : "1.0-1") : '-'}}</span>
                    <span *ngIf="!expandCol.convertNumber">{{ child[expandCol.field] ? child[expandCol.field] : '-' }}</span> -->
                      <div class="expand-data">
                        {{
                        child[expandCol.field]
                        ? expandCol?.convertNumber
                        ? (child[expandCol.field] | number : "1.0-1") +
                        (expandCol?.field === "occupancy" ? "%" : "")
                        : child[expandCol.field] +
                        (expandCol?.field === "occupancy" ? "%" : "")
                        : "-"
                        }}
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </table>
            </div>
          </td>
        </ng-container>
        <!--- Expand td Column -->

        <!--- normal td Column -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let j = dataIndex" class="example-element-row"
          [class.example-expanded-row]="expandedElement === row"
          [style.background-color]="j % 2 === 0 ? '#F5F5F5' : '#FFFFFF'" (click)="setExpadndColor(j)"
          [ngClass]="{ hideTable: !showLoader }"></tr>
        <!--- normal td Column -->

        <!--- Expand tr Column -->
        <ng-container *ngIf="expandColumn">
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </ng-container>
        <!--- Expand tr Column -->
      </table>

      <!--- No Record Found -->
      <div class="data_unavail" *ngIf="tableDataSource.data?.length === 0 || !tableDataSource?.data">
        <span>
            {{"reports.no_record_found" | translate}} <!-- <mat-icon role="img"
                class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color"
                aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon> -->
        </span>
    </div>

      <!--- No Record Found -->
      <!-- Table content blur start -->
      <div class="lock-table-row" *ngIf="LockReport">
        <img src="../../../../../../assets/images/Lock.png" alt="">
      </div>
      <!-- Table content blur end -->
    </div>
    <div id="loaderDiv" *ngIf="!showLoader">
      <app-skeleton-loader [loaderSize]="tableDataSource.data.length / 2"></app-skeleton-loader>
    </div>
  </div>



  <!--- Pagination -->
  <div class="pagination-wrapper pagination-showing-count"
    *ngIf="tableDataSource.data?.length !== 0 && tableDataSource?.data && !hidePagination">
    <p *ngIf="!LockReport">
      {{ "reports.Showing" | translate }} {{ startIndex }}
      {{ "reports.to" | translate }} {{ endIndex }}
      {{ "reports.outOf" | translate }}
      {{ reportService.formatInternationNum(dataLength) }}
    </p>
    <p *ngIf="LockReport" class="pb-2">
      <span class="contact-sale" (click)="openContactSales()">{{'contactSales' | translate }}</span>
      {{'to_unlock' | translate }} {{reportService.formatInternationNum(dataLength) }} {{'rows_more'| translate}}.
    </p>

    <mat-paginator *ngIf="dataLength > 20" [disabled]="!showLoader || LockReport" class="default-pagination" [length]="dataLength" [pageSize]="20"
      [pageSizeOptions]="[5, 10, 15, 25, 100]" [showFirstLastButtons]="true" (page)="onPageChange($event)"
      [hidePageSize]="true">
    </mat-paginator>

    <!-- Pagination input -->
    <div class="go-to-page d-flex align-items-center" *ngIf="dataLength > 20">
      <input class="go-to-page-input" type="number" [(ngModel)]="goToPageNumber" (change)="onGoToPage()"
        [disabled]="totalPages === 1 || !showLoader || LockReport" />
      <span class="oftext">of</span>
      <span>{{ totalPages }}</span>
    </div>
  </div>
  <!--- Pagination -->

  <!--- data cleaned Text -->
  <div class="dataCleaned">
    <span>
      <ng-container *ngIf="showEstaterText">{{
        "reports.dataCleaned" | translate
        }}</ng-container>
    </span>
  </div>

  <!--- data cleaned Text -->
</div>


<!--- grid table -->
