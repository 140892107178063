import { ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';
import { ReportOfficeInventoryConstants } from './report-office-inventory.constants';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { OfficeInventoryMenuEnum } from './report-office-inventory.enum';
import { ReportOfficeInventoryService } from './report-office-inventory.service';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { ReportService } from 'src/app/reports/services/report.service';
import { StoreService } from 'src/app/common/services/store.service';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { UnitTypeEnum } from '../../models/unit-type-enum';

@Component({
  selector: 'app-report-office-inventory',
  templateUrl: './report-office-inventory.component.html',
  styleUrls: ['./report-office-inventory.component.scss']
})
export class ReportOfficeInventoryComponent {
  @Input() params: any;
  overViewMenu: any = ReportOfficeInventoryConstants.overViewMenu;
  officeInventoryMenuEnum = OfficeInventoryMenuEnum;
  chartView = ReportsChartEnum;
  UnitTypeEnum = UnitTypeEnum;

  currentScroll: any = '';
  placeName = '';
  CurrencyCode: string = '';
  officeRentOption: string = 'officeRentOption';
  moreText: any;
  langName: any;
  overviewLoading = false;
  overviewGraphLoading = false;
  isFirstTime = true;
  isChart = true;

  propertiesData: any = {};
  openMarketData: any = {};
  captiveOfficeData: any = {};
  overviewChartData: any = {};
  selectedChartValue: any[] = [];
  retailRentalChartReport: any[] = [];

  overviewData$ = new Subscription();
  overviewDataGraph$ = new Subscription();

  constructor(
    private sharedService: SharedService,
    private officeService: ReportOfficeInventoryService,
    public reportService: ReportService,
    private storeService: StoreService,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
  ){
    this.sharedService.language$.subscribe(lang => {
      this.langName = lang;
      if (lang && !this.isFirstTime) {
        this.getReports();
        this.isChart = false;
      }
    })
  }
  ngOnInit(){
    this.placeName = this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceName);
    this.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
    this.getReports();
    this.isFirstTime = false;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.getReports();
    }
  }

  /**
   * All Rports api call
  */
  getReports() {
    this.getOverview();
    this.getOverviewGraph();
  }
  /**
   * Overview all type Data
   */
  getOverview() {
    this.overviewLoading = true;
    this.overviewData$ = this.officeService.getOverview().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            //propertiesData
            this.propertiesData = res.data.total_properties;
            this.propertiesData['untillDate'] = this.propertiesData.max_date ? moment.utc(this.propertiesData.max_date).local().format('DD MMM YYYY') : null;
            this.propertiesData['totalaverage_rate'] = this.reportService.calcPercentage(this.propertiesData.prev_total_properties, this.propertiesData.total_properties);
            //openMarketData
            this.openMarketData = res.data.open_market_share;
            this.openMarketData['untillDate'] = this.openMarketData.max_date ? moment.utc(this.openMarketData.max_date).local().format('DD MMM YYYY') : null;
            this.openMarketData['totalaverage_rate'] = this.reportService.calcPercentage(this.openMarketData.prev_open_market_share, this.openMarketData.open_market_share);
            //captiveOfficeData
            this.captiveOfficeData = res.data.captive_office_share;
            this.captiveOfficeData['untillDate'] = this.captiveOfficeData.max_date ? moment.utc(this.captiveOfficeData.max_date).local().format('DD MMM YYYY') : null;
            this.captiveOfficeData['totalaverage_rate'] = this.reportService.calcPercentage(this.captiveOfficeData.prev_captive_office_share, this.captiveOfficeData.captive_office_share);
          }
          this.overviewLoading = false;
        },
        error: (error: any) => {
          this.overviewLoading = false;
        }
      }
    )
  }
  /**
   * Overview year chart
   */
  getOverviewGraph() {
    this.overviewGraphLoading = true;
    this.overviewDataGraph$ = this.officeService.getOverviewGraph().subscribe({
      next: (res: any) => {
        if (res.status === 200) {
          this.overviewChartData = res.data;
          let officeRentOption: any = this.storeService.get(this.officeRentOption);
          if (officeRentOption && officeRentOption?.length > 0) {
            this.selectedChartValue = officeRentOption;
          } else {
            // Select the first three keys by default
            this.selectedChartValue = Object.keys(this.overviewChartData).slice(0, 3);
          }
          // Transform the data
          Object.keys(this.overviewChartData).forEach((key) => {
            let data = this.overviewChartData[key];
            this.overviewChartData[key] = data.map((obj: any) => ({
              category: obj.year.toString(),
              value: obj.count,
              name: key
            }));
          });
          // Update the retail rental chart report
          this.retailRentalChartReport = Object.keys(this.overviewChartData)
            .filter(key => this.selectedChartValue.includes(key))
            .reduce((obj: any, key: any) => {
              obj[key] = this.overviewChartData[key];
              return obj;
            }, {});
              this.showMoreText();
              this.cdr.detectChanges();
        } else {
          this.overviewChartData = null;
        }
        this.overviewGraphLoading = false;
      },
      error: (error: any) => {
        this.overviewGraphLoading = false;
        this.overviewChartData = null;
      }
    });
  }
  showMoreText() {
    let textContent = document.querySelector('.setext')?.textContent || '';
    this.moreText = this.reportService.showMoreText(this.selectedChartValue, textContent);
  }
  getReportKeys(): string[] {
    if (this.overviewChartData) {
      return Object.keys(this.overviewChartData);
    }
    return [];
  }
  /**
   * 
   * @param e 
   * @param value 
   * @returns 
   * On clicking the dropdown, the chart will be added or removed
   */
  selectChart(e: any, value: any) {
    let data = this.reportService.selectChart(
      this.selectedChartValue,
      this.overviewChartData,
      e,
      value
    );
    if(data) this.retailRentalChartReport = data;
    this.overviewGraphLoading = false;
    this.showMoreText();
  }
  /**
   * 
   * @param menu 
   * if click menu scroll particular div
   */
  menuChangeValue(menu: any) {
    this.currentScroll = menu.name;
    setTimeout(() => {
      this.scrollPoint(menu.name);
    })
  }
  scrollPoint(menu: string) {
    this.sharedService.scrollMenu(menu);
  }
  ngOnDestroy() {
    this.overviewData$.unsubscribe();
    this.overviewDataGraph$.unsubscribe();
  }
}

