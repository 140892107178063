import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-min-max-slider',
  templateUrl: './min-max-slider.component.html',
  styleUrls: ['./min-max-slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MinMaxSliderComponent implements OnInit, OnChanges {
  @Input() name!:any;
  @Input() sliderValue:any;
  @Input() floorMin!:number;
  @Input() floorMax!:number;
  @Input() required:boolean=false;
  
  minValue!:number;
  maxValue!:number;

  unitForm!: FormGroup;

  errorCheck:boolean=false;
  errorMax: boolean=false;
  constructor(
    private controlContainer: ControlContainer
  ) {}

  ngOnInit(): void {    
    this.minValue = this.sliderValue?.min ? this.sliderValue?.min : 0;
    this.maxValue = this.sliderValue?.max ? this.sliderValue?.max : 0;
    
    this.unitForm = this.controlContainer.control as FormGroup;
    

    this.unitForm.valueChanges.subscribe((value) => {
      if (value.min && this.checkRangeValidation(value)) {
        this.minValue = value.min;
      }
      if (value.max && this.checkRangeValidation(value)) {
        this.maxValue = value.max;
      }
    });
  }

  ngOnChanges(){
    this.minValue = this.sliderValue?.min ? this.sliderValue?.min : 0;
    this.maxValue = this.sliderValue?.max ? this.sliderValue?.max : 0;
  }

  sliderValueChange(event:any) {
    this.unitForm.controls['min'].patchValue(this.minValue);
  }

  highValueChange(event:any) {
    this.unitForm.controls['max'].patchValue(this.maxValue);
  }
  numberOnly(event:any): boolean {
    const pattern = /^[0-9]+$/;
      if (pattern.test(event.key)) {
        return true;
      }
    return false;
  }


  checkRangeValidation(val:any){    
      if(this.floorMax && (this.floorMax < Number(val.max))){
        this.errorMax = true;
        this.errorCheck = false;
        this.unitForm.setErrors({'error':true})
        return false;
      }else{       
        if(Number(val.min) > Number(val.max)){
          this.errorCheck = true;
          this.errorMax = false;
          this.unitForm.setErrors({'error':true})
          return false;
        }
      }
      this.unitForm.removeValidators;
      this.errorMax = false;
      this.errorCheck = false;
      return true;
  }
}
