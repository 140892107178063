import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { HouseVillasReportConstant } from './report-house-villas.constants';
import { SharedService } from 'src/app/common/services/shared.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { ReportHouseVillasService } from './report-house-villas.service';
import { HouseVillasMenuEnum } from './report-house-villas.enum';
import { ReportService } from 'src/app/reports/services/report.service';
import { ReportTransactionService } from '../report-transactions/report-transactions.service';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { Subscription } from 'rxjs';
import { ReportChartColumn } from '../../models/reports.model';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { UnitTypeEnum } from '../../models/unit-type-enum';

@Component({
  selector: 'app-report-house-villas',
  templateUrl: './report-house-villas.component.html',
  styleUrls: ['./report-house-villas.component.scss']
})
export class ReportHouseVillasComponent implements OnInit, OnChanges {

  @Input() params: any;
  @Input() multi_story_residential: boolean = false;
  UnitTypeEnum=UnitTypeEnum;
  houseVillasMenuEnum = HouseVillasMenuEnum;
  chartView = ReportsChartEnum;

  overViewMenu = HouseVillasReportConstant.overViewMenu;
  currentScroll: any = '';

  independentVillasColumns = HouseVillasReportConstant.independentVillasColumns;
  governmentalVillasColumns = HouseVillasReportConstant.governmentalVillasColumns;
  projectsColumns = HouseVillasReportConstant.projectsColumns;

  houseVillaChart: ReportChartColumn[] = [];

  independentVillasdata: any = [];
  independentVillasCount: number = 0;
  governmentalVillasdata: any = [];
  governmentalVillasCount: number = 0;
  projectsdata: any = [];
  projectsCount: number = 0;
  overViewSummary: any = null;
  graphData: any[] = [];


  showPropertyDetails: boolean = false;
  showProjectDetails: boolean = false;
  // showAreaDetails: boolean = false;
  independentVillasdataLoading = false;
  governmentalVillasdataLoading= false;
  projectsdataLoading = false;
  houseVillaChartLoading = false;
  houseVillaOverviewLoading = false;
  

  userInfo: any = 0;
  placeCode: string = '';
  CurrencyCode: string = '';
  propertyInfo: any = {};
  propertyDetails$ = new Subscription();
  isFirstTime = true;
  downloadLoading: any = {
    a: false,
    b: false,
    c: false
  };
  constructor(
    private sharedService: SharedService,
    private reportHouseVillasService: ReportHouseVillasService,
    private reportService: ReportService,
    private reportTransactionService: ReportTransactionService,
    private alertService: AlertService
  ) {
    this.sharedService.language$.subscribe(lang => {
      if (lang && !this.isFirstTime) {
        this.getReports();
      }
    })
  }

  menuChangeValue(menu: any) {
    this.currentScroll = menu.name;
    setTimeout(() => {
      this.scrollPoint(menu.name);
    })
  }

  scrollPoint(menu: string) {
    this.sharedService.scrollMenu(menu);
  }

  ngOnInit() {
    this.getReports();
    this.isFirstTime = false;
    this.houseVillaPropertyDetails();
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    this.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.reportHouseVillasService.projectsPayload.pageNumber = 0;
      this.reportHouseVillasService.independentPayload.pageNumber = 0;
      this.reportHouseVillasService.governmentalPayload.pageNumber = 0;
      this.propertyInfo = null
      this.showPropertyDetails = false;
      this.showProjectDetails = false;
      this.getReports();
    }
  }

  getReports() {
    this.getOverviewSummary();
    this.getGraph();
    this.getIndependentHouseVillas();
    this.getGovernorHouseVillas();
    this.getProjects();
  }

  getOverviewSummary() {
    this.houseVillaOverviewLoading = true;
    this.reportHouseVillasService.getOverviewSummary().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.overViewSummary = this.reportHouseVillasService.formatOverViewSummary(res.data)
            // if (this.overViewSummary?.untillDate) this.overViewSummary.untillDate = new Date(this.overViewSummary?.untillDate).getFullYear();
          } else {
            this.overViewSummary = null;
          }
          this.houseVillaOverviewLoading = false;
        },
        error: (error: any) => {
          this.overViewSummary = null;
          this.houseVillaOverviewLoading = false;
        }
      }
    )
  }
  /**
   * Graph Data Api
   */
  getGraph() {
    this.houseVillaChartLoading = true;
    this.reportHouseVillasService.getGraphSummary().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.graphData = res.data;
            if (this.graphData && this.graphData?.length > 0) {
              this.graphData.sort((a: any, b: any) => (Number(a.year) - Number(b.year)))
              this.houseVillaChart = [];
              this.graphData.map((data: any) => {
                let obj: any = {};
                if (data?.count > 0) {
                  obj = {
                    category: data.year.toString(),
                    value: data.count
                  }
                  this.houseVillaChart.push(obj);
                }

              })

            }
          } else {
            this.graphData = [];
          }
          this.houseVillaChartLoading = false;
        },
        error: (error: any) => {
          this.graphData = [];
          this.houseVillaChartLoading = false;
        }
      }
    )
  }

  getIndependentHouseVillas(year = null) {
    this.independentVillasdataLoading = true;
    this.reportHouseVillasService.getIndependentHouseVillas(this.reportHouseVillasService.independentPayload, year).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.independentVillasdata = res.data.data;
            this.independentVillasdata.map((e: any) => {
              e['neigh_name'] = this.sharedService.getNeighLabel(e['neigh_name']);
            })

            if(this.reportHouseVillasService.independentPayload.pageNumber == 0){
              this.independentVillasCount = res.data.count;
             }
          } else {
            this.independentVillasdata = [];
            this.independentVillasCount = 0;
          }
          this.independentVillasdataLoading = false;
        },
        error: (error: any) => {
          this.independentVillasdata = [];
          this.independentVillasCount = 0;
          this.independentVillasdataLoading = false;
        }
        
      }
    )
  }

  getGovernorHouseVillas(year = null) {
    this.governmentalVillasdataLoading = true;
    this.reportHouseVillasService.getGovernorHouseVillas(this.reportHouseVillasService.governmentalPayload, year).subscribe(
      {
        next: (res: any) => {

          if (res.status === 200) {
            this.governmentalVillasdata = res.data.data;
            this.governmentalVillasCount = res.data.count;
            this.governmentalVillasdata.map((e: any) => {
              e['neigh_name'] = this.sharedService.getNeighLabel(e['neigh_name']);
            })
          } else {
            this.governmentalVillasdata = [];
            this.governmentalVillasCount = 0;
          }
          this.governmentalVillasdataLoading = false;
        },
        error: (error: any) => {
          this.governmentalVillasdata = [];
          this.governmentalVillasCount = 0;
          this.governmentalVillasdataLoading = false;
        }
      }
    )
  }

  getProjects(year = null) {
    this.projectsdataLoading = true;
    this.reportHouseVillasService.getProjects(this.reportHouseVillasService.projectsPayload, year).subscribe(
      {
        next: (res: any) => {

          if (res.status === 200) {
            this.projectsdata = res.data.data;
            this.projectsCount = res.data.count;
            this.overViewMenu.map((e:any)=>{
              if(e.name == this.houseVillasMenuEnum.projects){
                e['count'] = this.reportService.formatInternationNum(this.projectsCount);
              }
            })
          } else {
            this.projectsdata = [];
            this.projectsCount = 0;
            if (this.reportHouseVillasService.projectUnitPayload.pageNumber == 0) {
              this.overViewMenu.map((e:any)=>{
                if(e.name ==this.houseVillasMenuEnum.projects){
                  e['count'] = 0;
                }
              })
            }
          }
          this.projectsdataLoading = false;
        },
        error: (error: any) => {
          this.projectsdata = [];
          this.projectsCount = 0;
          this.projectsdataLoading = false;
          if (this.reportHouseVillasService.projectUnitPayload.pageNumber == 0) {
            this.overViewMenu.map((e:any)=>{
              if(e.name ==this.houseVillasMenuEnum.projects){
                e['count'] = 0;
              }
            })
          }
        }
      }
    )
  }

  onPageEvent(e: any, report: any) {
    if (e.previousPageIndex !== e.pageIndex) {
      if (report === this.houseVillasMenuEnum.projects) {
        this.reportHouseVillasService.projectsPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getProjects();
      } else if (report === this.houseVillasMenuEnum.independent_villas) {
        this.reportHouseVillasService.independentPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getIndependentHouseVillas();
      } else {

        this.reportHouseVillasService.governmentalPayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getGovernorHouseVillas();
      }
    }

  }

  sortData(event: any, report: any) {
    let sort = {
      column: event.active,
      order: event.direction
    }
    if (report === this.houseVillasMenuEnum.projects) {
      this.reportHouseVillasService.projectsPayload.pageNumber = 0;
      this.reportHouseVillasService.projectsPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getProjects();
    } else if (report === this.houseVillasMenuEnum.independent_villas) {
      this.reportHouseVillasService.independentPayload.pageNumber = 0;
      this.reportHouseVillasService.independentPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getIndependentHouseVillas();
    } else {
      this.reportHouseVillasService.governmentalPayload.pageNumber = 0;
      this.reportHouseVillasService.governmentalPayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getGovernorHouseVillas();
    }
  }
  exploreProperty() {
    this.reportService.transactionPropertyDetails.next(true);
  }
  houseVillaPropertyDetails() {
    this.propertyDetails$ = this.reportService.transactionPropertyDetails.subscribe((data) => {
      // if (data && data.proj_uid) {
      //   this.showProjectDetails = true;
      // } else {
      //   this.showProjectDetails = false;
      // }

      if (data && data.property_uid) {
        this.showPropertyDetails = true;
      } else {
        this.showPropertyDetails = false;
      }
      // if (data && data.neigh_uid) {
      //   this.showAreaDetails = true;
      // } else {
      //   this.showAreaDetails = false;
      // }


      if (data) {
        this.propertyInfo = data;
      } else {
        this.propertyInfo = {};
      }
    });
  }

  downloadExcel(event: any, type: string, key: any) {
    this.downloadHouseVillaReport(type, 'house_and_villa', key);
  }

  selectedYear(data: any){
    if(data.reportType === 'independent'){
      this.reportHouseVillasService.independentPayload.pageNumber = 0;
      this.reportHouseVillasService.independentPayload.year = data.year;
      this.getIndependentHouseVillas(data.year);
    } else if(data.reportType === 'governor') {
      this.reportHouseVillasService.governmentalPayload.pageNumber = 0;
      this.reportHouseVillasService.governmentalPayload.year = data.year;
      this.getGovernorHouseVillas(data.year);
    } else {
      this.reportHouseVillasService.projectsPayload.pageNumber = 0;
      this.reportHouseVillasService.projectsPayload.year = data.year;
      this.getProjects(data.year);
    }
  }


  downloadHouseVillaReport(type: string, module_name: string, key: any) {
    this.downloadLoading[key] = true;
    this.reportHouseVillasService.downloadHouseVillaReport(type, this.userInfo?.user_id).subscribe(
      {
        next: (res: any) => {
          this.downloadLoading[key] = false;
          if (res.status === 200) {
            const downloadUrl = `${this.reportTransactionService.downloadExcelUrl()}?file_name=${res.data}&city_code=${this.placeCode.toLowerCase()}&module_name=${module_name}`;
            this.sharedService.downloadExcelUrl(downloadUrl);
          } else {
            this.alertService.warn(res.message)
          }
        },
        error: (error: any) => {
          this.downloadLoading[key] = false;
        }
      }
    )
  }

  ngOnDestroy() {
    this.propertyDetails$.unsubscribe()
  }
}
