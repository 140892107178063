<div class=" detail-info" >
    <h2 class="detail-heading">{{'reports.builtup_information' | translate}} <i class="contant-icon" matTooltipPosition="above" matTooltipClass="custom-tooltip" matTooltip="This section presents the individual value and share of built-up area for various property categories."></i></h2>
    <div class="built-up-info">
        <div class="legendbox">
            <span *ngIf="totalBuildUpinfo?.total_built_up_area">{{'reports.totalBuiltUparea' | translate}}: {{ totalBuildUpinfo?.total_built_up_area  | number : "1.0-1" }} sqm <span>({{ totalBuildUpinfo?.builtup_percentage | number : "1.0-1" }}%)</span></span>
            <div id="legenddiv"></div>
        </div>
        <div class="chartbox">
            <div id="chartdiv"></div>
        </div>
    </div>
</div>
