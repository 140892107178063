import {
  Component,
  OnChanges,
  SimpleChanges,
  NgZone,
  PLATFORM_ID,
  Inject,
  OnInit,
  Input,
} from '@angular/core';

import { isPlatformBrowser } from '@angular/common';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { ReportPropertyDetailService } from '../../report-property-detail.service';
import { SharedService } from 'src/app/common/services/shared.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-build-up-info',
  templateUrl: './build-up-info.component.html',
  styleUrls: ['./build-up-info.component.scss'],
})
export class BuildUpInfoComponent implements OnChanges {

  @Input() propertyInfo: any;

  buildUpinfo: any = null;
  totalBuildUpinfo: any = null;
  isFirstTime : boolean = true;

  private root!: am5.Root;
  private legendRoot!: am5.Root;
  unitType = GlobalContants.keySymbolContants.squareMeterUnit;
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private zone: NgZone,
    private reportPropertyDetailService: ReportPropertyDetailService,
    private sharedService: SharedService,
    private translate: TranslateService
  ) {
    this.sharedService.language$.subscribe(lang => {
      if (lang && !this.isFirstTime) {
        this.getBuiltInformation();
      }
    })
  }

  ngOnInit() {
    this.isFirstTime = false;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.root) {
      this.root.dispose();
    }
    if (this.legendRoot) {
      this.legendRoot.dispose();
    }
    this.getBuiltInformation();

  }

  getBuiltInformation() {

    this.reportPropertyDetailService.getBuiltInformation().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200 && res.data) {
            this.buildUpinfo=[];
            let buildup: any[] = res.data.list_builtup_info;
            if(buildup?.length>0){
              buildup.sort((a: any, b: any) => (Number(a.sort_order) - Number(b.sort_order)))
              buildup.map((e) => {
                if(e.value){
                  this.buildUpinfo.push({
                    category : e.name,
                    value: e.value
                  })
                }
              });
              if(this.buildUpinfo.length>0)this.pieChart();
            }else{
              this.buildUpinfo = null;
            }
            if(Object.keys(res.data.total_built_up_info).length>0){
              this.totalBuildUpinfo = res.data.total_built_up_info;
            }else{
              this.totalBuildUpinfo = null;
            }

           } else {
             this.buildUpinfo = null;
             this.totalBuildUpinfo = null;
           }

        }, error:(err)=>{
          this.buildUpinfo = null;
          this.totalBuildUpinfo = null;
        }
      }
    )
  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  pieChart() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
      let root = am5.Root.new('chartdiv');

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          layout: root.verticalLayout,
          radius: am5.percent(80),

        })
      );

      // Define data
      var data = this.buildUpinfo;
      console.log("pie chart data",data)
      // Create series
      var series = chart.series.push(
        am5percent.PieSeries.new(root, {
          name: 'Series',
          valueField: 'value',
          categoryField: 'category',
           legendLabelText: "[bold]{category}[/]",
          legendValueText: ": {value}" + (this.translate.instant('sqm_unit')) + "[/]",
          alignLabels: false
        })
      );
      series.set("colors", am5.ColorSet.new(root, {
        colors: [
          am5.color(0xC3D5F8),
          am5.color(0x2372F0),
          am5.color(0xEE7435),
          am5.color(0x57AC52),
          am5.color(0x664FD9),
          am5.color(0x87ACF1),
        ]
      }))
      series.data.setAll(data);
      // series.labels.template.setAll({
      //   text: "[bold]{valuePercentTotal.formatNumber('0.00')}%",
      //   fontSize: 9,
      //   fill: am5.color(0xFFFFFF),
      //   radius: -60
      // });
      series.ticks.template.set('forceHidden', true);
      series.labels.template.set('forceHidden', true);
      series.slices.template.set('tooltipText', '{category}: {valuePercentTotal.formatNumber("0.00")}%');



      var legendRoot = am5.Root.new('legenddiv');

      legendRoot.setThemes([am5themes_Animated.new(root)]);

      var legend = legendRoot.container.children.push(
        am5.Legend.new(legendRoot, {
          width: am5.percent(100),
          centerX: am5.percent(50),
          x: am5.percent(50),
          layout: am5.GridLayout.new(root, {
            maxColumns: 2,
            fixedWidthGrid: true,
          }),
        })
      );

      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
      });

      legend.markers.template.setAll({
        width: 11,
        height: 11,
      });
      legend.labels.template.setAll({
        fontSize: 11,
        maxWidth: 170,
        oversizedBehavior: 'wrap',
      });
      legend.valueLabels.template.setAll({
        fontSize: 11,
      });

      legend.data.setAll(series.dataItems);


      this.root = root;
      this.legendRoot= legendRoot;
    });
  }
}
