<div class="city-model-container">
    <div class="header-close" *ngIf="currentCitySlug">
        <mat-icon (click) = closeDialog() class="close-icon">close</mat-icon>
      </div>
    <div class="model-content">
        <img src="assets/images/city_icon/logo.png" alt="Estater Markets">
        <h2>{{'welcomeToEstaterMarkets' | translate}}</h2>
        <p>{{'yourGatewayToTheMost' | translate}}</p>
    </div>
    <div class="coty-model-inner-container">
        <h3 *ngIf="!currentCitySlug">{{'chooseCityToBegin' | translate}}</h3>
        <h3 *ngIf="currentCitySlug">{{'chooseCountryCity' | translate}}</h3>
        <div class="matselect_container">
        <mat-select class="form-control matselect_city" (selectionChange)="onCitychange($event)"
            [formControl]="citySelectForm" placeholder="{{'Select a city' | translate}}">
            <mat-select-trigger>
                {{(selectedCityName | lowercase) | translate}}
            </mat-select-trigger>
            <mat-option class="search-sticky">
                <mat-icon>search</mat-icon>
                <ngx-mat-select-search [formControl]="searchFilter"
                placeholderLabel="{{'reports.search' | translate}}"></ngx-mat-select-search>
            </mat-option>
                <mat-option [ngClass]="{'hide_list_item': cities?.hideItem}" *ngFor="let cities of cityList" [value]="cities.slug" class="city_select_option" [disabled]="cities.is_future_city">
                    <span class="list"><img class="countrycons" src="{{ cities.image }}" alt="{{ cities.place_name }}" width="20" onerror="this.src='assets/images/city_icon/default-flag.png'"> {{cities?.parent_name?(cities.parent_name | translate)+', ':''}}{{(cities.place_name | lowercase) | translate}}</span>
                    <span class="city_badge" [ngClass]="{'new_city': cities.new_city_value, 'future_city': cities.is_future_city}"> {{cities.new_city_value? ('newcity' | translate):cities.is_future_city?('futurecity' | translate):''}}</span>
                </mat-option>
                <mat-option *ngIf="cityList?.length === 0 || noMatchecItem" disabled class="no-results_option">
                    <div class="no-results">
                        <img src="assets/images/no_data_icon.svg" alt="">
                      <span>{{ 'reports.no-result-found' | translate }}</span><br />
                    </div>
                  </mat-option>
            </mat-select>
        </div>
        <h3>{{'featuredCities' | translate}}</h3>
        <div id="loaderDiv" *ngIf="showLoader; else cityLoaded">
            <app-skeleton-loader [loaderSize]="2" [reportType]="8"></app-skeleton-loader>
        </div>
        <ng-template #cityLoaded>
            <div class="city-model">
                <owl-carousel-o [options]="slideConfig1" class="city-carouse">
                    <ng-container *ngIf="windowWidth > 700; else mobileView">
                        <ng-template carouselSlide *ngFor="let cities of cityListStored; let l = last">
                            <div class="citylist_item">
                                <div>
                                    <div class="city-lists" [ngClass]="{'active' : selectedCitySlug == cities.slug, 'disabled_city' : cities.is_future_city}" [attr.data-component]="cities.slug+'-city'" (click)="onCitychange(cities, true)">
                                        <img class="countrycons" src="{{ cities.image }}" alt="{{ cities.place_name }}" onerror="this.src='assets/images/city_icon/default-flag.png';">
                                        <span title="{{(cities.place_name | lowercase) | translate}}">{{(cities.place_name | lowercase) | translate}}</span>
                                    </div>
                                    <span class="city_badge" [ngClass]="{'new_city': cities.new_city_value, 'future_city': cities.is_future_city}"> {{cities.new_city_value? ('newcity' | translate):cities.is_future_city?('futurecity' | translate):''}}</span>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                    <ng-template #mobileView>
                        <ng-template carouselSlide *ngFor="let citiesitem of groupedItems; let l = last">
                            <div class="citylist_item" *ngFor="let cities of citiesitem">
                                <div>
                                    <div class="city-lists" [ngClass]="{'active' : selectedCitySlug == cities.slug, 'disabled_city' : cities.is_future_city}" [attr.data-component]="cities.slug+'-city'" (click)="onCitychange(cities, true)">
                                        <img class="countrycons" src="{{ cities.image }}" alt="{{ cities.place_name }}" onerror="this.src='assets/images/city_icon/default-flag.png';">
                                        <span title="{{(cities.place_name | lowercase) | translate}}">{{(cities.place_name | lowercase) | translate}}</span>
                                    </div>
                                    <span class="city_badge" [ngClass]="{'new_city': cities.new_city_value, 'future_city': cities.is_future_city}"> {{cities.new_city_value? ('newcity' | translate):cities.is_future_city?('futurecity' | translate):''}}</span>
                                </div>
                            </div>
                        </ng-template>
                    </ng-template>
                </owl-carousel-o>
            </div>
            <div class="action_btn">
                <button *ngIf="currentCitySlug" class="btn-default m-1" (click)="closeDialog()">{{"User.Cancel" | translate}}</button>
                <button class="btn-secondry m-1" (click)="citySelect()" type="button" [disabled]="!selectedCitySlug">{{'reports.apply'|translate}}</button>
            </div>
        </ng-template>
    </div>
</div>
