import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';

import { HeaderComponent } from './components/header/header.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { I18nModule } from '../i18n/i18n.module';
import { CityModelComponent } from './components/city-model/city-model.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { RouterModule } from '@angular/router';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { TableGridComponent } from './components/table-grid/table-grid.component';
import { FolderTranslatePipe, LangTranslatePipe } from './pipes/translation.pipe';
import { AlertComponent } from './components/alert/alert.component';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { MatTooltipModule } from "@angular/material/tooltip";
import { ConfirmDialogBoxComponent } from './components/confirm-dialog-box/confirm-dialog-box.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { MatSelectModule } from '@angular/material/select';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ListDialogBoxComponent } from './components/list-dialog-box/list-dialog-box.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SaveFilterComponent } from './components/save-filter/save-filter.component';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { CardComponent } from './components/card/card.component';
import { SaveAsFilterComponent } from './components/save-as-filter/save-as-filter.component';
import { SavePolygonComponent } from './components/save-polygon/save-polygon.component';
import { MatInputModule } from '@angular/material/input';
import { OuterHeaderComponent } from './components/outer-header/outer-header.component';
import { BannerComponent } from './components/banner/banner.component';
import { productFruits } from 'product-fruits';
import { RequestForDemoConfirmDialogComponent } from './components/request-for-demo-confirm-dialog/request-for-demo-confirm-dialog.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { DropdownRadioSelectionComponent } from './components/dropdown-radio-selection/dropdown-radio-selection.component';
import { MatRadioModule } from '@angular/material/radio';
import { ChildBreadcumComponent } from './components/child-breadcum';

@NgModule({
    declarations:[
        HeaderComponent,
        SideBarComponent,
        CityModelComponent,
        SpinnerComponent,
        SearchBarComponent,
        TableGridComponent,
        LangTranslatePipe,
        AlertComponent,
        SearchFilterPipe,
        ConfirmDialogBoxComponent,
        FolderTranslatePipe,
        ContactUsComponent,
        ProgressBarComponent,
        ListDialogBoxComponent,
        SafeHtmlPipe,
        SaveFilterComponent,
        SkeletonLoaderComponent,
        CardComponent,
        SaveAsFilterComponent,
        SavePolygonComponent,
        OuterHeaderComponent,
        BannerComponent,
        RequestForDemoConfirmDialogComponent,
        DropdownRadioSelectionComponent,
        ChildBreadcumComponent
    ],
    imports:[
        CommonModule,
        I18nModule,
        HttpClientModule,
        MatIconModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatFormFieldModule,
        NgxMatIntlTelInputComponent,
        MatSelectModule,
        MatPaginatorModule,
        MatInputModule,
        MatSidenavModule,
        MatRadioModule
    ],
    exports: [
        HeaderComponent,
        SideBarComponent,
        CityModelComponent,
        SpinnerComponent,
        SearchBarComponent,
        LangTranslatePipe,
        SearchFilterPipe,
        AlertComponent,
        TableGridComponent,
        FolderTranslatePipe,
        ProgressBarComponent,
        SafeHtmlPipe,
        SkeletonLoaderComponent,
        CardComponent,
        OuterHeaderComponent,
        BannerComponent,
        DropdownRadioSelectionComponent,
        ChildBreadcumComponent
    ]
})

export class SharedModule {}
