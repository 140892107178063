<div class="category-list" (click)="$event.stopPropagation()" [ngClass]="{'category-list-ar': currentLang === 'ar'}">
    <div class="category">
        <div class="report-type" *ngFor="let objectives of reportStudiesData; let i = index" (click)="selectStudies(objectives)" [ngClass]="{'active': selectedObjId === objectives.obj_id}" [attr.data-component]="i+'-report-group'">
            <div class="custom-tootip"  *ngIf="showDescription == objectives.obj_id">
                <span [innerHTML]="objectives.obj_description | langtranslate"></span>
            </div>
            <div class="report-icon">
              <div *ngIf="objectives.obj_name.toLowerCase()=='catchment_or_sector_analysis'" class="img-icon d-flex">
                <img src="../../../../assets/images/catchment_sector_analysis.svg" alt="">
              </div>
            </div>
            <div class="report-info">
                <div class="list" >
                    <h3 matTooltip="{{ objectives.obj_label | langtranslate }}" [matTooltipPosition]="'above'">{{ objectives.obj_label | langtranslate }}</h3>
                    <span>
                        <mat-icon *ngIf="objectives.obj_description" (click)="$event.stopPropagation()"  #menuTrigger="matMenuTrigger"  mat-button [matMenuTriggerFor]="menu"> error_outline
                        </mat-icon>
                        <mat-menu #menu="matMenu" class="category-tooltip">
                            <p (click)="$event.stopPropagation();"  [innerHTML]="objectives.obj_description | langtranslate"></p>
                          </mat-menu>
                    </span>
                </div>
            <p [innerHTML]="objectives.obj_description | langtranslate"></p> 
            </div>
        </div>
    </div>

    <div class="category" *ngIf="studiesList.length > 0">
        <ng-container *ngFor="let studies of studiesList;  let i = index">
            <div class="report-type" (click)="selectQueries(studies)" [attr.data-component]="i+'-report-type'" [ngClass]="{'active': selectedStudiesId === studies.study_id}" *ngIf="studies.active">
                <div class="report-icon">
                    <div  class="img-icon d-flex" *ngIf="studies?.study_icon">
                        <img src= "{{studies?.study_icon[1]}}">
                        
                    </div>
                </div>
                <div class="report-info">
                    <div class="list">
                        <h3 matTooltip="{{ studies.study_label | langtranslate }}" [matTooltipPosition]="'above'">{{ studies.study_label | langtranslate }}</h3>
                        <mat-icon  #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation()"  mat-button [matMenuTriggerFor]="study" *ngIf="studies.study_description"> error_outline
                        </mat-icon>
                        <mat-menu #study="matMenu" class="category-tooltip">
                            <p (click)="$event.stopPropagation();" [innerHTML]="studies.study_description | langtranslate"></p>
                        </mat-menu>
                    </div>
                    <p *ngIf="studies.study_description" [innerHTML]="studies.study_description | langtranslate"></p>

                </div>
            </div>
        </ng-container>
    </div>
    <div class="category" *ngIf="queriesList.length > 0">
        <div class="report-type hide" *ngFor="let queries of queriesList; let i = index" (click)="selectReport(queries)" [ngClass]="{'active': selectedReportId === queries.report_id}" [attr.data-component]="i+'-report'">
            <div class="report-icon" [ngClass]="{'report-lock':!queries.active }">
                <mat-icon class="material-icons-outlined" *ngIf="!queries.active">lock</mat-icon>
                <div class="img-icon d-flex"  *ngIf="queries.active && queries.icon">
                  <img src= "{{queries?.icon[1]}}">
                </div>
              </div>
            <div class="report-info" [ngClass]="{'report-info-lock':!queries.active }">
                <div class="list">
                    <h3  matTooltip="{{ queries.report_label | langtranslate }}" [matTooltipPosition]="'above'">{{ queries.report_label | langtranslate }}</h3>
                    <mat-icon *ngIf="queries.report_description"   #menuTrigger="matMenuTrigger"  mat-button [matMenuTriggerFor]="queriesMenu" (click)="$event.stopPropagation()" > error_outline
                    </mat-icon>
                    <mat-menu #queriesMenu="matMenu" class="category-tooltip">
                        <p (click)="$event.stopPropagation();"  [innerHTML]="queries.report_description | langtranslate"></p>
                      </mat-menu>
                    <span class="unlock" (click)="submitSubscription(queries)" *ngIf="!queries.active">
                        <span>{{"reports.unlock-here" | translate}}</span>
                        <span class="unlock-loader" *ngIf="unloack_report_id == queries.report_id"><app-progress-bar></app-progress-bar></span>
                     </span>

                </div>
                <p *ngIf="queries.report_description" [innerHTML]="queries.report_description | langtranslate"></p>
            </div>
        </div>

    </div>

</div>
