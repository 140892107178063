import { Injectable } from '@angular/core';

import { ApiService } from '../../../../../common/services/api.service';
import { environment } from 'src/environments/environment';
import { apiEndPoints } from '../../../../../common/constants/api-endpointsconstant';
import { ReportService } from 'src/app/reports/services/report.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class WarehouseInventoryService {

  constructor(
    private apiServive: ApiService,
    private reportService: ReportService
  ) { }

  neighbourhoodPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }

  propertiesPayload: any = {
    pageSize: 20,
    pageNumber: 0,
    sort_order: null,
    year: null
  }
  averageRentRate = new Map();
  occupancyRate = new Map();

  getOverviewSummary() {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.warehouseInventory}${changeParams}&report_type='overview_report'`;
    return this.apiServive.get(url, false);
  }

  getRetailRentalRentrateReport() {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.retailRentalRentrateReport}${changeParams}`;
    return this.apiServive.get(url, false);
  }

  getOverviewSubCategory() {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    const url: string = `${environment.miUrl}${apiEndPoints.retailRentalOverviewSubCategory}${changeParams}`;
    return this.apiServive.get(url, false);
  }



  getNeighbourhood(payload: any) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.neighbourhoodPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.warehouseInventory}${changeParams}&report_type='neighborhood_report'&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    return this.apiServive.get(url, false);
  }

  getProperties(payload: any) {
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.propertiesPayload.year || this.reportService.yearMaxValue(queryParams.year);
    }
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let offset = payload.pageNumber || 0
    let url: string = `${environment.miUrl}${apiEndPoints.warehouseInventory}${changeParams}&report_type='properties_repor'&limit=${payload?.pageSize}&offset=${offset}`;
    if (payload.sort_order) {
      url += `&sort_order=${payload.sort_order}`;
    }
    if(offset == 0){
      url += "&is_count=true"
    }
    return this.apiServive.get(url, false);
  }

  downloadTransactionReport(type: string, user_id: number) {
    let url: string = `${environment.miUrl}${apiEndPoints.downloadTransactionReport}${this.reportService.filterQueryParams}&type=${type}&user_id=${user_id}`;
    return this.apiServive.get(url);
  }

  downloadExcelUrl() {
    return `${environment.miUrl}${apiEndPoints.downloadExcel}`;
  }

  formatOverViewSummary(data: any) {
    data['totalLandCovered'] = this.reportService.calcPercentage(data.total_land_covered.prev_total_land_covered, data.total_land_covered.total_land_covered);
    data['totalOccupacy_rate'] = this.reportService.calcPercentage(data.occupacy_rate.prev_total_warehouses, data.occupacy_rate.total_warehouses);
    data['totalBuiltupArea'] = this.reportService.calcPercentage(data.total_builtup_area.prev_total_builtup_area, data.total_builtup_area.total_builtup_area);
    data['untillDate'] = data.occupacy_rate.max_date ? moment.utc(data.occupacy_rate.max_date).local().format('DD MMM YYYY') : null;
    return data;
  }


  downloadRetailRentalReport(type:string, user_id:any){
    const url: string = `${environment.miUrl}${apiEndPoints.reportDownload}${this.reportService.filterQueryParams}&type=${type}&user_id=${user_id}`;
    return this.apiServive.get(url);
  }

}
