<div id="map" #mapIdentifier [ngClass]="classname">
    <div class="map-spinner" *ngIf="map_spinner">
        <mat-spinner class="background" mode="determinate" value="100">
        </mat-spinner>
        <mat-spinner class="mat-spin"></mat-spinner>
    </div>
    <div class="draw-toolbar"
        [ngStyle]="{'margin': draw_btn_toggle ? '12px 10px':'','top': (classname == 'onlyMapView') ? '60px':''} " *ngIf="draw_btn_toggle">
        
            <button [disabled]="reportService.getLockReport()" *ngIf="reportName?.toLocaleLowerCase() !== 'population'" (click)="drawPolygon();" id="draw_polybtn"
            [ngClass]="{ 'rmv-btn': polygon_create, 'draw-btn':!polygon_create}">
                <img *ngIf="reportService.getLockReport()" class="lock" src="assets/images/Lock-disable.png" alt="">
                <span *ngIf="!polygon_create">{{"map.draw_catchment" | translate}}</span>
                <span *ngIf="polygon_create && (polygon_WKT == null || polygon_WKT == undefined)">{{"map.cancel_catchment" |
                    translate}}</span>
                <span *ngIf="polygon_create && (polygon_WKT != null && polygon_WKT.length > 0)">{{"map.remove_catchment" |
                    translate}}</span>
            </button>
        
            <div class="breadcums breadcum-filters ploygon-grp" *ngIf="drawnPolygons && drawnPolygons.length != 0">
                <div class="d-flex">
                    <!-- <mat-chip-set #chipGrid aria-label="selection1"> -->
                    <ng-container *ngFor="let filter of drawnPolygons;let i = index">
                        <mat-chip-row class="filter-matChip mr-3" [ngClass]="{'darw-chipone': i === 0, 'darw-chiptwo': i === 1, 'darw-chipthree': i === 2, 'darw-chipfour': i === 3}">
                            C{{i+1}}
                            <!-- {{ drawnPolygons[i].properties?.label ? drawnPolygons[i].properties?.label : 'C'+(i+1) }} -->
                            <button matChipRemove (click)="polygonWKTRemove(filter)" [disabled]="polygon_chip">
                                <mat-icon class="remove-filter">close</mat-icon>
                            </button>
                        </mat-chip-row>
                    </ng-container>
                    <mat-chip-row class="filter-matChip multipoly-add-btn" *ngIf="4 > drawnPolygons.length" [disabled]="polygon_chip">
                        <button matChipRemove (click)="drawPolygon('add')">
                            <mat-icon class="remove-filter">add</mat-icon>
                        </button>
                    </mat-chip-row>
                    <!-- </mat-chip-set> -->
                </div>
            </div>
    
        <div class="tenant-names d-flex align-items-center" *ngIf="TenantName">
            {{'reports.show_on_map' | translate}}:<button><span>{{TenantName}}</span> <span class="icon" (click)="removeCustomMaker(true)"><mat-icon class="remove-filter">close</mat-icon></span></button>
        </div>
    </div>
    <div class="legendContainer map_legend" *ngIf="legendToggle" [ngStyle]="{'direction': current_lang == 'ar' ? 'ltr':''} ">
        <span class="legendContainer_header"><span>{{'Map Legend' | translate}}
                ({{layerselected}})</span> <span class="legend_close" (click)="collapseLegend()"><img
                    class="legend_close" src="../../../../assets/images/cross.svg" /></span></span>
        <div *ngFor="let main_legend of legend_data;let idx = index;">
            <span class="mapLegend" style="font-weight: 500;">{{returnVal(main_legend.label,'text',main_legend)}}</span>
            <div *ngFor="let legend of main_legend.data;let idx = index; let isLast=last;" class="mapLegend">
                <div *ngIf="main_legend.type != 'number'" style="display: flex;"><img
                        src="{{returnVal(main_legend.data[idx],'src',main_legend)}}" alt="legend" width="20"
                        height="20">{{returnVal(main_legend.data[idx],'text',main_legend)}}
                </div>
                <div *ngIf="main_legend.type == 'number' && !isLast" style="display: flex;"><img
                        src="{{returnVal(main_legend.data[idx]+'-'+main_legend.data[idx+1],'src',main_legend)}}"
                        alt="legend" width="20" height="20">{{returnVal(main_legend.data[idx],'text',main_legend)}}
                    {{'sqm_unit' | translate}}. -
                    {{returnVal(main_legend.data[idx+1],'text',main_legend)}} {{'sqm_unit' |translate}}.</div>
                <div *ngIf="main_legend.type == 'number' && isLast" style="display: flex;"><img
                        src="{{returnVal('>'+main_legend.data[idx],'src',main_legend)}}" alt="legend" width="20"
                        height="20">{{returnVal(main_legend.data[idx],'text',main_legend)}}
                    {{'sqm_unit' | translate}}. +</div>
            </div>
        </div>
    </div>
</div>
<ng-container  *ngIf="currentReportView === viewType.gridView || currentReportView === viewType.mapGridView">
<div class="mapLayer-panel" *ngIf="propertyinfoToggle">
    <app-map-property-details [propertyInfo]="selected_property"></app-map-property-details>
</div>
<ng-container *ngIf="showAreaToggle">
    <div class="mapLayer-panel" [ngClass]="{'mapLayer-fullpanel': showAreaToggle && currentReportView === viewType.gridView }"><app-report-neigh-area-details [propertyInfo]="neightInfo"></app-report-neigh-area-details>
    </div>
</ng-container>

<ng-container *ngIf="showProjectToggle">
    <div class="mapLayer-panel" [ngClass]="{'mapLayer-fullpanel': showProjectToggle && currentReportView === viewType.gridView }" *ngIf="reportName !== 'inventory'">

        <app-report-housevilla-project-details [propertyInfo]="projecttInfovar"></app-report-housevilla-project-details>
    </div>
    <div class="mapLayer-panel"  [ngClass]="{'mapLayer-fullpanel': showProjectToggle && currentReportView === viewType.gridView }"  *ngIf="reportName == 'inventory'">

        <app-report-inventory-project-details [propertyInfo]="projecttInfovar"></app-report-inventory-project-details>
    </div>
</ng-container>
</ng-container>
