<div class="reports-dashboard">
    <app-report-breadcum [filterList]="filter_list"></app-report-breadcum>
    <div class="report-map report-data" [ngClass]="{'disableMapView' : currentReportView === viewType.gridView && !projectReportView, 'onlyMapView' : currentReportView === viewType.mapView && !projectReportView}">
        <app-reports-map-view [classname]="(currentReportView === viewType.mapView)?'onlyMapView':'disableMapView'"></app-reports-map-view>
    </div>

    <div class="report-grid report-data" *ngIf="currentReportView === viewType.gridView || currentReportView === viewType.mapGridView" [ngClass]="{'onlyGridView' : currentReportView === viewType.gridView && !projectReportView}">
        <app-report-grid [reportData]="currentReportData"></app-report-grid>
    </div>
    <div class="expand-panel" matTooltip="{{'User.expand' | translate}}" *ngIf="!currentToggle" (click)="toggleReportSide(true)" [ngClass]="{'ar-expand': currentLang === 'ar'}">
        <mat-icon>sort</mat-icon>
    </div>

    <div class="map-filter actions" [ngClass]="{'ar-actions': currentLang === 'ar'}" *ngIf="isReport">
        <button *ngIf="is_save_allow" class="map-filter_icns" [matTooltip]="is_saved? translate.instant('reports.saved'):translate.instant('reports.save')" [class.is_saved]="is_saved" (click)="openDailog('save')"  [matTooltipPosition]="currentLang === 'ar' ? 'after':'before'"> <mat-icon class="" svgIcon="saveIcon"></mat-icon></button>
        <!-- <button class="map-filter_icns" matTooltip="{{'reports.share' | translate}}" [matTooltipPosition]="currentLang === 'ar' ? 'after':'before'"> <mat-icon class="" svgIcon="shareIcon"></mat-icon></button>
         -->
         <!-- <button *ngIf="downloadLoader" class="map-filter_icns"><mat-spinner class="download-spinner"></mat-spinner></button>
         <ng-container *ngIf="!downloadLoader"> -->
            <button *ngIf="(studyName?.toLocaleLowerCase() === 'apartment' && (reportName?.toLocaleLowerCase() === 'occupancy_and_lease' || reportName?.toLocaleLowerCase() === 'inventory_in_all_the_neighborhoods' || reportName?.toLocaleLowerCase() === 'units' || reportName?.toLocaleLowerCase() === 'property_profiling')) || studyName?.toLocaleLowerCase() === 'retail'" (click)="downloadInvestmentReport('investment')" class="map-filter_icns" matTooltip="{{'reports.download'| translate}}"  [matTooltipPosition]="currentLang === 'ar' ? 'after':'before'"> <mat-icon class="" svgIcon="downloadIcon"></mat-icon></button>
        <!-- </ng-container> -->
    </div>
    <app-report-map-legend></app-report-map-legend>
</div>
