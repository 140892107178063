import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
/**
 * This component is used as dialog-box if we want to show list of content.
 */
@Component({
  selector: 'app-list-dialog-box',
  templateUrl: './list-dialog-box.component.html',
  styleUrls: ['./list-dialog-box.component.scss']
})
export class ListDialogBoxComponent {
  selectedFolder:string = "";
  message: any;
  class='';
  type:string='';
  constructor(
    public dialogRef: MatDialogRef<ListDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public info: any) {
    this.message = this.info.message;
    this.class = this.info.class
    this.type= this.info.type?this.info.type:'';
   }

  selectFolder(id:string) {
    this.selectedFolder = id;
  }

  confirmDialogBox() {
    this.dialogRef.close(this.selectedFolder);
  }

}
