<div class="report-container retail-rental">


    <app-report-sub-menu [menuList]="overViewMenu" (menuChangeValue)="menuChangeValue($event)"></app-report-sub-menu>
    <ng-container *ngIf="overViewLoading">
        <app-skeleton-loader [reportType]="chartView.overView"></app-skeleton-loader>
    </ng-container>
    <div class="report-tabsection" id="{{ retailRentalMenuEnum.overview}}"
        [ngClass]="{ 'scroll': currentScroll === retailRentalMenuEnum.overview}">
        <div class="report-overview" *ngIf="!overViewLoading">
            <app-overview-card
                [card_title]="placeCode  == RYDCHECK ? 'reports.AnnuallyAverageRent' : 'reports.averageRentRate'"
                [total_current]="overViewSummary?.average_rate.avg_rent" [untillDate]="overViewSummary?.untillDate"
                [total_prev]="overViewSummary?.average_rate.prev_avg_rent"
                [total_percentage]="overViewSummary?.totalAverage_rate" [uniType]="UnitTypeEnum.currencywithsqm"
                [graphData]="{data: overviewAverageRate, type: 'averageRentRate', loader: graphLoader}"></app-overview-card>

            <app-overview-card [card_title]="'reports.occupancyRate'"
                [total_current]="overViewSummary?.occupacy_rate.occupacy_rate"
                [untillDate]="overViewSummary?.untillDate"
                [total_prev]="overViewSummary?.occupacy_rate.prev_occupacy_rate"
                [total_percentage]="overViewSummary?.totalOccupacy_rate" [uniType]="UnitTypeEnum.percentage"
                [graphData]="{data: overviewOccupancy, type: 'occupancyRate', loader: graphLoader}"></app-overview-card>



        </div>
        <div class="report-model report-rentrate" *ngIf="retailRentalRentrateReport">
            <div class="report-model-header">
                <div class="info">
                    <h3>{{'reports.rental_price_in' | translate}} <span class="text-capitalize">{{(placeName |
                            lowercase) | translate}}</span> <!-- {{'reports.past_years' | translate}} --> ({{CurrencyCode |
                        translate}}/{{'reports.sqm_unit' | translate}})
                    </h3>
                </div>

                <button class="btn-select-grade" [matMenuTriggerFor]="menu"
                    *ngIf="!retailRentalChartLoading && (retailRentalChartReport | json) != '{}'">
                    <div class="setext">
                        <ng-container *ngFor="let name of selectedChartValue; let l = last">{{name}}<span *ngIf="!l"
                                style="padding-right: 2px;">,</span></ng-container>
                    </div>
                    <span class="morecount" *ngIf="selectedChartValue.length > 1">{{moreText}}</span>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>

                <mat-menu #menu="matMenu" [xPosition]="langName === 'ar' ? 'after' : 'before'"
                    class="grades-menu directionLtr">
                    <div (click)="$event.stopPropagation()">
                        <h5>{{'reports.choose_maximum_options' | translate}}</h5>
                        <ng-container *ngFor="let category of getReportKeys()">
                            <mat-checkbox (change)="selectChart($event, category)"
                                [checked]="selectedChartValue.includes(category)">{{category}}</mat-checkbox>
                        </ng-container>
                    </div>
                </mat-menu>
            </div>
            <ng-container *ngIf="retailRentalChartLoading">
                <app-skeleton-loader [reportType]="chartView.barChart"></app-skeleton-loader>
            </ng-container>
            <ng-container *ngIf="!retailRentalChartLoading">
                <div class="multiBarChart d-flex" *ngIf="(retailRentalChartReport | json) != '{}'; else noDataMessage">
                    <ng-container *ngFor="let chart of retailRentalChartReport | keyvalue; let i = index">
                        <app-report-charts class="grchart" [data]="chart.value" [chartType]="chartView.smallBarChart"
                            [isBorder]="false" [name]="chart.key" [index]="i"
                            [ngStyle]="{'min-width': (retailRentalChartReport | keyvalue).length == 1 ? '100%' : '220px'}">
                        </app-report-charts>
                    </ng-container>


                </div>
                <ng-template #noDataMessage>
                    <div class="data_unavail">
                        <span>
                            {{"reports.data_not_available" | translate}} <mat-icon role="img"
                                class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color"
                                aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon>
                        </span>
                    </div>
                </ng-template>
            </ng-container>
        </div>
    </div>

    <!-- Rental Unit Size -->
    <div class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === retailRentalMenuEnum.retailUnitSize}"
        id="{{ retailRentalMenuEnum.retailUnitSize }}">

        <app-report-year-dropdown [reportType]="'rentalUnit'"
            (selectedYear)="selectedYear($event)"></app-report-year-dropdown>


        <div class="report-model">

            <div class="report-model-header">
                <div class="info">
                    <h3>{{"reports.rentUnitSizeWise" | translate}}</h3>
                    <!-- <span class="totalTransaction"> ({{retailUnitsizeCount ? reportService.formatInternationNum(retailUnitsizeCount) : retailUnitsizeCount}} {{"reports.totalUnits" | translate}})</span> -->
                </div>

            </div>
            <!-- <ng-container *ngIf="retailUnitsizeLoading">
                    <app-skeleton-loader [loaderSize]="8"></app-skeleton-loader>
                </ng-container> -->
            <app-report-datatable class="rrr-rentunitsize-table" [showLoader]="!retailUnitsizeLoading"
                [tableColumns]="retailUnitsizeColumns" [dataLength]="retailUnitsizeCount"
                [tableData]="retailUnitsizedata" (pageEvent)="onPageEvent($event, retailRentalMenuEnum.retailUnitSize)"
                (sort)="sortData($event, retailRentalMenuEnum.retailUnitSize)" [allowExcelDownload]="true"
                (excelDownload)="downloadExcel($event, 'retail_rental_unit_size', 'a')"
                [downloadLoading]="downloadLoading['a']" [showEstaterText]="true" [expandColumn]="true"
                [tableExpandColumns]="retailUnitsizeExpandColumns" [dropDownOption]="unitSizeDropdown"
                (dropDownValue)="dropDownChange($event)" [showTooltip]="true">
            </app-report-datatable>



        </div>
    </div>

    <!-- Rental Unit -->
    <div class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === retailRentalMenuEnum.retailUnit}"
        id="{{ retailRentalMenuEnum.retailUnit }}">

        <app-report-year-dropdown [reportType]="'unit'"
            (selectedYear)="selectedYear($event)"></app-report-year-dropdown>


        <div class="report-model">

            <div class="report-model-header">
                <div class="info">
                    <h3>{{'reports.unit' | translate}}</h3>
                    <span class="totalTransaction">({{retailUnitCount ?
                        reportService.formatInternationNum(retailUnitCount) : retailUnitCount}} {{"reports.totalUnits" |
                        translate}})</span>
                </div>

            </div>
            <!-- <ng-container *ngIf="retailUnitLoading">
                 <app-skeleton-loader [loaderSize]="8"></app-skeleton-loader>
             </ng-container> -->
            <app-report-datatable [showLoader]="!retailUnitLoading" [tableColumns]="retailUnitColumns"
                [dataLength]="retailUnitCount" [tableData]="retailUnitdata"
                (pageEvent)="onPageEvent($event, retailRentalMenuEnum.retailUnit)"
                (sort)="sortData($event, retailRentalMenuEnum.retailUnit)" [allowExcelDownload]="true"
                (excelDownload)="downloadExcel($event, 'retail_rental_unit', 'b')"
                [downloadLoading]="downloadLoading['b']" [showEstaterText]="true">
            </app-report-datatable>


        </div>
    </div>



</div>