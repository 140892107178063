import { Injectable } from '@angular/core';
import { StoreService } from './store.service';
import { HttpClient } from '@angular/common/http';
type GtagFunction = (event: string, lebel?: string, params?: object) => void;
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
    public gtag: GtagFunction;
    private SESSION_EXPIRATION_IN_MIN = 30;

    constructor(private storage: StoreService) {
        // Ensure gtag is defined before using
        this.gtag = (window as any).gtag || function() { console.log('gtag is not defined'); };
        // this.gtag('consent', 'default', {
        //     'analytics_storage': 'granted',  // Default consent state for analytics
        //     'wait_for_update': 500  // Wait for up to 500ms for consent state update
        //   });
      }
    // trackUserEvents(event:string='click', category:string,event_name:string, label:string, screen:string='', value:string) {
    //     gtag('event', event, {
    //         event_ategory: category,
    //         event_name: event_name,
    //         event_label: label,
    //         scree_name: screen,
    //         event_value: value
    //     });
    //   }
    
      public trackEvent(eventName: string, eventParams?: object): void {
        this.gtag('event', eventName, eventParams);
      }
    
    async getOrCreateSessionId() {
    // Store session in memory storage
    let sessionData:any = await this.storage.getSessionData('sessionData');
    // Check if session exists and is still valid
    const currentTimeInMs = Date.now();
    if (sessionData && sessionData.timestamp) {
        // Calculate how long ago the session was last updated
        const durationInMin = (currentTimeInMs - sessionData.timestamp) / 60000;
        // Check if last update lays past the session expiration threshold
        if (durationInMin > this.SESSION_EXPIRATION_IN_MIN) {
        // Delete old session id to start a new session
        sessionData = null;
        } else {
        // Update timestamp to keep session alive
        sessionData.timestamp = currentTimeInMs;
        await this.storage.addSessionData("sessionData" , sessionData);
        }
    }
    if (!sessionData) {
        // Create and store a new session
        sessionData = {
        session_id: currentTimeInMs.toString(),
        timestamp: currentTimeInMs.toString(),
        };
        await this.storage.addSessionData("sessionData" , sessionData);
    }
    return sessionData.session_id;
    }
}
