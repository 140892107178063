import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportTableColumn, ReportTableDropdown } from "../../models/reports.model";
import { RetailInventoryMenuEnum } from "./report-retail-inventory.enum";
import { DataType } from "src/app/common/models/enums/common.enum";

export class RetailInventoryReportConstant {

    static overViewMenu = [
        {
            label: 'reports.overview',
            name: RetailInventoryMenuEnum.overview
        },
        {
            label: 'reports.projectsProperties',
            name: RetailInventoryMenuEnum.properties,
            count: null
        },
        {
            label: 'reports.departments',
            name: RetailInventoryMenuEnum.departments,
            count: null
        },
        {
            label: 'reports.tentants',
            name: RetailInventoryMenuEnum.tenants,
            count: null
        },
        {
            label: 'reports.units',
            name: RetailInventoryMenuEnum.unit,
            count: null
        }
    ]

    static propertiesColumns: ReportTableColumn[] = [
        
        {
            header: 'property_name',
            columnName: 'reports.PropertyName',
            field: 'property_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap: true,
    
        },
        {
            header: 'neighborhood',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neighborhood',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
        },
       
        {
            header: 'land_area',
            columnName: 'reports.land_area',
            field: 'land_area',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        },
        {
            header: 'gla',
            columnName: 'reports.gla',
            field: 'gla',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        },
        {
            header: 'shops',
            columnName: 'reports.shops',
            field: 'shops',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true
        }
        ,
        {
            header: 'occupancy',
            columnName: 'reports.occupancy',
            field: 'occupancy',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true
        }
        ,
        {
            header: 'blended_lease_rate',
            columnName: 'reports.blendedLeaseRate',
            field: 'blended_lease_rate',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter,
        }
    ]

    
    static departmentExpandColumns: any[] = [
        { field : 'unit_size', convertNumber: false },
        { field : 'combined_area', convertNumber: true },
        { field : 'units', convertNumber: true },
        { field : 'lease_rate_range', convertNumber: false },
        { field : 'unit_size_range', convertNumber: false }
    ]

    static departmentAverageExpandColumns: any[] = [
        { field : 'unit_size', convertNumber: false },
        { field : 'combined_area', convertNumber: true },
        { field : 'units', convertNumber: true },
        { field : 'lease_rate_avg', convertNumber: false },
        { field : 'unit_size_avg', convertNumber: false }
    ]

    
    static departmentsColumns: ReportTableColumn[] = [
        {
            header: 'department_name',
            columnName: 'reports.department_name',
            field: 'department_name',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap: true
        },
        {
            header: 'combined_area',
            columnName: 'reports.combined_area',
            field: 'combined_area',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        },
       
        {
            header: 'units',
            columnName: 'reports.units',
            field: 'units',
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            
        },
        {
            header: 'lease_rate_range',
            columnName: 'reports.leaserate_range',
            field: 'lease_rate_range',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: false,
            symbol: DataType.currencyWithSquareMeter,
        },
        {
            header: 'unit_size_range',
            columnName: 'reports.unitsize_Range',
            field: 'unit_size_range',
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: false,
            symbol: DataType.squareMeter,
        }
       
    ]

    static departmentsAverageColumns: ReportTableColumn[] = [
        {
            header: 'department_name',
            columnName: 'reports.department_name',
            field: 'department_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap: true
        },
        {
            header: 'combined_area',
            columnName: 'reports.combined_area',
            field: 'combined_area',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        },
       
        {
            header: 'units',
            columnName: 'reports.units',
            field: 'units',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            
        },
        {
            header: 'lease_rate_avg',
            columnName: 'reports.lease_rate_average',
            field: 'lease_rate_avg',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false,
            symbol: DataType.currencyWithSquareMeter,
        },
        {
            header: 'unit_size_avg',
            columnName: 'reports.unit_size_average',
            field: 'unit_size_avg',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false,
            symbol: DataType.squareMeter,
        }
       
    ]

    static tentantsColumns: ReportTableColumn[] = [
        {
            header: 'tenant_name',
            columnName: 'reports.tentant_name',
            field: 'tenant_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap:true
        },
        {
            header: 'department_name',
            columnName: 'reports.department',
            field: 'department_name',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
       
        {
            header: 'units',
            columnName: 'reports.units',
            field: 'units',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
        },
        {
            header: 'lease_rate_range',
            columnName: 'reports.leaserate_range',
            field: 'lease_rate_range',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false,
            symbol: DataType.currencyWithSquareMeter,
        },
        {
            header: 'unit_size_range',
            columnName: 'reports.unitsize_Range',
            field: 'unit_size_range',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false,
            symbol: DataType.squareMeter,

        },
        {
            header: 'combined_area',
            columnName: 'reports.combined_area',
            field: 'combined_area',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,

        }
       
    ]

    static tentantsAverageColumns: ReportTableColumn[] = [
        {
            header: 'tenant_name',
            columnName: 'reports.tentant_name',
            field: 'tenant_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
        },
        {
            header: 'department_name',
            columnName: 'reports.department',
            field: 'department_name',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
       
        {
            header: 'units',
            columnName: 'reports.units',
            field: 'units',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
        },
        {
            header: 'lease_rate_average',
            columnName: 'reports.lease_rate_average',
            field: 'lease_rate_average',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'unit_size_average',
            columnName: 'reports.unit_size_average',
            field: 'unit_size_average',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false,
            symbol: DataType.squareMeter,

        },
        {
            header: 'combined_area',
            columnName: 'reports.combined_area',
            field: 'combined_area',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,

        }
       
    ]


    static unitColumns: ReportTableColumn[] = [
        {
            header: 'unit_number',
            columnName: 'reports.unit_number',
            field: 'unit_number',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap:true
        },
        {
            header: 'property_name',
            columnName: 'reports.PropertyName',
            field: 'property_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
       
        {
            header: 'floor',
            columnName: 'reports.floor',
            field: 'floor',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
        },
        {
            header: 'gross_area',
            columnName: 'reports.gross_area',
            field: 'gross_area',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        },
        {
            header: 'tenant_name',
            columnName: 'reports.tentant_name',
            field: 'tenant_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
        },
        // {
        //     header: 'combined_area',
        //     columnName: 'reports.combined_area',
        //     field: 'combined_area',
        //     border: false,
        //     isSortable: true,
        //     isContainsHTML: false,
        //     convertNumber: true,
        //     symbol: DataType.squareMeter,

        // },
        {
            header: 'categories',
            columnName: 'reports.categories',
            field: 'categories',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false,
        },
        {
            header: 'lease_rate',
            columnName: 'reports.lease_rate',
            field: 'lease_rate',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyWithSquareMeter,
        },
        {
            header: 'monthly_rental',
            columnName: 'reports.monthly_rentals',
            field: 'monthly_rental',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyCode,

        },
        {
            header: 'is_anchor',
            columnName: 'reports.anchor',
            field: 'is_anchor',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false,
        }
    ]

   
    static unitSizeDropdown: ReportTableDropdown = {
        label: 'reports.show_price_in',
        data: [{
            value: 'Range',
            id: 'Range',
        },{
            value: 'Average',
            id: 'Average',
        }],
        default: 'Range',
        maxSelect: 1
    }

    static trendsDropdown: ReportTableDropdown = {
        label: 'reports.choose_an_option',
        data: [{
            value: 'occupancy_trends',
            id: 'occupancy_trends',
        },{
            value: 'vacancy_trends',
            id: 'vacancy_trends',
        }],
        default: 'occupancy_trends',
        maxSelect: 1
    }

    static neighInsightDropdown: ReportTableDropdown = {
        label: 'reports.choose_an_option',
        data: [{
            value: 'gla_share',
            id: 'gla_share',
        },{
            value: 'countOf_malls',
            id: 'countOf_malls',
        }],
        default: 'gla_share',
        maxSelect: 1
    }
}
