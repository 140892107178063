<div class="transaction-property-detail">
    <app-child-breadcum [property_uid]="propertyInfo?.property_uid" (back)="backToTransaction()"></app-child-breadcum>
    <ng-container *ngIf="allDataLoading['overview']">
        <app-skeleton-loader [reportType]="chartView.overView" [loaderSize]="1"></app-skeleton-loader>
    </ng-container>
    <div class="property-details" *ngIf="propertyDetail && !allDataLoading['overview']">

        <div class="property-info">
            <h4>{{ propertyDetail?.property_name ? propertyDetail?.property_name : propertyDetail.property_uid }}</h4>
            <p>{{ propertyDetail?.address }}</p>

            <div class="property-zone" *ngIf="propertyDetail?.zoning || propertyDetail?.property_type">
                <h4>{{'reports.property_type_zoning' | translate}}</h4>
                <div class="zoning-info">
                    <span *ngIf="propertyDetail?.zoning">{{ "reports.zoning" | translate }} : <strong>{{ propertyDetail?.zoning }}</strong></span>
                    <span *ngIf="propertyDetail?.property_type"><strong>{{ propertyDetail?.property_type }}</strong></span>
                </div>

            </div>

            <div class="property-information">
                <h4>{{'reports.property_information' | translate}}</h4>

                <div class="property-details-d">
                    <div class="property-area">
                        <h6>{{'reports.area' | translate}}</h6>
                        <span [matTooltip]="propertyDetail?.area">{{ propertyDetail?.area ? (propertyDetail?.area | number: '1.0-1') : 'N/A' }}</span>
                        <h6 *ngIf="propertyDetail?.area ">{{(placeCode == BHRCHECK ? 'feet': 'sqm_unit') | translate}}</h6>
                    </div>
                    <div class="property-area frontage">
                      <h6>{{ "reports.frontage" | translate }}</h6>
                        <span [matTooltip]="propertyDetail?.frontAge">{{ propertyDetail?.frontAge ? (propertyDetail?.frontAge | number: '1.0-1') : 'N/A' }}</span>
                    </div>
                    <div class="property-area setback" >
                        <h6>{{ "reports.setback" | translate }}</h6>
                        <span [matTooltip]="propertyDetail?.setback">{{ propertyDetail?.setback ? (propertyDetail?.setback | number: '1.0-1') : 'N/A' }}</span>
                        <h6 *ngIf="propertyDetail?.setback">{{(placeCode == BHRCHECK ? 'feet': 'sqm_unit') | translate}}</h6>
                    </div>
                    <div class="property-area corner" >
                        <h6>{{'reports.corners' | translate}}</h6>
                        <span [ngClass]="{'nocorner-txt': !propertyDetail?.corner}">{{ propertyDetail?.corner ? propertyDetail?.corner : ('reports.no_corner' | translate) }}</span>

                    </div>
                </div>
            </div>
        </div>

        <div class="property-info-img">
            <div class="property-imgD">
                <img src="../../../../../../../assets/images/corner_{{propertyDetail.cornerValue}}.svg">
            </div>

        </div>

    </div>
    <!-- DataTable -->
    <div class="report-model">

        <div class="report-model-header">
            <div class="info">
                <h3>{{'reports.transactions_history' | translate}}</h3>
                <span class="totalTransaction">{{ propertyTransactionHistoryCount }} {{'reports.total_transaction' | translate}}</span>
            </div>

            <!-- <search-bar></search-bar> -->
        </div>
        <app-report-datatable [showLoader]="!allDataLoading['table1']" [tableColumns]="propertyTransactionHistoryColumns" [dataLength]="propertyTransactionHistoryCount"
            [tableData]="propertyTransactionHistoryData" [allowExcelDownload]="false" [showEstaterText]="true"
            (pageEvent)="onPageEvent($event, 'property-history')" (sort)="sortData($event, 'property-history')"
            (excelDownload)="downloadExcel($event, 'property-history')"></app-report-datatable>
    </div>

    <div class="report-model">

        <div class="report-model-header">
            <div class="info">
                <h3>{{'reports.units_transactions_history' | translate}}</h3>
                <span class="totalTransaction">{{ unitTransactionHistoryCount }} {{'reports.total_transaction' | translate}}</span>
            </div>

            <!-- <search-bar></search-bar> -->
        </div>
        <app-report-datatable [showLoader]="!allDataLoading['table2']" [tableColumns]="unitTransactionHistoryColumns" [dataLength]="unitTransactionHistoryCount"
            [tableData]="unitTransactionHistoryData" [allowExcelDownload]="false" [showEstaterText]="true"
            (pageEvent)="onPageEvent($event, 'unit-history')" (sort)="sortData($event, 'unit-history')"
            (excelDownload)="downloadExcel($event, 'unit-history')"></app-report-datatable>
    </div>
</div>
