import { Injectable } from '@angular/core';
import { apiEndPoints } from 'src/app/common/constants/api-endpointsconstant';
import { ApiService } from 'src/app/common/services/api.service';
import { ReportService } from 'src/app/reports/services/report.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportOfficeInventoryService {
  constructor(
    private apiServive: ApiService,
    private reportService: ReportService
  ) { }

  /**
   *
   * @returns
   * OverView Data
  */
  getOverview(){
    let queryParams = { ...this.reportService.queryParams };
    if (queryParams?.year) {
      queryParams.year = this.reportService.yearMaxValue(queryParams.year);
    }
    queryParams.report_type = 'overview_report';
    const changeParams = this.reportService.setfilterQueryParams(queryParams);
    let url: string = `${environment.miUrl}${apiEndPoints.officeInventory}${changeParams}`;
    return this.apiServive.get(url);
  }
/**
   *
   * @returns
   * OverView Data
   */
getOverviewGraph(){
  let queryParams = { ...this.reportService.queryParams };
  if (queryParams?.year) {
    queryParams.year = this.reportService.yearMaxValue(queryParams.year);
  }

  const changeParams = this.reportService.setfilterQueryParams(queryParams);

  let url: string = `${environment.miUrl}${apiEndPoints.officeRentalRentRateOverviewGraphdataReport}${changeParams}`;
  return this.apiServive.get(url);
}
}
