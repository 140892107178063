<div *ngIf="isLoggedIn" [ngClass]="{'side-collapse' : collapseMenu$}">
  <nav class="sidebar">
      <!-- <div class="sidebar-top-wrapper">
          <img (click)="collapseMenu()" src="../../../../assets/images/sidebar-icon.svg" width="20" height="54">

      </div> -->
      <div class="sidebar-links">
          <ul>
              <li  (click)="collapseMenu()">
                  <a >
                  <span>
                      <mat-icon  class="material-icons-outlined">menu</mat-icon>
                      <span class="link hide">{{'hide_menu' | translate}}</span>
                  </span>
              </a>
                  </li>
              <ng-container *ngFor="let menu of menuList; let i = index">
                <li  [attr.data-component]="i+'-menu'"[ngClass]="{ 'disable': menu.isDisable, 'active': menu.index === navigateID}" *ngIf="check(menu)">
                    <a (click)="navigateMenu(menu)"  [matTooltip]="menu.title | translate" matTooltipPosition = 'after' [matTooltipDisabled]="collapseMenu$ ? false : true">
                        <span>
                            <mat-icon *ngIf="menu.icon !== ''" class="material-icons-outlined">{{ menu.icon }}</mat-icon>
                            <img *ngIf="menu.image !== ''"  [src]="menu.image" [ngClass]="{'isSampleRepoer': menu.title == 'AI Report'}">
                            <span class="link hide">{{ menu.title | translate }}</span>
                        </span>
                    </a>

                </li>
              </ng-container>

            </ul>
        </div>
        <div class="sidebar-links bottom-links">
            <ul>

                <li *ngIf="langList > 1 && returnVal(currentLangID)" (click)="changeLang(currentLangID)" class="lang-bg" matTooltip="{{'User.language' | translate}}" matTooltipPosition = 'after'>
                    <span>
                        <mat-icon  class="material-icons-outlined"> language</mat-icon>
                        <span class="link hide">{{currentLangID === 1 ? 'English' : 'عربي'}}</span>
                    </span>
                </li>
                <li class="help"  matTooltip="{{'getHelp' | translate}}" matTooltipPosition = 'after' [matTooltipDisabled]="collapseMenu$ ? false : true">
                    <span (click)="getHelp()">
                        <mat-icon> error_outline</mat-icon>
                        <span class="link hide">{{ 'getHelp' | translate }}</span>
                    </span>
                </li>
            </ul>
        </div>
    </nav>
</div>
