<div class="transaction-property-detail">
    <app-child-breadcum [property_uid]="propertyInfo?.property_uid" (back)="backToTransaction()"></app-child-breadcum>
    <ng-container *ngIf="allDataLoading">
        <app-skeleton-loader [reportType]="chartView.overView" [loaderSize]="1"></app-skeleton-loader>
    </ng-container>
    <div class="property-details" *ngIf="propertyDetail">

        <div class="property-info">
            <h4>{{ propertyDetail?.property_name}}</h4>
            <p><strong>{{'reports.address' | translate}}:</strong> {{ propertyDetail?.address }}</p>

            <div class="property-zone" *ngIf="propertyDetail?.zoning">
                <h4>{{'reports.property_type_zoning' | translate}}</h4>
                <div class="zoning-info">
                    <span>{{ "reports.zoning" | translate }} : <strong>{{ propertyDetail?.zoning }}</strong></span>
                    <span *ngIf="propertyDetail?.property_type"><strong>{{ propertyDetail?.property_type }}</strong></span>
                </div>

            </div>

            <div class="property-information">
                <h4>{{'reports.property_information' | translate}}</h4>

                <div class="property-details-d">
                    <div class="property-area">
                        <h6>{{'reports.area' | translate}}</h6>
                        <span [matTooltip]="propertyDetail?.area">{{ propertyDetail?.area ? (propertyDetail?.area | number: '1.0-1') : ('na' | translate)
                            }}</span>
                        <h6 *ngIf="propertyDetail?.area">{{'sqm_unit' | translate}}</h6>
                    </div>
                    <div class="property-area frontage">
                        <h6>{{ "reports.frontage" | translate }}</h6>
                        <span [matTooltip]="propertyDetail?.frontAge">
                          {{ propertyDetail?.frontAge ? (propertyDetail?.frontAge | number: '1.0-1') : ('na' | translate) }}</span>
                        <h6 *ngIf="propertyDetail?.frontAge">{{'meter'| translate}}</h6>
                    </div>
                    <div class="property-area setback">
                      <h6>{{ "reports.setback" | translate }}</h6>
                        <span [matTooltip]="propertyDetail?.setback">{{ propertyDetail?.setback ?
                            (propertyDetail?.setback | number: '1.0-1' ) : ('na' | translate) }}</span>
                        <h6 *ngIf="propertyDetail?.setback">{{'sqm_unit' | translate}}</h6>
                    </div>
                    <div class="property-area corner">
                        <h6>{{'reports.corners' | translate}}</h6>
                        <span [ngClass]="{'nocorner-txt': !propertyDetail?.corner}">{{ propertyDetail?.corner ? propertyDetail?.corner : ('reports.no_corner' | translate) }}</span>

                    </div>
                </div>
            </div>


            <div class="property-zone property-builtup">
                <h4>{{'reports.builtup_information' | translate}}</h4>
                <div class="zoning-info builtup-info">
                    <span>{{'reports.builtup_area' | translate}}: <strong>{{propertyDetail?.total_buildup_area ?
                          propertyDetail?.total_buildup_area + ('reports.sqm_unit' | translate): ('na' | translate)}}</strong></span>
                    <!-- <span>{{'reports.year_of_build' | translate}}:<strong>{{propertyDetail?.build_year || ('na' | translate)}}</strong></span> -->
                    <span *ngIf="propertyDetail?.floors"><strong>{{propertyDetail?.floors }} {{'reports.floor' | translate}}</strong></span>
                    <span>{{'reports.finish' | translate}}: <strong>{{propertyDetail?.furnishing_status || ('na' | translate)}}</strong></span>
                    <span *ngIf="propertyDetail?.bed_rooms"><strong>{{propertyDetail?.bed_rooms}}</strong></span>
                    <span *ngIf="propertyDetail?.balconies"><strong>{{propertyDetail?.balconies}}</strong></span>
                    <span *ngIf="propertyDetail?.parking"><strong>{{propertyDetail?.parking}} {{'reports.parking' | translate}}</strong></span>
                </div>
            </div>
            <!-- <button class="btn-primary btn-housevilla-plan" mat-button><img src="assets/images/pdf.svg" alt=""> Floor
                Plans</button> -->
        </div>

        <!-- <div class="property-info-img">
            <div class="property-imgD">
                <img src="assets/images/housevilla.png">
            </div>
        </div> -->
    </div>
</div>
