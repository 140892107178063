import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { FormControl, ControlContainer } from '@angular/forms';
import { SharedService } from '../../../../common/services/shared.service';
import { ReportService } from 'src/app/reports/services/report.service';
import { ReplaySubject, Subject, Subscription, debounceTime, switchMap, takeUntil } from 'rxjs';
import { MatSelect } from '@angular/material/select';

export interface FilterValue{
  filterParam:any,
  label: any,
  val:string
}
@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent {
  
  @Input() Disabled:boolean=false;
  @Input() filterData:any;
  @Input() name: any;
  @Input() required: boolean = false;
  @Input() query_params:any

  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect | undefined;
  search = new Subject<string>();
  searchResponse$: Subscription = new Subscription();
  selectMultiForm = new FormControl();
  searchFilter = new FormControl();
  loadMore = false;
  displaySelectedValue = []; 
  lang: any;
  public filteredValue: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  protected _onDestroy = new Subject<void>();
  DataList: any=[];
  start: number=0;
  end: number=5;
  constructor(
    private controlContainer: ControlContainer,
    private reportsService: ReportService,
    private sharedService: SharedService
  ) {
    this.sharedService.language$.subscribe(lan => {
      if(lan){this.lang = this.sharedService.getCurrentLangID()}
    })
  }
  
  ngOnInit(): void {
    this.filterData.value_list = this.checkSelectedDataExits(this.filterData.value_list);
    this.filterData.value_list = this.filterData.value_list.filter((e:any)=> e.val != null)
    if(this.filterData.parameter_name == "year"){
      this.filterData.value_list.sort((a:any, b:any) =>  (Number(b.val) - Number(a.val)));  
      this.DataList =  this.filterData.value_list;
    }else if(this.filterData.parameter_name == "property_grade"){
      this.DataList = this.sortdata(this.filterData.value_list, 'property_grade');
    }else if(this.filterData.parameter_name == "property_age"){
      this.DataList = this.sortdata(this.filterData.value_list, 'property_age');
    }else{
      this.DataList = this.filterData.value_list.sort((a:any, b:any) => a.label[1]?.localeCompare(b.label[1]));
    }
    this.filteredValue.next(this.DataList.slice());

    this.searchFilter.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.findSearchVal();
    });
    this.searchFilterValue();
  }

  /**
   * Custom sort function for property grade
   * @param sortedS grade list
   * @returns return sorted data
   */
  sortdata(sortedS:any[], param:string){
    let grades:any = [];
    if(param == 'property_age'){
      grades = ['0-5', '6-10', '11-15','16-20'];
    }else if(param == 'property_grade'){
      grades = ['Grade A++', 'Grade A+', 'Grade A','Grade B++', 'Grade B+', 'Grade B', 'Grade C++', 'Grade C+', 'Grade C', 'Grade D++', 'Grade D+', 'Grade D'];
    }
    let sortedGrades:any = [];
      sortedS.sort((a:any, b:any) => a.label[1]?.localeCompare(b.label[1]))
      grades.map((ele:any) => {
        sortedS.find((ele1) => {
              if(ele1.label[1] == ele) {
                sortedGrades.push(ele1);
              }
          })
      })
      sortedS.map(ee=>{
          if(sortedGrades.find((a:any)=> a.label[1] == ee.label[1])){
          }else{
            sortedGrades.push(ee)
          }
      })
    return sortedGrades;
  }

  ngOnChanges(): void {
    this.Disabled = this.Disabled;
    this.selectMultiForm = this.controlContainer.control as FormControl; 
    this.filterData.value_list = this.checkSelectedDataExits(this.filterData.value_list);
    this.filterData.value_list = this.filterData.value_list.filter((e:any)=> e.val != null)
    if(this.filterData.parameter_name == "year"){
      this.filterData.value_list.sort((a:any, b:any) =>  (Number(b.val) - Number(a.val)));  
      this.DataList =  this.filterData.value_list;
    }else if(this.filterData.parameter_name == "property_grade"){
      this.DataList = this.sortdata(this.filterData.value_list, 'property_grade');
    }else if(this.filterData.parameter_name == "property_age"){
      this.DataList = this.sortdata(this.filterData.value_list, 'property_age');
    }else{
      this.DataList = this.filterData.value_list.sort((a:any, b:any) => a.label[1]?.localeCompare(b.label[1]));
    }
    // this.getSliceItem();
  }
  
  
  checkSelectedDataExits(data:any){
    let setValue = [...data];
    let selectedValue = this.selectMultiForm.value;
    if(this.filterData.type == 'single_select'){
      if(selectedValue.includes(',')) {
        let dropdownval = selectedValue.split(',')
        selectedValue = dropdownval[0];
        this.selectMultiForm.patchValue(selectedValue);
      }      
      selectedValue = selectedValue ? [selectedValue]: []
    }
    if(selectedValue?.length > 0){
      selectedValue.forEach((val:any) => {
        let findVal = data.find((d:any) => d.val === val);
        if(!findVal){
          let obj = {
            val: val,
            label: {
              [1]: val
            }
          }
          setValue.push(obj)
        }
      })
     
    }
    return setValue;
  }

  public searchFilterValue():void{
    this.searchResponse$ = this.search.pipe(
      debounceTime(600),
      switchMap((value: any) => {
        return this.reportsService.getfilterData(value.col_name, value.table, this.query_params, value.text)
      })
    ).subscribe((res) => {
        let filterData = [];
        
        if(res.data.length > 0){
          filterData = res.data.map((searchdata:any) => {
            return {
              val: searchdata.filter_name,
              label: searchdata.filter_label
            }
          });
        }
        let newData = filterData.filter((a:any)=> a.val != null);
        this.selectMultiForm.patchValue(this.selectMultiForm.value);
        let alldata = this.checkSelectedDataExits(newData);
        this.filteredValue.next(alldata)

        
    })
  }

  protected findSearchVal() {
    if (!this.DataList) {
      return;
    }
    // get the search keyword
    let search = this.searchFilter.value;
    let searchVal = this.searchFilter.value;
    // if (!search) {
    //   this.filteredValue.next(this.DataList.slice());
    //   return;
    // } else {
    //   search = search.toLowerCase();
    // }
    // filter the banks
    let value = this.filterData;
    value['text'] =  search;
    if(value.value_type === 'function' && value.value_search === 'API'){
      if(searchVal.length >= 2 || searchVal.length == 0){
          this.search.next(value)
      }
    }else {
      this.filteredValue.next(
        this.DataList.filter((d:any) => (d.val?.toLowerCase().includes(search) ||  d.label[this.lang]?.toLowerCase().includes(search)))
      );
    }
  }
/**
 * Remove slected data from chips
 * @param val 
 */
  remove(val:string){
    if(this.filterData.type == 'single_select'){
      this.selectMultiForm.patchValue('');
    }else{
      this.selectMultiForm.patchValue(this.selectMultiForm.value.filter((e:string) => e != val));
    }
  }
  /**
   * Select all filter from the dropdown
   * @param checked 
   */
  setAll(checked: boolean){
    if(checked){
    let all:string[] =[];
     this.DataList.map((s:FilterValue)=> {
        all.push(s.val);
     });
      this.selectMultiForm.patchValue(all);
    }else{
    this.selectMultiForm.patchValue([]);
    }
  }

  /**
   * This function used to get label of filters
   * @param v 
   * @returns 
   */
  getFilterLable(v:string){
    if(v){
     let item = this.DataList.find((e:any)=> e.val == v);
     if(item){
      return this.getNeighLabelFromLocal(item.label[this.lang] ? item.label[this.lang] : item.label['1'])  //item.label[this.lang] ? item.label[this.lang] : item.label['1'];
     }else{
      return v; //this.sharedService.removeUnderscores(v);
     }
    }
    return ;
  } 
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.searchResponse$.unsubscribe();
  }

  getSliceItem(){
    return this.selectMultiForm.value.slice(this.start, this.end)||[];
  }
  /**
   * This a function to use load more selected data in multiselect
   */
  loadMoreSelectedVal(){
    this.loadMore = !this.loadMore;
    if(this.loadMore){
      this.start = 0;
      this.end = this.selectMultiForm.value.length;
    }else{
      this.start = 0;
      this.end = 5;
    }
   
  }

  /**
   * This a function check selected value in the data list multiselect
   * @param val 
   * @returns 
   */
  checkSelected(val:any){
     return (this.selectMultiForm.value.indexOf(val) > -1)
  }

  /**
   * This is function to use multi select chips
   * @param val 
   */
  selectChips(val:any){    
    if(this.Disabled){
      return;
    }
    if(this.selectMultiForm.value.indexOf(val) == -1){
      let tmp = this.selectMultiForm.value || [];
      tmp.push(val);
      if(this.filterData.parameter_name == "year"){
        tmp = tmp.sort((a:any, b:any) =>  (Number(b) - Number(a)));  
      }
      this.selectMultiForm.patchValue(tmp);
      
    }else{
      this.selectMultiForm.patchValue(this.selectMultiForm.value.filter((e:string) => e != val));
    }
  }

  selectSingleChips(val:any){    
    if(this.selectMultiForm.value == val){
      this.selectMultiForm.patchValue('');
    }else{
      this.selectMultiForm.patchValue(val);
    }
  }

   /**
   * This a function check selected value in the data list in single select
   * @param val 
   * @returns 
   */
  checkSingleSelected(val:any){
    return this.selectMultiForm.value == val
  }

  /**
   * Get neigh label from the main neigh list (stored on localstorage)
   * @param label 
   * @returns 
   */
  getNeighLabelFromLocal(label:string){
   return this.sharedService.getNeighLabel(label);
  }
}
