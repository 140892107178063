import { Component, effect, Inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LanguagesEnum } from '../../models/enums/common.enum';
import { SharedService } from '../../services/shared.service';
import { AlertService } from '../alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import { CustomValidator } from 'src/app/customValidator';
import { UserService } from '../../services/user.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-contact-sales',
  templateUrl: './contact-sales.component.html',
  styleUrls: ['./contact-sales.component.scss']
})
export class ContactSalesComponent {

    RequestDemoForm!:FormGroup;
    currentLang:string='';
    dialCode: any;
    btnDisable: boolean=false;
    city_list:any =[];
    lengthCheck: any;
    phoneNumber:boolean=false;
    isBrowser: any;
    langList!: number;
    isRequestSend:boolean = false;
    isAllSelected = false;
    selected_citis: any[]=[];
    
    user_info: any;
    get FullName() {
      return this.RequestDemoForm.get('full_name')!;
    }
    get Email() {
      return this.RequestDemoForm.get('email')!;
    }
    get CompanyName(){
      return this.RequestDemoForm.get('company_name')!;
    }
    get PhoneNumber(){
      return this.RequestDemoForm.get('phoneNumber')!;
    }
    get City(){
      return this.RequestDemoForm.get('city')!;
    }
    get Message(){
        return this.RequestDemoForm.get('message')!;
      }
    search_item:string='';
    currentLangID: number = LanguagesEnum.english;
    constructor(private fb: FormBuilder,
      private sharedService: SharedService,
      private alertService: AlertService,
      private translate: TranslateService,
      private userService: UserService,
      public dialogRef: MatDialogRef<ContactSalesComponent>,
      @Inject(PLATFORM_ID) private platformId: any){
  
        this.isBrowser = isPlatformBrowser(this.platformId);
        if (this.isBrowser) {
          this.setLang();
          effect(()=> this.langList = this.sharedService.languageList()?.length);
        }
        this.intForm();      
        
    }
    intForm(){
      this.RequestDemoForm = this.fb.group(
        {
          full_name:  ['', Validators.compose([CustomValidator.notOnlyWhitespace, Validators.required, Validators.maxLength(45), Validators.minLength(3),  Validators.pattern('^[a-zA-Z \u0621-\u064A\u0660-\u0669 ]*$')])],
          email: ['', Validators.compose([Validators.required, Validators.email, Validators.minLength(this.sharedService.emailMinLength), Validators.maxLength(this.sharedService.emailMaxLength), Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')])],
          phoneNumber: ['', [CustomValidator.validatePhoneNumber, Validators.required]],
          company_name: ['', Validators.compose([Validators.required, Validators.maxLength(63), Validators.minLength(3), CustomValidator.notOnlyWhitespace])],
          city:['', Validators.required],
          message:['', Validators.compose([Validators.required, Validators.maxLength(140), Validators.minLength(10), CustomValidator.notOnlyWhitespace])],
        }
      );
       this.user_info =  this.sharedService.getUserInfo();
       if(this.user_info){
        this.patchValue(this.user_info);
       }
    }
    /**
     * patch value in the Form
     * @param Value 
     */
    patchValue(Value:any){
    //   if(Value) {
    //     this.RequestDemoForm.controls['full_name'].disable();
    //     this.RequestDemoForm.controls['email'].disable();
    //   }
      this.selected_citis = [this.sharedService.CityID]
      let full_name = Value.uname || '';
      this.RequestDemoForm.patchValue({
        full_name: full_name,
        email: Value.email,
        city : [this.sharedService.CityID]
      })
    }
    setLang(): void{
      this.currentLangID = this.sharedService.getCurrentLangID();
      this.sharedService.language$.subscribe((lang : any) => {
        this.translate.use(lang);
      })
    }
    phoneInputChange(event:any) {
      this.phoneNumber = (event.target.value == '') ? false : true;
      if(!this.phoneNumber){
        this.RequestDemoForm.controls['phoneNumber'].setErrors(null);
      } else if(event.target.value?.length != this.lengthCheck?.length) {
        this.RequestDemoForm.controls['phoneNumber'].setErrors({'validatePhoneNumber': true });
      }
    }
    getCountryCode(event:any) {
      this.dialCode = event.dialCode;
      this.lengthCheck = event.placeHolder.split('+'+this.dialCode)[1];
    }
  
    ngOnInit() {
      this.sharedService.getCity();
      this.sharedService.cityList$.subscribe((res)=>{
        this.city_list = res.data;
      })
      this.sharedService.language$.subscribe((res : any)=>{
        this.currentLang = res;
      })
    }
  
    saveChanges(value:any){
      if (this.RequestDemoForm.invalid) {
        return;
      }
      if(value['full_name'].trim() == "" || value['full_name'].trim() == null){
        this.RequestDemoForm.patchValue({first_name:''})
        this.alertService.warn(this.translate.instant('User.full_name_required'))
        return;
      }
      let body = {
        first_name: value.full_name,
        business_email: value.email,
        phone_number : value?.phoneNumber,
        company_name: value.company_name.trim(),
        cities_of_interest : value.city,
        message: value.message.trim(),
        type: 'contact_sales',
        contact_person: {
            phone_number : this.user_info.phone_number || null,
            full_name: this.user_info.user_details,
            Email: this.user_info.primary_email_id
          }
      }
      this.btnDisable = true;
      this.userService.requestAccount(body, value.city).subscribe({
          next: (res: any) => {
            this.btnDisable = false;
            if (res && res.status == 201) {
              this.isRequestSend = true;             
              this.RequestDemoForm.reset();
            } else {
              this.alertService.warn(res.message)
            }
          }, error: (err : any) => {
            this.btnDisable = false;
            this.alertService.error(err.error.message);
          }
        })
  
    }
 
 
    selectLang(lang:any){
      this.currentLangID = lang;
      this.sharedService.setLanguage(lang)
    }
  
    
    selectAll(checked: boolean) {
      if (checked) {
        const allCities = this.city_list.map((city:any) => city.place_id);
        this.City.setValue(allCities);
        this.selected_citis=allCities;
      } else {
        this.City.setValue([]);
        this.selected_citis=[];
  
      }
    }
    
  
    selectOne(checked:any, id:number){
      if(checked){
        if(this.selected_citis.indexOf(id) == -1){
          this.selected_citis.push(id)
        }
      }else{
        if(this.selected_citis.indexOf(id) > -1){
          this.selected_citis = this.selected_citis.filter(e=> e != id)
        }
      }
      this.RequestDemoForm.patchValue({
        city : this.selected_citis
      })
    }

    close(){
        this.dialogRef.close();
    }
}
