import { UserService } from 'src/app/common/services/user.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription, distinctUntilChanged } from 'rxjs';

import { ReportService } from '../../services/report.service';
import { ReportsViewEnum } from '../../models/enums/reports.enum';
import { SharedService } from 'src/app/common/services/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { SaveReportDailogComponent } from '../save-report-dailog/save-report-dailog.component';
import { actionIdConstants } from 'src/app/common/constants/common.contants';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from 'src/app/common/services/store.service';
import { ReportMapService } from '../../services/report-map.service';
import { ReportTransactionService } from '../reports-lists/components/report-transactions/report-transactions.service';
import { AlertService } from 'src/app/common/components/alert/alert.service';

@Component({
  selector: 'app-report-data',
  templateUrl: './report-data.component.html',
  styleUrls: ['./report-data.component.css']
})
export class ReportDataComponent implements OnInit, OnDestroy {

  currentReportView: number = ReportsViewEnum.mapView;
  viewType = ReportsViewEnum;
  currentReportView$: Subscription = new Subscription();
  currentToggle$: Subscription = new Subscription();
  routeSub$: Subscription = new Subscription();
  languageSub$: Subscription = new Subscription();
  currentToggle: boolean = false;
  currentReportID: number | null = null;
  queryParams: any;
  currentReportData: any;
  filter_list: any = [];
  currentLang: string = '';
  startTime = new Date();
  reportId: any;
  saveReporList: any = [];
  is_saved: boolean = false;
  sr_id!: any;
  isReport: boolean = false;
  selectReportSub$: Subscription = new Subscription();
  placeCode: string = '';
  studyName: string = '';
  reportName: string = '';
  user_info!: any;
  is_save_allow:boolean=false;
  downloadLoader: boolean =false;
  projectReportView: boolean =false;
  constructor(
    private reportService: ReportService,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private matDailog: MatDialog,
    private userService: UserService,
    private router: Router,
    public translate: TranslateService,
    private storeService: StoreService,
    private mapService: ReportMapService,
    private reportTransactionService: ReportTransactionService,
    private alertService: AlertService,
    public translateService: TranslateService
  ) { }

  ngOnInit() {

    this.user_info = this.sharedService.getUserInfo();
    this.reportId = this.activatedRoute.snapshot.queryParams['rid'];
    this.reportService.projectReportView = false;
    this.projectReportView = false;
    this.getReportView();
    this.toggleReport();
    this.setLang();
    this.setReportQueryParams();
    this.getSaveReportData();
    this.selectReportSub$ = this.reportService.selectedReport$.subscribe(isReport => {
      this.isReport = isReport.flag;
      this.studyName = this.reportService?.selectedStudies?.study_name;
      this.reportName = this.reportService?.selectedRepored?.report_name;

    })
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
  }

  setReportQueryParams() {

    this.routeSub$ = this.activatedRoute.queryParams.pipe(
      distinctUntilChanged(),
    ).subscribe((params) => {
      this.reportService.projectReportView = false;
      this.storeService.add(GlobalContants.StoreKey.latestReportRoute, this.router.url);
      this.queryParams = { ...params };
      if (params['srid']) {
        this.sr_id = params['srid'];
        this.check_saved_report();
      } else {
        this.sr_id = null;
        this.is_saved = false;
      }
      if (params['rid'] && Number(params['rid']) !== this.currentReportID) {
        this.currentReportID = Number(params['rid']);
        this.reportService.queryParams = { ...this.queryParams };
        this.currentReportData = null;
        this.reportService.filterQueryParams = this.reportService.setfilterQueryParams(this.queryParams);
        this.getReportInfo();
      } else if (params['rid'] && Number(params['rid']) === this.currentReportID) {

        this.reportService.selectedReport$.next({flag: true});
        this.reportService.queryParams = { ...this.queryParams };
        this.reportService.filterQueryParams = this.reportService.setfilterQueryParams(this.queryParams);
      } else {
        this.reportService.selectedReport$.next({flag: false});
        this.reportService.queryParams = null;
        this.reportService.filterQueryParams = null;
      }
      if (params['rid'] && !this.reportService.filterFlag) {
        this.logActivty();
      }
    });

  }

  setLang(): void {
    this.languageSub$ = this.sharedService.language$.subscribe(lang => {
      this.currentLang = lang;
    })
  }

  reportSubscription(): boolean {
    if (this.reportService.userSubscription?.length === 0) {
      return true;
    }
    let currentReport = this.reportService.getCurrentReport();
    let active = currentReport?.is_open_for_demo;
    if (this.reportService.userSubscription && !active) {
      active = this.reportService.userSubscription.some((type: any) => this.queryParams?.rid == type.report_id);
    }
    return active;
  }

  getReportInfo() {
    this.reportService.getReportData(this.queryParams).subscribe({
      next: (res: any) => {
        if (res && res.status == 200) {
          this.currentReportData = res.data[0];
          this.setReportData(res); // This function use for showing selected data in breadcum
          this.is_save_allow = this.currentReportData.is_save;
          this.reportService.setCurrentReport(this.currentReportData);

          let checkSubscription = this.reportSubscription();
          if (!checkSubscription) {
            const lang = this.sharedService.getCurrentLang() || 'en';
            const city = this.sharedService.getStoreValue(GlobalContants.StoreKey.Slug);
            this.router.navigateByUrl(`${city}-${lang}/${GlobalContants.Routes.Reports}`);
            this.reportService.selectedReport$.next({flag: false, type:'new_report'});
          } else {
            this.reportService.selectedReport$.next({flag: true, type:'new_report'});
            // if (res.user_preference && !this.is_saved) {
              // this.reportService.user_preference = res.user_preference;
              // this.fnc.legendCategoryColor.next(true)
              // if (res.user_preference.filter_data) {
              //   let queryParams = { ...res.user_preference.filter_data };
              //   delete queryParams['oid'];
              //   delete queryParams['gid'];
              //   delete queryParams['rid'];
              //   let datafilter = this.reportService.setCetegoryFilter(res.data[0]);
              //   let filterData = this.reportService.setSavedFilterData(datafilter, queryParams);
              //   this.router.navigate([], {
              //     queryParams: filterData,
              //     queryParamsHandling: 'merge',
              //   });
              // }
            // }
          }
          this.setFilter();
        } else {
          this.currentReportData = null;
          this.reportService.setCurrentReport(null);
        }
      },
      error: (error: any) => {
        this.currentReportData = null;
        this.reportService.setCurrentReport(null);
      }
    })
  }

  /**
   * This function use for showing selected data in breadcum
   * @param res
   */
  setReportData(res: any){
    let s = {...this.reportService.selectedStudies}
    s['study_label'] = res.groupDetail.label;
    this.reportService.selectedStudies = s;
    let r = {...this.reportService.selectedRepored}
    r['report_label'] = this.currentReportData.label;
    this.reportService.selectedRepored = r;
  }

  getReportView() {
    this.currentReportView$ = this.reportService.reportView$.subscribe((view: number) => {
      this.projectReportView = this.reportService.projectReportView;
      this.currentReportView = view;
    });
  }

  toggleReport() {
    this.currentToggle$ = this.reportService.reportQueryToggle$.subscribe((toggle: boolean) => {
      this.currentToggle = toggle;
    });
  }

  toggleReportSide(toggle: boolean) {
    this.reportService.reportQueryToggle$.next(toggle);
  }

  getSaveReportData() {
    this.userService.getUserSavedReport(this.sharedService.UserId).subscribe({
      next: (res: any) => {
        if (res.status == 200) {
          this.saveReporList = res.data;
          this.check_saved_report();
        }
      },
      error: (err) => {

      }
    })
  }
  setFilter() {
    if (this.currentReportData?.filter) {
      this.filter_list = this.currentReportData?.filter;

      this.getFilterData();
      // this.getCombineFilterData('');
      this.reportService.reportFilter = this.filter_list;
    }
  }

  getFilterData() {
    this.filter_list.map((data: any) => {
      // update title, col and table name from the search master filter data
      let master_filter;
      if(this.reportService.master_filter_list?.length > 0){
        master_filter = this.reportService.master_filter_list.find((mf:any) => mf.parameter_name == data.parameter_name);
      }      
      data['title'] = (master_filter?.label) ? master_filter?.label : data.label;
      data['col_name'] = (master_filter?.value?.param?.column) ? master_filter?.value?.param?.column : data.col_name;
      data['table'] = (master_filter?.value?.param?.table) ? master_filter?.value?.param?.table : data.table;
     
      if (
        data &&
        data.value_type &&
        data.value_type == 'function' &&
        data.type != 'range' &&
        data.type != 'hidden'
      ) {
        this.reportService
          .getfilterData(data.col_name, data.table, this.queryParams)
          .subscribe((ele) => {
            let temp: any = [];
            if (ele && ele?.data) {
              ele?.data.map((val: any) => {
                temp.push({
                  val: val.filter_name,
                  label: val.filter_label,
                });
              });
            }

            data['value_list'] = temp;
          });
      } else {
        if (data.type == 'text') {
          data['textval'] = data && data.value ? data.value : '';
        } else {
          if (data && data.value && Array.isArray(data.value)) {
            data['value_list'] = data.value;
          } else {
            data['value_list'] = [];
          }
        }
      }
    });
  }
  /**
   * getCombineFilterData get filter combined data
   * @param search_text serach text
   */
 getCombineFilterData(search_text:'') {
    let filterItem:any = {
      combine_data:[]
    }
    this.filter_list.map((data: any) => {
      data['title'] = data.label;
      if (data && data.value_type && data.value_type == 'function' &&
        data.type != 'range' &&
        data.type != 'hidden') {
        filterItem['combine_data'].push({ param: data.col_name, type: data.table, search_text:''});
      } else {
          if (data.type == 'text') {
            data['textval'] = data && data.value ? data.value : '';
          } else {
            if (data && data.value && Array.isArray(data.value)) {
              data['value_list'] = data.value;
            } else {
              data['value_list'] = [];
            }
          }
        }
    });
    this.reportService.getCombinefilterData(filterItem, this.queryParams).subscribe((ele:any) => {
        let temp: any = [];
        ele?.data.map((val: any) => {
          let itemname = Object.keys(val)[0];
          if (val[itemname] != null) {
            val[itemname].map((ditem:any)=> {
            temp.push({
              val: ditem?.filter_name,
              label: ditem?.filter_label,
            });
          })
        }
        this.filter_list.find((item:any) => item.col_name == itemname)['value_list'] = temp;
      });
    });
  }

  openDailog(type: string) {

    if (type == 'save') {
      let position = {};
      if (this.currentLang == 'ar') {
        position = {
          left: `${70}px`,
          bottom: `${100}px`,
        }
      } else {
        position = {
          right: `${70}px`,
          bottom: `${100}px`,
        }
      }
      this.matDailog.open(SaveReportDailogComponent, {
        width: '399px',
        height: 'auto',
        position: position,
        data: { currentReportData: this.currentReportData, saved_report: this.saveReporList }
      }).afterClosed().subscribe(res => {
        if (res) {
          this.getSaveReportData()
        }
      })
    }
  }
  /**
   * @description activity log call
   */
  logActivty(): void {
    const endTime = new Date();
    const activity = {
      duration: this.sharedService.getDurationInSeconds(this.startTime, endTime),
      entityId: this.reportId,
      actionId: actionIdConstants.View_Report,
      userdata: this.queryParams,
      entity: 'report',
    };
    this.userService.logUserActivity(activity);
  }


  /**
   * @description check the save report list
   */
  check_saved_report() {
    if (this.saveReporList) {
      let data: any = {};
      for (let key in this.queryParams) {
        if (key != 'srid') {
          data[key] = this.queryParams[key];
        }
      }
      if (this.queryParams['srid']) {
        this.sr_id = this.queryParams['srid'];
      }
      let saved = this.saveReporList.find((ele: any) => ele.id == this.sr_id);

      if (saved) {
        if (this.sharedService.compareObject(saved.data, data)) {
          this.is_saved = true;
          if (saved.filter_data) {
            this.reportService.user_preference['report_data'] = saved.filter_data;
          }
        } else {
          this.is_saved = false;
        }
      } else {
        this.is_saved = false;
      }
    }
  }

  /**
   * Reset filter
   */
  resetFilter() {
    if (this.currentReportData?.filter) {
      let queryParams = {
        oid: this.queryParams['oid'],
        gid: this.queryParams['gid'],
        rid: this.queryParams['rid'],
        polygon: this.queryParams['polygon']
      };
      // delete this.queryParams['polygon'];
      let defaultValue = this.reportService.setDefaultFilter(this.currentReportData);
      queryParams = { ...queryParams, ...defaultValue };
      this.reportService.selectedReport$.next({flag: true});
      this.mapService.reportFiltertoggle.next('reset'); //// for when filter is being applied
      // this.mapService.drawPolyToggle.next(false);
      this.router.navigate(
        [`${this.sharedService.cityName}-${this.sharedService.LangName}/reports`],
        { queryParams: queryParams }
      );
      // this.savefilterValue(queryParams);
    }
  }

  savefilterValue(value: any) {
    let filterValue = { ...value };

    if (filterValue['property_category']) {
      delete filterValue['property_category'];
    }
    if (filterValue['unit_category']) {
      delete filterValue['unit_category'];
    }
    if (filterValue['quarter']) {
      let quarter = JSON.parse(filterValue['quarter'])
      filterValue['quarter'] = quarter;
    }
    Object.keys(filterValue).forEach(value => {
      if (Array.isArray(filterValue[value])) {
        filterValue[value] = filterValue[value].join(',');
      }
    })
    delete filterValue['srid'];
    // let body = {
    //   report_id: this.reportId,
    //   user_id: this.user_info.user_id,
    //   filter_data: filterValue,
    //   added_by: this.user_info.user_id
    // }
    this.mapService.reportFilter.next(filterValue);
    // this.reportService.setUserPreference(body).subscribe((res: any) => { });
  }

  downloadInvestmentReport(module_name: string) {
    if(this.reportName === 'rental'){
      module_name = 'retail_rental'
    }else if(this.reportName === 'office_rental'){
      module_name = 'office_rental_overall'
    }
    if(!this.currentReportData?.is_download) {
      this.alertService.warn(this.translateService.instant('reports.disableDownloadAccessMsg'));
      return;
    }else {
      let subscription = this.reportService.userSubscription.find((sub: any) => sub.report_id === this.currentReportData?.id);
      if (!subscription?.allow_download) {
        this.alertService.warn(this.translateService.instant('reports.disableDownloadAccessMsg'));
        return;
      }
    }
    if(this.queryParams?.year){
      if(this.queryParams?.year.split(',').length > 1){
        this.alertService.warn("Select only one year");
        return;
      }
    }
    this.alertService.success('Preparing data for download...');
    // this.downloadLoader=true;
    this.reportService.getReportDownload(this.queryParams, module_name).subscribe(
      {
        next: (res: any) => {
          // this.downloadLoader=false;
          if (res.status === 200) {
            if(this.reportName === 'office_rental'){
              module_name = 'office_rental'
            }
            const downloadUrl = `${this.reportTransactionService.downloadExcelUrl()}?file_name=${res.data}&city_code=${this.placeCode.toLowerCase()}&module_name=${module_name}`;
            this.sharedService.downloadExcelUrl(downloadUrl);
            this.alertService.success(this.translate.instant('reports.downloadRequestSubmitted'));
          }else{
            this.alertService.error(res?.message);
          }
        },error : ()=>{
          // this.downloadLoader=false;
        }
      })
  }

  ngOnDestroy(): void {
    this.currentReportView$?.unsubscribe();
    this.currentToggle$?.unsubscribe();
    this.routeSub$?.unsubscribe();
    this.languageSub$?.unsubscribe();
    this.currentReportData = null;
    this.currentReportID = null;
  }
}
