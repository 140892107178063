<div class="container">
  <div class="heading text-center">
    <p>{{"Save Changes or save as" | translate}}</p>
  </div>
  <div class="content">
    <div class="content-inner text-center">
      <p>You made some changes to previously
        saved search <strong> {{appliedFilterName + " "}}</strong>would you like to save the changes?</p>
    </div>
    <div class="content-inner btns d-flex">
      <button type="button" class="btn btn-cancel" (click)="onCancelClick('')">Dont Save</button>
      <button type="submit" class="btn btn-save" (click)="onSaveClick()">Save Changes</button>
    </div>
    <div class="content-inner text-center">
      <span>Or </span>
      <button class="dialog-option" (click)="onCancelClick('save')"> Save as new search criteria</button>
    </div>
  </div>
</div>
