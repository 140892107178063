<div class="prop-info detail-info" *ngIf="overViewPropertySummary">

    <div class="prop-detail">

        <h2>{{ overViewPropertySummary.property_name }}</h2>

        <div class="prop-list">
            <ul>
                <li><span>{{'reports.address' | translate}} : </span> {{ overViewPropertySummary.address ? overViewPropertySummary.address : '-' }}</li>
                <li><span>{{'reports.constructionStatus' | translate}} : </span>  {{ overViewPropertySummary.construction_status  ? (overViewPropertySummary.construction_status | translate) : '-' }}</li>
                <li><span>{{'reports.landNumber' | translate}} : </span>  {{ overViewPropertySummary.land_number ? overViewPropertySummary.land_number : '-' }}</li>
                <li><span>{{'reports.yearOfBuild' | translate}}: </span>  {{ overViewPropertySummary.year_of_build ? overViewPropertySummary.year_of_build : '-' }} <ng-container *ngIf="overViewPropertySummary?.property_age">({{overViewPropertySummary?.property_age}})</ng-container></li>
                <li *ngIf="overViewPropertySummary.developer_name"><span>{{'reports.developer' | translate}} : </span>  {{ overViewPropertySummary.developer_name ? overViewPropertySummary.developer_name : '-' }}</li>
                <li><span>{{'reports.Municipal_land_zone' | translate}}: </span>  {{ overViewPropertySummary.zoning ? overViewPropertySummary.zoning : '-' }}</li>
            </ul>


        </div>

        <div class="land-value" *ngIf="overViewPropertySummary.land_value">
            <span>{{'reports.land_value_estimate' | translate}} &nbsp;&nbsp;&nbsp; <img *ngIf="overViewPropertySummary?.land_value" src="../../../../../../../../../assets/images/push-color.svg" alt=""> {{ overViewPropertySummary.land_value | number : "1.0-1" }} {{CurrencyCode}}</span>
        </div>

        <div class="property-cat"  *ngIf="overViewPropertySummary.property_categories">
            <h2>{{'reports.property_categories' | translate}}</h2>
            <ul>
                <li>{{ overViewPropertySummary.property_categories  ? overViewPropertySummary.property_categories : '-' }}</li>
            </ul>
        </div>

        <div class="land-info">
            <h2>{{'reports.land_information' | translate}} <i class="contant-icon" matTooltipPosition="above" matTooltipClass="custom-tooltip" [matTooltip]="'reports.thisSectionProvidesBasicDetails' | translate"></i></h2>
            <div class="info-land">
                <div class="p-info">
                    <h6>{{'reports.land_area' | translate}}</h6>
                    <h3>{{ overViewPropertySummary.land_area  ? overViewPropertySummary.land_area : '-' }}  <sup *ngIf="overViewPropertySummary.land_area">{{unitType | translate}}</sup></h3>

                </div>
                <div class="p-info">
                    <h6>{{'reports.setback' | translate}}</h6>
                    <h3>{{ overViewPropertySummary.setback  ? (overViewPropertySummary.setback) : '-' }} <sup *ngIf="overViewPropertySummary.setback">{{unitType | translate}}</sup></h3>

                </div>
                <div class="p-info">
                    <h6>{{'reports.corners' | translate}}</h6>
                    <h3>{{ overViewPropertySummary.corners  ? overViewPropertySummary.corners : '-' }}</h3>
                </div>
                <div class="p-info">
                    <h6>{{'Permissible' | translate}}</h6>
                    <h3>{{ overViewPropertySummary.far  ? (overViewPropertySummary.far | number : '1.0-1') : '-' }} <sup *ngIf="overViewPropertySummary.far">%</sup></h3>
                </div>
            </div>
        </div>

    </div>

    <div class="prop-img">
        <img *ngIf="overViewPropertySummary.corners == '0'" src="../../../../../../../../../assets/images/corner_0.svg" alt="Image">
        <img *ngIf="overViewPropertySummary.corners == '1'" src="../../../../../../../../../assets/images/corner_1.svg" alt="Image">
        <img *ngIf="overViewPropertySummary.corners == '2'" src="../../../../../../../../../assets/images/corner_2.svg" alt="Image">
        <img *ngIf="overViewPropertySummary.corners != '0' && overViewPropertySummary.corners != '1' && overViewPropertySummary.corners != '2'" src="../../../../../../../../../assets/images/corner_4.svg" alt="Image">
    </div>

</div>

<!--Property Information-->
<div class="property-infod">
    <div class="property-information">
        <h2 class="detail-heading">{{'reports.property_information' | translate}}<i class="contant-icon" matTooltipPosition="above" matTooltipClass="custom-tooltip" [matTooltip]="'reports.thisSectionShows' | translate"></i></h2>

        <div class="info"  *ngIf="propertyInfomation.length > 0; else noDataMessage">
            <ng-container  *ngFor="let info of propertyInfomation">
                <div class="p-info" *ngIf="info.value">
                    <h6>{{ info.name | translate}}</h6>
                    <h3>{{ info.value | number : '1.0-1'}}<ng-container *ngIf="info.name == 'FAR'">%</ng-container>
                        <sup *ngIf="info.name == 'Leasable Area'">{{unitType | translate}}</sup>
                    </h3>
                </div>
            </ng-container>

        </div>
        <ng-template #noDataMessage>
            <div class="data_unavail">
                <span>
                    {{"reports.data_not_available" | translate}} <mat-icon  role="img" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">equalizer</mat-icon>
                </span>
            </div>
        </ng-template>


    </div>

    <!-- <div class="numberofmax">
        <h2 class="detail-heading">Number of Maximum Permitted Floors <i class="contant-icon" matTooltipPosition="above" matTooltipClass="custom-tooltip" mamatTooltip="This section displays the maximum number of floors allowed for construction on this property, along with a breakdown of different floor types permitted, according to local municipal guidelines."></i></h2>
    </div> -->
</div>
