import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportTableColumn, ReportTableDropdown } from "../../models/reports.model";
import { OfficeSpaceRentalMenuEnum } from "./report-office-space-renta.enum";
import { DataType } from "src/app/common/models/enums/common.enum";

export class ReportOfficeSpaceRentalConstants{
    static overViewMenu = [
        {
            label: 'reports.overview',
            name: OfficeSpaceRentalMenuEnum.overview,
            count:null
        },
        {
            label: 'reports.rentUnitSizeWise',
            name: OfficeSpaceRentalMenuEnum.rentUnitSizeWise,
            count:null
        },
        {
            label: 'reports.unit',
            name: OfficeSpaceRentalMenuEnum.unit,
            count:null
        }
    ]
    static unitsColumns: ReportTableColumn[] = [
        {
            header: 'p_property_name',
            columnName: 'reports.PropertyName',
            field: 'p_property_name',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'neighborhood',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neighborhood',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'floor',
            columnName: 'reports.floor',
            field: 'floor',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'unit_size',
            columnName: 'reports.unit_size',
            field: 'unit_size',
            isSortable: true,
            border: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'property_grade',
            columnName: 'reports.propertyGrade',
            field: 'property_grade',
            isSortable: true,
            border: false,
            isContainsHTML: false
        },
        {
            header: 'lease_price',
            columnName: 'reports.lease_rate',
            field: 'lease_price',
            border: true,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyCode,
        }
    ]
    /**
     * Range Data
     */
    static rentUnitSizeColumns: ReportTableColumn[] = [
        {
            header: 'unit_size',
            columnName: 'reports.unit_size',
            field: 'unit_size',
            isSortable: false,
            border: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'grade_a+_range',
            columnName: 'reports.grade_a+',
            field: 'grade_a+_range',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            toolTipField:'avg_grade_a+',
        },
        {
            header: 'grade_a_range',
            columnName: 'reports.grade_a',
            field: 'grade_a_range',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            toolTipField:'avg_grade_a',
        },
        {
            header: 'grade_b_range',
            columnName: 'reports.grade_b',
            field: 'grade_b_range',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            toolTipField:'avg_grade_b',
        },
        {
            header: 'grade_c_range',
            columnName: 'reports.grade_c',
            field: 'grade_c_range',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            toolTipField:'avg_grade_c',
        }
    ]
    static unitsizeExpandColumns: any[] = [ 
        {field : 'neighbourhood'}, 
        {field : 'grade_a+_range'},
        {field : 'grade_a_range'},
        {field : 'grade_b_range'},
        {field : 'grade_c_range'}
    ];
    /**
     * Average Data
     */
    static retailUnitsizeAverageColumns: ReportTableColumn[] = [
        {
            header: 'unit_size',
            columnName: 'reports.unit_size',
            field: 'unit_size',
            isSortable: false,
            border: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'avg_grade_a+',
            columnName: 'reports.grade_a+',
            field: 'avg_grade_a+',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'grade_a+_range',
        },
        {
            header: 'avg_grade_a',
            columnName: 'reports.grade_a',
            field: 'avg_grade_a',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'grade_a_range'
        },
        {
            header: 'avg_grade_b',
            columnName: 'reports.grade_b',
            field: 'avg_grade_b',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'grade_b_range'
        },
        {
            header: 'avg_grade_c',
            columnName: 'reports.grade_c',
            field: 'avg_grade_c',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'grade_c_range'
        }
    ]
    static unitsizeExpandAverageColumns: any[] = [{field : 'neighbourhood', convertNumber: false},{field : 'avg_grade_a+', convertNumber: true},{field :'avg_grade_a', convertNumber: true},{field :'avg_grade_b', convertNumber: true},{field :'avg_grade_c',  convertNumber: true}];
    static unitSizeDropdown: ReportTableDropdown = {
        label: 'reports.show_price_in',
        data: [{
            value: 'Range',
            id: 'Range',
        },{
            value: 'Average',
            id: 'Average',
        }],
        default: 'Range',
        maxSelect: 1
    }
}