import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { commonContants } from '../../constants/common.contants';
import { CityModelComponent } from '../city-model/city-model.component';
import { SharedService } from '../../services/shared.service';
import { GlobalContants } from '../../constants/global.contants';
import { MatRegisterIconService } from '../../services/mat-register-icon.service';
import { UserService } from '../../services/user.service';
import { MobileViewPopUpComponent } from '../mobile-view-pop-up/mobile-view-pop-up.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges {

  @Input("IsLoggedIn") isLoggedIn: boolean = false;

  langset: string = commonContants.defaultLanguage;
  selectedCity: string = '';
  globalContants = GlobalContants.Routes;
  userInfo: any;
  citySub$: Subscription = new Subscription();
  userSub$: Subscription = new Subscription();
  cityLang!: string;
  placeCode: string='';
  currentLangID=1;

  constructor(
    private dialog: MatDialog,
    private sharedService: SharedService,
    private s: MatRegisterIconService,
    private userService: UserService
  ){}

  ngOnInit(): void {

    this.getSelectedCity();
    this.getCitylist();
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    this.getUserInfo();
    this.getChangedLang();
  }

  getChangedLang(){
    this.sharedService.language$.subscribe(res=>{
      let language = this.sharedService.getCurrentLangID();
      this.currentLangID = language;
      this.userInfo = {...this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo)};
      if(this.userInfo && this.userInfo?.user_details){
        this.userInfo['uname'] = this.userInfo?.user_details[language].first_name.trim() + " " + this.userInfo?.user_details[language].last_name.trim()
      }else{
        this.userInfo['uname'] = '';
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void{
    if(changes['isLoggedIn'] && changes['isLoggedIn'].currentValue){
      this.getSelectedCity();
      this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
      this.getUserInfo();
      this.getChangedLang()
    }
  }


  getCitylist(): void{
    this.citySub$ = this.sharedService.selectedCity$.subscribe((res: any) => {
      this.getSelectedCity();
    })
  }

  getUserInfo(): void{
    this.userSub$ = this.sharedService.userInfo$.subscribe((res: any) => {
      this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    });
  }

  openCityModel(): void{
    const dialogRef = this.dialog.open(CityModelComponent, {
      maxWidth: '95vw',
      maxHeight: '97vh',
      width: '618px',
       panelClass: 'custom-model-container'
    })

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result && result.selectedCity){
        this.selectedCity = result.selectedCity.place_name
      }
    });
  }

  getSelectedCity(): void{
    this.selectedCity = this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceName);
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    this.placeCode = this.placeCode ? this.placeCode.toLowerCase():'';
    this.cityLang = `${this.sharedService.CityLang}`;
  }

  logout(): void{
    this.userService.userLogout();
  }

  ngOnDestroy(): void{
    this.citySub$?.unsubscribe();
    this.userSub$?.unsubscribe();
  }

}
