<div
  [ngClass]="{
    'reports-side-collapse': !collapseReports$,
    'arabic-reports-side-collapse': currentLang === 'ar'
  }"
>
  <div class="reports-side">

    <div class="start-btn" *ngIf="reportStudiesData.length > 0">
      <button data-component="generate-new-report" class="btn-primary btn-start" #menuTrigger="matMenuTrigger"  mat-button [matMenuTriggerFor]="menu" (click)="genrateReportButtonClick()"><mat-icon>add</mat-icon> <span>{{"reports.newQuery" | translate}}</span></button>
    </div>

    <mat-menu #menu="matMenu" class="report-panel" [xPosition]="currentLang === 'ar' ? 'before' : 'after'">
      <app-reports-category [reportStudiesData]="reportStudiesData"></app-reports-category>
    </mat-menu>

    <div class="search-section">
      <div class="report-search">
          <search-bar
          [MinCharSearch] = 1
          [Reset]="resetSearch"
          (SearchClick)="onSearchChange($event)"
          [Placeholder]="'reports.search-report'"
        >
        </search-bar>
      </div>
      <mat-icon> import_export</mat-icon>
    </div>
    <div class="report-query">
      <app-recent-saved-report [searchReportName] ="searchReportName" ></app-recent-saved-report>
    </div>
    <div class="collapse" (click)="collapseReportSide(false)">
      <img src="../../../../assets/images/colapse.svg" alt="collapse">
      <span>{{'reports.collapse' | translate}}</span>
    </div>
  </div>
</div>
