<div class="report-model">

    <div class="report-model-header">
        <div class="info">
            <h3>{{'reports.residentialRentalIncome' | translate}}</h3>
        </div>

      
    </div>

    <app-report-datatable 
    [showLoader]="!rentalIncomeCountLoading" 
    [tableColumns]="rentalIncomeCountColumns" 
    [dataLength]="rentalIncomeCount"
    [tableData]="rentalIncomeCountData" 
    [allowExcelDownload]="false"
    [hidePagination]="true"
    (sort)="sortData($event, 1)"
    >
</app-report-datatable>

<app-report-datatable 
    [showLoader]="!rentalIncomeAreaLoading" 
    [tableColumns]="rentalIncomeAreaColumns" 
    [dataLength]="rentalIncomeAreaCount"
    [tableData]="rentalIncomeAreaData" 
    [allowExcelDownload]="false"
    [hidePagination]="true"
    (sort)="sortData($event, 2)"
    >
</app-report-datatable>

</div>
