import { GlobalContants } from "src/app/common/constants/global.contants";
import { ReportTableColumn } from "../../../models/reports.model";
import { PropertyDetailMenuEnum } from "./report-property-detail.enum";
import { DataType } from "src/app/common/models/enums/common.enum";

export class ReportPropertyDetailConstant {

    static overViewMenu = [
        {
            label: 'reports.overview',
            name: PropertyDetailMenuEnum.overview
        },
        {
            label: 'reports.builtup_information',
            name: PropertyDetailMenuEnum.builtInformation,
            count: null
        },
        // {
        //     label: 'reports.costOfConstruction',
        //     name: PropertyDetailMenuEnum.costOfconstruction,
        //     count: null
        // },
        {
            label: 'reports.amenities',
            name: PropertyDetailMenuEnum.amentities,
            count: null
        },
        {
            label: 'reports.residentialUnits',
            name: PropertyDetailMenuEnum.unit,
            count: null
        },
        {
            label: 'reports.unitTransactionsPrices',
            name: PropertyDetailMenuEnum.unitTransaction,
            count: null
        }, {
            label: 'reports.propertyTransactions',
            name: PropertyDetailMenuEnum.projectTransaction,
            count: null
        },
        {
            label: 'reports.residentialRentalIncome',
            name: PropertyDetailMenuEnum.rentalIncome,
            count: null
        }
    ]

    static residentialColumns: ReportTableColumn[] = [
        {
            header: 'unit_type',
            columnName: 'reports.unitType',
            field: 'unit_type',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,

        },
        {
            header: 'count',
            columnName: 'reports.count',
            field: 'count',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true
        },

        {
            header: 'min_size',
            columnName: 'reports.minSize',
            field: 'min_size',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,

        },
        {
            header: 'max_size',
            columnName: 'reports.maxSize',
            field: 'max_size',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,
        },
        {
            header: 'avg_size',
            columnName: 'reports.AverageSize',
            field: 'avg_size',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.squareMeter,

        }
    ]

    static unitTransactionPropertyColumns: ReportTableColumn[] = [
        {
            header: 'u_unit_uid',
            columnName: 'reports.unitId',
            field: 'u_unit_uid',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
        },
        {
            header: 'u_unit_area',
            columnName: 'reports.unitSize',
            field: 'u_unit_area',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            symbol: DataType.squareMeter,
            convertNumber: true
        },

        {
            header: 'u_unit_type',
            columnName: 'reports.unitType',
            field: 'u_unit_type',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
        },
        {
            header: 'transaction_date',
            columnName: 'reports.transactionDate',
            field: 'transaction_date',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'value',
            columnName: 'reports.value',
            field: 'value',
            border: true,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyCode,
        },
        {
            header: 'value_type',
            columnName: 'reports.valueType',
            field: 'value_type',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        }
    ]

    static transactionPropertyColumns: ReportTableColumn[] = [
        {
            header: 'property_name',
            columnName: 'reports.PropertyName',
            field: 'property_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
        },
        {
            header: 'size',
            columnName: 'size',
            field: 'size',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            symbol: DataType.squareMeter,
            convertNumber: true
        },

        // {
        //     header: 'type',
        //     columnName: 'Type',
        //     field: 'type',
        //     isSortable: true,
        //     border: false,
        //     isContainsHTML: false,
        //     convertNumber: false
        // },
        {
            header: 'transaction_date',
            columnName: 'reports.transactionDate',
            field: 'transaction_date',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'value',
            columnName: 'reports.value',
            field: 'value',
            border: true,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true,
            symbol: DataType.currencyCode,
        },
        {
            header: 'value_type',
            columnName: 'reports.valueType',
            field: 'value_type',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        }
    ]

    static rentalIncomeCount: ReportTableColumn[] = [
        {
            header: 'unit_type',
            columnName: 'reports.type',
            field: 'unit_type',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
        },
        {
            header: 'count',
            columnName: 'reports.count',
            field: 'count',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: true
        },

        {
            header: 'monthly_rental',
            columnName: 'reports.monthlyRental',
            field: 'monthly_rental',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            symbol: DataType.currencyCode,
        }
    ]

    static rentalIncomeArea: ReportTableColumn[] = [
        {
            header: 'unit_type',
            columnName: 'reports.type',
            field: 'unit_type',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
        },
        {
            header: 'area',
            columnName: 'reports.area',
            field: 'area',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            symbol: DataType.squareMeter,
            convertNumber: true
        },

        {
            header: 'ir_lease_rate',
            columnName: 'reports.lease_rate',
            field: 'lease_rate',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            symbol: DataType.currencyWithSquareMeter,
        }
    ]


}
